import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { LogoutService } from '../../services/logout.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogPromptBoolComponent } from '../dialogs/dialog-prompt-bool/dialog-prompt-bool.component';

@Component({
  selector: 'app-logout-button',
  template: `
    <app-ugau-button
      [iconName]="(isLoading$ | async) ? 'refresh' : 'logout'"
      [fill]="true"
      iconColor="danger"
      classColor="danger"
      label="Déconnexion"
      [hasBorder]="false"
      (click)="onLogout()"
      [class]="(isLoading$ | async) ? 'm1em rotate' : 'm1em'"
    ></app-ugau-button>

    <app-ugau-button
      [iconName]="(isLoadingAll$ | async) ? 'refresh' : 'logout'"
      [fill]="true"
      iconColor="danger"
      classColor="danger"
      label="Déconnexion (tous les appareils)"
      [hasBorder]="false"
      (click)="onLogoutAll()"
      [class]="(isLoadingAll$ | async) ? 'm1em rotate' : 'm1em'"
    ></app-ugau-button>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UgauButtonComponent, AsyncPipe],
})
export class LogoutButtonComponent {
  private logoutService = inject(LogoutService);
  private dialog = inject(MatDialog);

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  private isLoadingAllSubject = new BehaviorSubject<boolean>(false);
  isLoadingAll$ = this.isLoadingAllSubject.asObservable();

  onLogout() {
    this.isLoadingSubject.next(true);
    this.logoutService.logout().then(() => {
      this.isLoadingSubject.next(false);
    });
  }

  async onLogoutAll() {
    const dialog = this.dialog.open(DialogPromptBoolComponent, {
      data: {
        data: $localize`Êtes vous certain de vouloir vous déconnecter de tous les appareils ?`,
        title: $localize`Déconnexion de tous les appareils`,
        labelBtnValider: $localize`Déconnexion`,
      },
    });

    const confirmDelete = await firstValueFrom(dialog.afterClosed());
    console.log(confirmDelete);
    if (confirmDelete) {
      this.isLoadingAllSubject.next(true);
      this.logoutService.logoutAll().then(() => {
        this.isLoadingAllSubject.next(false);
      });
    }
  }
}
