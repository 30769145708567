/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  URI_buildings,
  CLICK_IGN_ENABLE,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
} from './layersChoices';
import {
  mapRemoveSandLIgnRaster,
  loadIgnRasterSourceAndLayer,
} from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

// Déclaration d'une variable pour conserver la référence du gestionnaire de clic
let handleClickBuilding: any;
export function addMapBuildings(map: Map, remove = false) {
  const sourceLib = URI_buildings; // Nom de la source

  // Si le gestionnaire de clic n'est pas encore défini, on le crée
  if (!handleClickBuilding) {
    handleClickBuilding = (e: any) => {
      e.ugau_wait_building = true;
      getInfoBuildingFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          if (data.features.length > 0) {
            e.ugau_custom_data = data;
          }
        }
      );
    };
  }

  if (remove) {
    if (CLICK_IGN_ENABLE) {
      map.off('click', handleClickBuilding); // Retirer le gestionnaire de clic si on enlève la couche
    }
    return mapRemoveSandLIgnRaster(map, sourceLib); // Retirer la couche
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=BUILDINGS.BUILDINGS' + // Spécifie la couche des bâtiments
    IGN_TILE;

  loadIgnRasterSourceAndLayer(map, sourceLib, url); // Ajoute la couche avec la source et l'URL des tuiles

  if (CLICK_IGN_ENABLE) {
    map.off('click', handleClickBuilding); // On s'assure de retirer les anciens gestionnaires
    map.on('click', handleClickBuilding); // Ajoute le gestionnaire de clic
  }
}

export function getInfoBuildingFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlBuildingPopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.json());
}

function getUrlBuildingPopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  // Définir une petite zone autour du point cliqué (une "bounding box")
  const buffer = 0.00000001; // Ajuster la taille selon la précision désirée

  const minLat = lat - buffer;
  const maxLat = lat + buffer;
  const minLng = lng - buffer;
  const maxLng = lng + buffer;

  // Construire l'URL pour le service WFS GetFeature avec la BBOX
  return `https://data.geopf.fr/wfs?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=BDTOPO_V3:batiment&SRSNAME=urn:ogc:def:crs:EPSG::4326&BBOX=${minLat},${minLng},${maxLat},${maxLng}&OUTPUTFORMAT=application/json`;
}
