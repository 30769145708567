import { storage, graphql } from '../services/nhost';

/**
 * Add Nhost prefix to image
 * @param image
 * @param storageUrl
 * @returns
 */
export function addNhostPrefix(
  image: string | undefined,
  storageUrl: string = storage.url
) {
  if (!image) return '';
  return `${storageUrl}/files/${image}`;
}

/**
 * Retrieve upload filename
 * @param fileId
 * @returns
 */
export async function retrieveUploadFilename(fileId: string): Promise<string> {
  try {
    const query = `
    query {
      file(id: "${fileId}") {
        name
      }
    }
  `;

    const { data, error } = await graphql.request(query);

    if (error) {
      console.error('Error while retrieving upload filename:', error);
      return '';
    }

    return data.file.name;
  } catch (error) {
    console.error('Error while retrieving upload filename:', error);
    return '';
  }
}
