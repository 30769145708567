/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { lon2tile, lat2tile, tile2lon, tile2lat } from '../tile.utils';
import {
  URI_points_repere_routiers,
  CLICK_IGN_ENABLE,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
} from './layersChoices';
import {
  mapRemoveSandLIgnRaster,
  loadIgnRasterSourceAndLayer,
} from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

let funcHandleRepereRouter: any;
export function addMapRepereRoutier(map: Map, remove = false) {
  if (!funcHandleRepereRouter) {
    funcHandleRepereRouter = (e: any) => {
      getInfoPrFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          console.log(data);
        }
      );
    };
  }
  const sourceLib = URI_points_repere_routiers;

  if (remove) {
    if (CLICK_IGN_ENABLE) map.off('click', funcHandleRepereRouter);
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, sourceLib, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', funcHandleRepereRouter);
    map.on('click', funcHandleRepereRouter);
  }
}

export function getInfoPrFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlPRinfoPopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.json());
}

function getUrlPRinfoPopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  const z = Math.floor(zoom);

  // Get the tile X and Y (assuming you have these functions)
  const tileX = lon2tile(lng, z);
  const tileY = lat2tile(lat, z);

  // Convert tile indices back to lat/lng of the top-left corner of the tile
  const tileCornerLng = tile2lon(tileX, z);
  const tileCornerLat = tile2lat(tileY, z);

  // Calculate the position within the tile
  const tileSizeInDegreesLng = tile2lon(tileX + 1, z) - tileCornerLng;
  const tileSizeInDegreesLat = tile2lat(tileY + 1, z) - tileCornerLat;

  const i = Math.floor((256 * (lng - tileCornerLng)) / tileSizeInDegreesLng);
  const j = Math.floor((256 * (lat - tileCornerLat)) / tileSizeInDegreesLat);

  return `https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetFeatureInfo&LAYER=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST_VISU&TILECOL=${tileX}&TILEROW=${tileY}&TILEMATRIX=${z}&TILEMATRIXSET=PM&FORMAT=image/png&STYLE=normal&INFOFORMAT=application/json&I=${i}&J=${j}`;
}
