import { Injectable, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { UserStateService } from '../state/user-state.service';

@Injectable({
  providedIn: 'root',
})
export class DisabledService {
  clickSubscription!: Subscription;

  constructor(private userState: UserStateService) {}

  generateLockIcon(renderer: Renderer2) {
    const lockIcon = renderer.createElement('mat-icon');
    renderer.setProperty(lockIcon, 'innerHTML', 'lock');
    renderer.addClass(lockIcon, 'mat-icon');
    renderer.addClass(lockIcon, 'notranslate');
    renderer.addClass(lockIcon, 'material-icons');
    renderer.addClass(lockIcon, 'mat-ligature-font');
    renderer.addClass(lockIcon, 'mat-icon-no-color');
    renderer.addClass(lockIcon, 'mat-lock-icon');
    return lockIcon;
  }

  handleSidebarItemFixed(renderer: Renderer2, el: any) {
    renderer.setStyle(el.nativeElement, 'position', 'relative');
    renderer.setStyle(el.nativeElement, 'display', 'flex');
    renderer.setStyle(el.nativeElement, 'align-items', 'center');
    renderer.setStyle(el.nativeElement, 'opacity', '0.5'); // Transparence
    renderer.setStyle(el.nativeElement, 'pointer-events', 'none'); // Désactive l'interaction
    renderer.setStyle(el.nativeElement, 'cursor', 'not-allowed'); // Style de curseur désactivé

    // Centrer l'icône sur l'élément
    this.addLockIconSidebarItem32px(renderer, el);
    this.interceptClick(el); // Bloque les clics si nécessaire
  }

  addLockIcon24px(renderer: Renderer2, el: any) {
    // Créer l'élément div avec la classe 'badge'
    const badge = renderer.createElement('div');
    renderer.addClass(badge, 'badge');

    // Créer l'élément mat-icon
    const lockIcon = this.generateLockIcon(renderer);
    renderer.setStyle(lockIcon, 'font-size', '24px');
    renderer.setStyle(lockIcon, 'width', '24px');
    renderer.setStyle(lockIcon, 'heigth', '24px');

    // Ajouter l'icône à la div.badge
    renderer.appendChild(badge, lockIcon);

    // Ajouter la div.badge à l'élément natif
    renderer.appendChild(el.nativeElement, badge);
  }

  addLockIconCenter48px(renderer: Renderer2, el: any) {
    const lockIcon = this.generateLockIcon(renderer);
    renderer.setStyle(lockIcon, 'font-size', '48px');
    renderer.setStyle(lockIcon, 'width', '48px');
    renderer.setStyle(lockIcon, 'height', '48px');
    renderer.setStyle(lockIcon, 'position', 'absolute');
    renderer.setStyle(lockIcon, 'color', 'var(--error-regular)');
    renderer.setStyle(lockIcon, 'top', '50%');
    renderer.setStyle(lockIcon, 'left', '50%');
    renderer.setStyle(lockIcon, 'transform', 'translate(-50%, -50%)');

    const parent = el.nativeElement;
    renderer.setStyle(parent, 'position', 'relative');

    renderer.appendChild(parent, lockIcon);
  }

  addLockIconSidebarItem32px(renderer: Renderer2, el: any) {
    const lockIcon = this.generateLockIcon(renderer);
    renderer.setStyle(lockIcon, 'font-size', '32px');
    renderer.setStyle(lockIcon, 'width', '32px');
    renderer.setStyle(lockIcon, 'height', '32px');
    renderer.setStyle(lockIcon, 'position', 'absolute');
    renderer.setStyle(lockIcon, 'color', 'var(--error-regular)');
    renderer.setStyle(lockIcon, 'left', '50%');
    renderer.setStyle(lockIcon, 'transform', 'translate(-50%, -50%)');

    const parent = el.nativeElement;
    renderer.setStyle(parent, 'position', 'relative');

    renderer.appendChild(parent, lockIcon);
  }

  interceptClick(el: any, callback?: () => void) {
    this.clickSubscription = fromEvent(el.nativeElement, 'click', {
      capture: true,
    }).subscribe((event: any) => {
      if (this.userState.checkIfUserIsOperatorOnlyInAllGroups()) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        callback?.();
      }
    });
  }
}
