import { Map } from 'mapbox-gl';
/**
 * This function will wait for the map to be idle before calling the function.
 * It will also trigger a repaint after the function is called.
 *
 * @param mapInstance The map instance to listen for idle event.
 * @param performMapAction The function to call when the map is idle.
 * @param contextInfo Optional context information to log when the function is called.
 * @returns void
 */
export function proxyCallMap({
  mapInstance,
  performMapAction,
  contextInfo,
}: {
  mapInstance: Map;
  performMapAction: () => void;
  contextInfo?: string;
}) {
  const logError = (message: string, error: unknown) =>
    console.error(message, error);

  const executeFunction = () => {
    try {
      performMapAction();
    } catch (error) {
      logError('Error executing function:', error);
    }
  };

  const triggerMapRepaint = () => {
    setTimeout(() => {
      try {
        mapInstance.triggerRepaint();
        console.log('Map repaint triggered');
      } catch (error) {
        logError('Error triggering repaint:', error);
      }
    }, 200);
  };

  const onMapIdle = () => {
    if (contextInfo) console.log(contextInfo);
    executeFunction();
    triggerMapRepaint();
  };

  try {
    if (mapInstance.isStyleLoaded() && !mapInstance.isMoving()) {
      onMapIdle();
    } else {
      mapInstance.once('idle', onMapIdle);
    }
  } catch (error) {
    logError('Error setting idle event listener:', error);
  }
}
