import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isString',
  standalone: true,
})
export class IsStringPipe implements PipeTransform {
  transform(value: any): value is string {
    return typeof value === 'string';
  }
}
