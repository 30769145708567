import { mapboxAccessToken } from '../../utils/mapbox-api-utils.service';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import { setToLocalStorage } from '../../utils/localstorage-utils.service';
import { LOCALSTORAGE } from '../../enum/local-storage';
import { defaultMapboxStyle } from './mapboxStylesList';
import mapboxgl, { Map as MapboxMap } from 'mapbox-gl';
import { Injectable, inject } from '@angular/core';
import { ToolsService } from '../tools.service';
import { sleep } from './../../../common-projects/sleep';
import { MapboxGLButtonControl } from './../../components/objet-osmap/MapboxGLButtonControl';
import { DatabaseService } from './../../db/database.service';
import { UgauMapboxOptions } from './mapbox.interface';
import { getMapboxStyleDefinition } from './getMapboxStyleDefinition';
import { addLayerZindex } from './addLayerZindex';
import { retrieveStylebyName } from './retrieveStylebyName';
import { UserMetaState } from '../../state/usermeta.state';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  mapInstances = new Map<string, MapboxMap>();
  originalOn = new Map<string, any>();
  originalOff = new Map<string, any>();
  originalOnce = new Map<string, any>();

  private tools = inject(ToolsService);
  private isMobile = this.tools.isMobile();
  private db = inject(DatabaseService);
  private usermetaState = inject(UserMetaState);

  initMapboxMap(
    id: string,
    container: string | HTMLElement,
    center: [number, number],
    options: UgauMapboxOptions = {}
  ) {
    const defaultValue = this.usermetaState.getMapboxStyle();
    const defaultStyle = retrieveStylebyName(defaultValue, this.isMobile);
    const style = defaultStyle?.uri ?? defaultMapboxStyle;
    const mapInstance = new MapboxMap({
      container: container,
      style,
      accessToken: mapboxAccessToken,
      attributionControl: false,
      dragRotate: true,
      touchZoomRotate: true,
      center: center,
      zoom: 9,
      preserveDrawingBuffer: true,
      maxZoom: 21,
      ...options,
    });

    mapInstance.on('style.load', () => {
      addLayerZindex(mapInstance);
    });

    mapInstance.on('moveend', () => {
      const center = mapInstance.getCenter();
      const zoom = mapInstance.getZoom();
      setToLocalStorage(LOCALSTORAGE.MAPBOX_DEFAULT_CENTER + id, {
        latitude: center.lat,
        longitude: center.lng,
        zoom,
      });
    });

    const { interactive } = options;
    if (interactive === false) {
      mapInstance.dragRotate.disable();
      mapInstance.touchZoomRotate.disableRotation();
    }
    this.mapInstances.set(id, mapInstance);

    // MODE DEBUG
    // this.interceptEventListeners(id);

    return mapInstance;
  }

  removeMap(id: string) {
    const map = this.mapInstances.get(id);
    if (map) {
      map.remove();
      this.mapInstances.delete(id);
    }
  }

  addMapStylesSwitcher(map: MapboxMap) {
    const { styles, options } = getMapboxStyleDefinition(
      this.isMobile,
      this.usermetaState.getMapboxStyle()
    );

    map.addControl(
      new MapboxStyleSwitcherControl(styles, {
        ...options,
        eventListeners: {
          onChange: (event: MouseEvent, style: string) => {
            console.log('Style changed to:', style);
            const validStyle = styles.find((e) => e.uri === style);
            if (validStyle) {
              this.usermetaState.setMapboxStyle(validStyle.title);
            }
            return true;
          },
        },
      }),
      'top-right'
    );
  }

  addMapResyncButton(map: MapboxMap) {
    const buttonControl = new MapboxGLButtonControl({
      className: 'material-icons refresh',

      title: $localize`Récupérer les données du serveur`,
      eventHandler: (event) => {
        buttonControl._btn.classList.add('rotate');

        this.tools.toastError($localize`Relance du cycle de synchronisation`);

        sleep(1000).then(async () => {
          await this.db.purgeNotAllDatasAndReload();
          buttonControl._btn.classList.remove('rotate');
        });
      },
    });
    map.addControl(buttonControl, 'top-right');
  }

  async resizeMap() {
    await sleep(250);
    try {
      Object.keys(this.mapInstances).forEach((key) => {
        const map = this.mapInstances.get(key);
        if (!map) return;
        if (map.getContainer().offsetParent === null) return;
        map.resize();
      });
    } catch (e) {
      console.log(e);
    }
  }

  interceptEventListeners(id: string): void {
    const mapPointer = this.mapInstances.get(id);
    if (!mapPointer) return;

    this.originalOn.set(id, mapPointer.on.bind(mapPointer));
    this.originalOff.set(id, mapPointer.off.bind(mapPointer));
    this.originalOnce.set(id, mapPointer.once.bind(mapPointer));

    mapPointer.on = ((type: any, layer: any, listener?: any): MapboxMap => {
      if (typeof layer === 'function' || listener === undefined) {
        listener = layer;
        console.log(`Event listener added: ${type}`);
        return this.originalOn.get(id)(type, listener);
      }
      console.log(`Event listener added: ${type} on layer: ${layer}`);
      return this.originalOn.get(id)(type, layer, listener);
    }) as mapboxgl.Map['on'];

    mapPointer.off = ((type: any, layer: any, listener?: any): MapboxMap => {
      if (typeof layer === 'function' || listener === undefined) {
        listener = layer;
        console.log(`Event listener removed: ${type}`);
        return this.originalOff.get(id)(type, listener);
      }
      console.log(`Event listener removed: ${type} on layer: ${layer}`);
      return this.originalOff.get(id)(type, layer, listener);
    }) as mapboxgl.Map['off'];

    const redefineOnce = (mapInstance: MapboxMap) => {
      const originalOnce = mapInstance.once.bind(mapInstance);

      const once: typeof mapInstance.once = (...args: any[]): any => {
        if (args.length === 1) {
          const [type] = args;
          console.log(`One-time event listener added as Promise: ${type}`);
          return originalOnce(type);
        } else if (args.length === 2) {
          const [type, listener] = args;
          console.log(`One-time event listener added: ${type}`, args);
          return originalOnce(type, listener);
        } else if (args.length === 3) {
          const [type, layer, listener] = args;
          console.log(
            `One-time event listener added: ${type} on layer: ${layer}`
          );
          return originalOnce(type, layer, listener);
        }
      };

      return once;
    };

    mapPointer.once = redefineOnce(mapPointer);
  }
}
