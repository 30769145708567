import { Map } from 'mapbox-gl';
import {
  ApplicationRef,
  EnvironmentInjector,
  inject,
  Injectable,
} from '@angular/core';
import { MapboxCustomControl } from './mapbox-switcher/mapbox-custom-control';

@Injectable({
  providedIn: 'root',
})
export class MapboxIgnService {
  private injector = inject(EnvironmentInjector);
  private appRef = inject(ApplicationRef);

  addMapIgnSandLToggler(map: Map, forcedLayers: string[] = []) {
    const control = new MapboxCustomControl(
      this.injector,
      this.appRef,
      forcedLayers
    );
    map.addControl(control, 'top-right');
  }
}
