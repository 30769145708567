export const IGN_URL = 'https://data.geopf.fr/';
export const IGN_GEO_AND_TILE =
  'wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile';
export const IGN_TILE =
  '&tilematrixset=PM&TileMatrix={z}&TileCol={x}&TileRow={y}&Format=image%2Fpng&style=normal&I=0&J=0&infoformat=application%2Fjson';
export const CLICK_IGN_ENABLE = true;
//
export const URI_lines_administratives = 'lines-administratives';
export const URI_points_repere_routiers = 'points-repere-routiers';
export const URI_securouteOA = 'points-securoute-oa';
export const URI_buildings = 'buildings-buildings';
export const URI_cadastral = 'lines-cadastre';
export const URI_urbanisme = 'lines-urbanisme';
export const URI_infraNetwork = 'infra-network';
export const URI_hydrographie = 'lines-hydrographie';

/**
 * @const layersChoices
 * @description An array of objects representing different map layers available for selection.
 * Each object contains a title and a URI for the respective map layer.
 *
 * @property {string} title - The title of the map layer.
 * @property {string} uri - The URI identifier for the map layer.
 */

export const layersChoices: {
  title: string;
  uri: string;
  style?: string;
}[] = [
  {
    title: 'Limite administrative',
    uri: URI_lines_administratives,
  },
  {
    title: 'Cadastre',
    uri: URI_cadastral,
  },
  {
    title: 'Bâtiments',
    uri: URI_buildings,
  },
  {
    title: 'Urbanisme',
    uri: URI_urbanisme,
  },
  {
    title: 'Repère routier',
    uri: URI_points_repere_routiers,
  },
  {
    title: 'Hydrographie',
    uri: URI_hydrographie,
  },
  {
    title: 'Franchissements',
    uri: URI_securouteOA,
  },
  {
    title: 'Lignes Electriques',
    uri: URI_infraNetwork,
  },
];
