import { inject } from '@angular/core';
import { EmplacementProvider } from './providers/emplacement/emplacement-provider';
import { EmplacementMobileProvider } from './providers/emplacement/emplacement-provider.mobile';
import { FabricantProvider } from './providers/fabricant-provider';
import { GroupeProvider } from './providers/groupe-provider';
import { ProductTreeProvider } from './providers/product-tree-provider';
import { ProductfieldsProvider } from './providers/productfields-provider';
import { ProductpropertiesProvider } from './providers/productproperties-provider';
import { ReglementationProvider } from './providers/reglementation-provider';
import { TagProvider } from './providers/tag-provider';
import { UsersProvider } from './providers/users-provider';
import { UserMetaProvider } from './providers/user-meta-provider';
import { StockProvider } from './providers/stock-provider';
import { InterventionProvider } from './providers/intervention/intervention-provider';
import { ContactProvider } from './providers/contact-provider';

/**
 * Initialise les providers pour un environnement de bureau.
 * @returns {Providers} - Les providers initialisés.
 */
export function initMobileProviders() {
  return {
    ...commonProviders(),
    emplacement: inject(EmplacementMobileProvider),
  };
}

/**
 * Initialise les providers pour un environnement mobile.
 * @returns {Providers} - Les providers initialisés pour mobile.
 */
export function initProviders() {
  return {
    ...commonProviders(),
    emplacement: inject(EmplacementProvider),
  };
}

function commonProviders() {
  return {
    user: inject(UsersProvider),
    usermeta: inject(UserMetaProvider),
    groupe: inject(GroupeProvider),
    tag: inject(TagProvider),
    productproperties: inject(ProductpropertiesProvider),
    reglementation: inject(ReglementationProvider),
    productfields: inject(ProductfieldsProvider),
    fabricant: inject(FabricantProvider),
    producttree: inject(ProductTreeProvider),
    intervention: inject(InterventionProvider),
    stock: inject(StockProvider),
    contact: inject(ContactProvider),
  };
}
