import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatIconModule } from '@angular/material/icon';

const startHour = 8;

@Component({
  selector: 'app-ugau-datetime',
  template: `
    <div class="datetime-container">
      <mat-form-field [appearance]="appearance" class="date-field">
        <mat-label>{{ placeholderDate }}</mat-label>
        <input
          matInput
          [value]="date | date: 'yyyy-MM-dd'"
          [matDatepicker]="datepicker"
          [readonly]="readonly"
          (dateChange)="onDateChange($event)"
          (click)="datepicker.open()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker [disabled]="readonly"></mat-datepicker>
      </mat-form-field>

      <mat-form-field [appearance]="appearance" class="time-field">
        <mat-label>{{ placeholderTime }}</mat-label>
        <input
          matInput
          [ngxMatTimepicker]="timepicker"
          [format]="24"
          [value]="time || ''"
          [readonly]="readonly"
        />
        <mat-icon matSuffix (click)="timepicker.open()">schedule</mat-icon>
        <ngx-mat-timepicker
          #timepicker
          (timeSet)="onTimeChange($event)"
        ></ngx-mat-timepicker>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      .datetime-container {
        display: flex;
        gap: 16px; /* Espace entre les champs */
        align-items: center;
      }

      .date-field,
      .time-field {
        flex: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    DatePipe,
  ],
})
export class UgauDatetimeComponent {
  date: string | undefined = undefined;
  time: string | undefined = undefined;

  @Input() set dateTime(value: string | null | undefined) {
    if (!value) {
      return;
    }

    const date = new Date(value);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    this.date = localDate.toISOString().split('T')[0];
    // Time contains seconds, we don't want that
    this.time = date
      .toLocaleTimeString()
      .split(' ')[0]
      .split(':')
      .slice(0, 2)
      .join(':');
  }

  @Input() placeholderDate: string = $localize`Date`;
  @Input() placeholderTime: string = $localize`Heure`;
  @Input() readonly = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Output() dateTimeChange = new EventEmitter<{ date: Date | null }>();

  onTimeChange($event: string) {
    this.time = $event;
    this.calcDatetime({
      date: this.date,
      time: $event,
    });
  }
  onDateChange($event: MatDatepickerInputEvent<any, any>) {
    const date = new Date($event.value);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    this.date = localDate.toISOString().split('T')[0];
    this.calcDatetime({
      date: this.date,
      time: this.time,
    });
  }

  calcDatetime({ date, time }: { date?: string; time?: string } = {}) {
    if (date && time) {
      const datetime = new Date(date);
      const timeSplitted = time.split(':');
      datetime.setHours(+timeSplitted[0]);
      datetime.setMinutes(+timeSplitted[1]);
      this.dateTimeChange.emit({ date: datetime });
      return;
    }

    if (this.date) {
      const datetime = new Date(this.date);
      datetime.setHours(startHour);
      this.dateTimeChange.emit({ date: datetime });
      return;
    }

    this.dateTimeChange.emit({ date: null });
  }
}
