import { Pipe, PipeTransform } from '@angular/core';
import { retrieveUploadFilename } from '../utils/nhost-utils';

@Pipe({
  name: 'nhostFilename',
  standalone: true,
})
export class NhostFilenamePipe implements PipeTransform {
  transform(fileId: string | null | undefined): Promise<string> {
    if (!fileId) {
      return Promise.resolve('');
    }
    return retrieveUploadFilename(fileId);
  }
}
