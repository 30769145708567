/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  CLICK_IGN_ENABLE,
  URI_cadastral,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
} from './layersChoices';
import {
  mapRemoveSandLIgnRaster,
  loadIgnRasterSourceAndLayer,
} from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

/**
 * Adds or removes the cadastral map layer to/from the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the cadastral layer will be added or removed.
 * @param {boolean} [remove=false] - A flag indicating whether to remove the cadastral layer.
 *                                   If true, the layer will be removed; otherwise, it will be added.
 *
 * @returns {void}
 */
let handleClickCadastre: any; // To keep the reference of the click event

export function addMapCadastre(map: Map, remove = false) {
  if (!handleClickCadastre) {
    handleClickCadastre = (e: any) => {
      e.ugau_wait_cadastre = true;
      getInfoParcelleFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          if (data.features.length > 0) {
            e.ugau_custom_data = data;
          }
        }
      );
    };
  }

  if (remove) {
    if (CLICK_IGN_ENABLE) {
      map.off('click', handleClickCadastre);
    }
    return mapRemoveSandLIgnRaster(map, URI_cadastral);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, URI_cadastral, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', handleClickCadastre);
    map.on('click', handleClickCadastre);
  }
}

export function getInfoParcelleFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlCadastrePopupFromIgn(lat, lng, zoom);
  // Attention, si le fetch echoue, alors on reçoit du xml
  return fetch(url).then((response) => response.json());
}

function getUrlCadastrePopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  // Define a small buffer for the bounding box (this will create a small area around the clicked point)
  const buffer = 0.00000001; // Adjust this to change the size of the bounding box

  const minLat = lat - buffer;
  const maxLat = lat + buffer;
  const minLng = lng - buffer;
  const maxLng = lng + buffer;

  return `https://data.geopf.fr/wfs?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle&SRSNAME=urn:ogc:def:crs:EPSG::4326&BBOX=${minLat},${minLng},${maxLat},${maxLng}&OUTPUTFORMAT=application/json`;
}
