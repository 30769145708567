import { gql } from 'graphql-request';

export const QUERY_GET_GROUPES = gql`
  query MyQuery(
    $where: groupe_bool_exp!
    $order_by: [groupe_order_by!]!
    $limit: Int!
  ) {
    groupe(where: $where, order_by: $order_by, limit: $limit) {
      abonnement
      libelle
      id
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      config
      user_groupes {
        id_user
        id
        role
        user {
          displayName
          email
          id
        }
      }
    }
  }
`;
