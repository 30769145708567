import { Injectable, OnDestroy } from '@angular/core';
import { EventsService } from '../services/events.service';
import { UserService } from './user.service';
import { ToolsService } from './tools.service';
import { CartStore } from '@types_custom/cart-store';
import { ProductFamiliesType } from '@types_custom/ProductType';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import { AuthenticationService } from './authentication.service';
import { environment } from './../../environments/environment';
import { CartCalculationService } from './cart-calculation.service';

const GET_EMPTY_CART = '&empty_cart=';

@Injectable({
  providedIn: 'root',
})
export class CartService implements OnDestroy {
  storeSubject = new BehaviorSubject<Record<string, CartStore>>({});
  store$ = this.storeSubject.asObservable();
  getStore$(x: string) {
    return this.store$.pipe(
      map((store) => {
        return store[x];
      })
    );
  }

  private readonly boutiqueLink =
    environment.FRONT_URL + '.netlify/functions/fill-cart?products=';

  userId: any;
  user: any;

  private subscriptions: Subscription[] = [];

  constructor(
    public AT: ToolsService,
    private auth: AuthenticationService,
    private events: EventsService,
    private UserP: UserService,
    private cartCalculationService: CartCalculationService
  ) {
    this.userId = this.auth.getUserId();
    this.subscriptions.push(
      ...[
        this.events.subscribe('stat:go_to_website_by_ids', (ids: any) => {
          this.goToWebsiteByIds(ids);
        }),

        this.UserP.getUserByIdObs(this.userId).subscribe((data: any) => {
          if (data) {
            this.user = data;
          }
        }),
      ]
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getStore() {
    return this.storeSubject.getValue();
  }

  calculate(id: string, emplacements: any, families: ProductFamiliesType[]) {
    const store: CartStore = {};
    this.cartCalculationService.calculate(store, emplacements, families);

    const actualStore = this.storeSubject.getValue();
    this.storeSubject.next({
      ...actualStore,
      [id]: { ...store },
    });
  }

  constructWebsiteUrlByIds(cateContainer: any, emplacements: any) {
    return emplacements.reduce((arr: any, emplacement: any) => {
      emplacement.composants.reduce((innerArr: any, composant: any) => {
        if (composant.product?.shortcut) {
          const index = innerArr.findIndex(
            (el: any) => el.shortcut == composant.product.shortcut
          );
          if (index == -1) {
            innerArr.push({
              shortcut: composant.product.shortcut,
              quantite: 1,
            });
          } else {
            innerArr[index].quantite++;
          }
        }
        return innerArr;
      }, arr);
      return arr;
    }, cateContainer);
  }

  constructWebsiteUrlFromCateContainer(cateContainer: any) {
    const parameters: any = [];
    cateContainer.forEach((el: any) => {
      parameters.push('' + el.shortcut + ',' + el.quantite + ',');
    });
    return parameters;
  }

  openCart() {
    const emptyCart = false;
    const userParameter = '&id=' + this.user.email;
    const link = this.boutiqueLink + userParameter + GET_EMPTY_CART + emptyCart;

    window.open(link, '_blank');
  }

  openCatalog() {
    const userParameter = '&id=' + this.user.email;
    const link =
      this.boutiqueLink + '&redirect=notre-catalogue' + userParameter;
    window.open(link, '_blank');
  }

  goToWebsiteByProducts(products: any) {
    const emptyCart = false;
    const tmp = products.reduce((arr: any, product: any) => {
      const index = arr.findIndex((el: any) => el.shortcut == product.shortcut);
      if (index == -1) {
        arr.push({
          shortcut: product.shortcut,
          quantite: 1,
        });
      } else {
        arr[index].quantite++;
      }
      return arr;
    }, []);

    const parameters = this.constructWebsiteUrlFromCateContainer(tmp);
    const userParameter = '&id=' + this.user.email;

    const link =
      this.boutiqueLink +
      parameters.join('|') +
      userParameter +
      GET_EMPTY_CART +
      emptyCart;

    window.open(link, '_blank');
  }

  goToWebsiteByIds(emplacements: emplacementDocType[], emptyCart = false) {
    const coupleShortcutQte = this.constructWebsiteUrlByIds([], emplacements);
    this.callBoutiqueByShortcutAndQuantite(coupleShortcutQte, emptyCart);
  }

  callBoutiqueByShortcutAndQuantite(
    coupleShortcutQte: any,
    emptyCart: boolean
  ) {
    const parameters =
      this.constructWebsiteUrlFromCateContainer(coupleShortcutQte);
    const userParameter = '&id=' + this.user.email;

    const link =
      this.boutiqueLink +
      parameters.join('|') +
      userParameter +
      GET_EMPTY_CART +
      emptyCart;

    window.open(link, '_blank');
  }

  proxyExportMapPng() {
    return new Promise((resolve) => {
      this.events.publish('toggleMapNumber', true);
      setTimeout(() => {
        this.events.publish('exportMapPng');
        resolve(null);
      }, 1000);
    });
  }

  proxyExportListeCsv() {
    return new Promise((resolve) => {
      this.events.publish('exportListeCsv');
      resolve(null);
    });
  }
}
