import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const usersSchemaLiteral = jsonSchema({
  name: 'users',
  nameCleanForUser: 'Utilisateurs',
  autoMigrate: true,
  title: 'Users schema',
  version: 1,
  type: 'object',
  required: ['id', 'displayName', 'updatedAt', 'email', 'user_groupes'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    displayName: {
      type: 'string',
    },
    phoneNumber: {
      type: ['string', 'null'],
    },
    email: {
      type: 'string',
    },
    defaultRole: {
      type: 'string',
    },
    user_groupes: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'role', 'groupe'],
        properties: {
          id: {
            type: 'string',
          },
          role: {
            type: 'string',
          },
          groupe: {
            type: 'object',
            required: ['id', 'libelle'],
            properties: {
              id: {
                type: 'string',
              },
              libelle: {
                type: 'string',
              },
              abonnement: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    createdAt: {
      type: 'string',
    },
    updatedAt: {
      type: ['string'],
    },
    deleted_bool: {
      type: 'boolean',
    },
  },
} as const);

const usersSchemaTyped = toTypedRxJsonSchema(usersSchemaLiteral);

export type usersDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof usersSchemaTyped
>;

export const usersSchema: RxJsonSchema<usersDocType> = usersSchemaLiteral;
