import { userAltType, groupeDocType } from '../../../db/schemas/groupe.schema';
import { tagDocType } from '../../../db/schemas/tag.schema';
import { usersDocType } from '../../../db/schemas/users.schema';
import { storage } from '../../../services/nhost';

export function tagGenerateEventDescription(
  tag: tagDocType,
  users: usersDocType[] | userAltType[] = [],
  groupes: groupeDocType[] = [],
  tagLink: string
): string {
  const metas = tag.tag_meta?.reduce((acc: string[], meta) => {
    if (meta.type === 'upload') {
      if (meta.value)
        acc.push(
          `${meta.key} : <a href="${storage.getPublicUrl({ fileId: meta.value })}" target="_blank">${storage.getPublicUrl({ fileId: meta.value })}</a>`
        );
      return acc;
    }

    if (!meta.value || meta.value === '') {
      acc.push(meta.key + ' : ' + $localize`(Non renseigné)`);
      return acc;
    }

    acc.push(`${meta.key} : ${meta.value}`);
    return acc;
  }, []);

  const metasString = metas?.length
    ? `
Informations :
${metas.join('\n')}
  `
    : '';

  const shares = tag.shared_tags?.reduce((acc: string[], share) => {
    if (share.active === false) return acc;
    if (share.target_user_id) {
      const user = users.find((u) => u.id === share.target_user_id);
      if (user) acc.push(user.displayName + ' (' + user.email + ')');
    }

    if (share.target_group_id) {
      const group = groupes.find((g) => g.id === share.target_group_id);
      if (group) acc.push(group.libelle);
    }

    return acc;
  }, []);

  const sharesString = shares?.length
    ? `
Partagé avec :
${shares.join('\n')}
  `
    : `
Aucun partage`;

  return `${tag.libelle}
<a href="${tagLink}" target="_blank">${tagLink}</a>

Type : ${tag.type}
Appartient à : ${tag.user_libelle ?? tag.groupe_libelle ?? $localize`(Non renseigné)`}
${metasString}${sharesString}`;
}
