import { Pipe, PipeTransform } from '@angular/core';
import { formatNumberFunction } from '../utils/formatNumberFunction';

@Pipe({
  name: 'formatnumber',
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  transform(num: number | undefined | null, addEuro = false): string {
    return formatNumberFunction(num, addEuro);
  }
}
