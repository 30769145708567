import { getDefaultLayoutClusterCount } from '../../components/objet-osmap/getDefaultLayoutClusterCount';
import { getDefaultLayoutUnclustered } from '../../components/objet-osmap/getDefaultLayoutUnclustered';
import { getDefaultPaintCluster } from '../../components/objet-osmap/getDefaultPaintCluster';
import { getDefaultPaintClusterCount } from '../../components/objet-osmap/getDefaultPaintClusterCount';
import { MAP_CLUSTER_POINT_COLOR } from './mapbox-constants';
import { mapboxLayerOptions } from './mapbox.interface';

export const POINT_SYMBOL_HOME: mapboxLayerOptions = {
  id: 'point-symbol',
  source: 'markers_source',
  type: 'symbol',
  layer: {
    id: 'point-symbol',
    source: 'markers_source',
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: getDefaultLayoutUnclustered(),
  },
};
export const POINT_CLUSTER_HOME: mapboxLayerOptions = {
  id: 'point-clusters',
  source: 'markers_source',
  type: 'circle',
  layer: {
    id: 'point-clusters',
    type: 'circle',
    source: 'markers_source',
    filter: ['has', 'point_count'],
    paint: getDefaultPaintCluster(MAP_CLUSTER_POINT_COLOR),
  },
};
export const POINT_CLUSTER_COUNT_HOME: mapboxLayerOptions = {
  id: 'point-clusters-count',
  source: 'markers_source',
  type: 'symbol',
  layer: {
    id: 'point-clusters-count',
    type: 'symbol',
    source: 'markers_source',
    filter: ['has', 'point_count'],
    layout: getDefaultLayoutClusterCount(),
    paint: getDefaultPaintClusterCount(),
  },
};
