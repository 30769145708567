import { Injectable } from '@angular/core';
import { auth } from './nhost';
import { environment } from './../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, takeUntil } from 'rxjs';
import { EventsService } from './events.service';
import { ToolsService } from './tools.service';
import { BaseAuthenticationService } from './authentication.base';

@Injectable({
  providedIn: 'root',
})
export class DesktopAuthenticationService extends BaseAuthenticationService {
  isOnline: boolean | unknown = true;
  public getIsOnline() {
    return this.isOnline;
  }

  idUser!: string;
  defaultRole: string = '';
  displayName: string = '';
  email: string = '';

  refreshEnCours = false;

  public token$ = new Observable((observer: any) => {
    // 1er coup, on push le token direct
    observer.next(auth.getAccessToken());
    auth.onTokenChanged(() => {
      // Au prochain changement de token, on repush le token
      observer.next(auth.getAccessToken());
    });
  });

  nhostSessionSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public tools: ToolsService,
    public event: EventsService,
    public router: Router
  ) {
    super(tools, event, router);
  }

  public init() {
    console.log('Authentication, Desktop mode');
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  public async login(user: any) {
    this.watchToken();

    const { session, error } = await auth.signIn({
      email: user.username,
      password: user.password,
    });

    if (error) {
      return { session: null, error };
    }

    if (session) {
      this.nhostSessionSubject$.next(session);
      if (environment.ENABLE_SENTRY) {
        Sentry.setUser({
          id: session.user.id,
          username: session.user.email,
          email: session.user.email,
        });
      }
      return { session, error: null };
    }

    return {
      session: null,
      error: { message: $localize`Erreur lors de l'authentification` },
    };
  }

  public watchToken() {
    // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
    this.token$.pipe(takeUntil(this.destroy$)).subscribe((token) => {
      this.event.publish('auth:tokenChanged', token);
    });
  }

  public async refreshToken() {
    if (!this.refreshEnCours) {
      this.refreshEnCours = true;
      await auth.refreshSession();
      this.refreshEnCours = false;
    }
  }
}
