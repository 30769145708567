import { inject, Injectable } from '@angular/core';
import { combineLatest, from, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { filtreParentNode } from '@types_custom/subFiltreViewType';
import { orderBy } from 'lodash-es';
import { interventionDocType } from '../db/schemas/intervention.schema';
import { auth, getUserID } from '../services/nhost';
import { INTERVENTION_STATUS } from '../components/intervention-page/intervention-form/INTERVENTION_STATUS';
import { UserStateService } from './user-state.service';
import { EmplacementsStateService } from './emplacements-state.service';
import { returnInterventionNextStatus } from './returnInterventionNextStatus';
import { CommonStateService } from './commun-state.service';
import { isConformeEmplacement } from '../utils/emplacement/isConformeEmplacement';
import { InterventionsViewTypes } from './InterventionsViewTypes';
import {
  getInterventionLabel,
  transformEmplacementsForIntervention,
} from '../utils/intervention/intervention-utils';
import { TagsStateService } from './tags-state.service';
import { GroupesStateService } from './groupes-state.service';
import { getUsersForIntervention } from '../pipes/interventions/intervention.pipe';
import { EmplacementService } from '../services/emplacement.service';
import { createIntervention } from '../utils/intervention/createIntervention';
import { InterventionsStateStore } from './interventions-state.store';
import { createInterventionDocument } from '../utils/intervention/createInterventionDocument';
import { DatabaseService } from '../db/database.service';

const PREVIEW_LIST = 'preview-list';

@Injectable({
  providedIn: 'root',
})
export class InterventionsStateService {
  private interventionProvider =
    inject(DatabaseService).getInterventionProvider();

  state$ = this.store.state$;
  currentTabDetailView$ = this.store.currentTabDetailView$;
  currentTabFormView$ = this.store.currentTabFormView$;
  isDetailEmploacementOpened$ = this.store.isDetailEmploacementOpened$;
  isDetailIntervenantOpened$ = this.store.isDetailIntervenantOpened$;
  currentIntervention$ = this.store.currentIntervention$;

  interventions$ = this.interventionProvider.getAll$.pipe(
    map((e) => e.filter((e) => !e.deleted_at)),
    tap((e) => {
      // On MAJ l'intervention courante si elle est modifiée
      const state = this.getState();
      const updated = e.find((e) => e.id === state.intervention?.id);
      if (updated) {
        this.store.setState({
          intervention: updated,
        });
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  interventionNotInPreparation$ = this.interventions$.pipe(
    map((e) => e.filter((e) => e.status !== 'PREPARATION'))
  );

  currentInterventionEmplacements$ = combineLatest([
    this.currentIntervention$,
    this.emplacementsState.emplacements$,
  ]).pipe(
    map(([intervention, emplacements]) => {
      const empacementIdsForThisIntervention =
        intervention?.emplacements.map((e) => e.id_emplacement) ?? [];
      return emplacements.filter((e) =>
        empacementIdsForThisIntervention.includes(e.id)
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  currentView$ = this.store.state$.pipe(
    map((e) => {
      return e.view;
    })
  );

  currentInterventionLabel$ = this.currentIntervention$.pipe(
    map((intervention) => {
      return getInterventionLabel(intervention);
    })
  );

  isUserOperatorForCurrentIntervention$ = combineLatest([
    this.currentIntervention$,
    this.userState.user$,
  ]).pipe(
    map(([intervention, user]) => {
      if (!intervention || !user) return false;
      return this.userState.isUserOperatorForTag(intervention.id_tag);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  documentTypes$ = this.interventions$.pipe(
    map((e) => {
      const types = e.reduce<string[]>((acc, curr) => {
        const documents = curr.documents;
        if (documents.length < 1) return acc;

        const types = documents.map((e) => e.document_type);
        return [...acc, ...types];
      }, []);
      return [...new Set(types)];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  interventionsFiltered$ = combineLatest([
    this.interventions$,
    this.store.state$,
  ]).pipe(
    map(([interventions, state]) => {
      if (state.tagId) {
        const inters = interventions.filter((e) => e.id_tag === state.tagId);
        return orderBy(inters, state.sortType, state.sortOrder);
      }

      if (state.interventionId) {
        const inters = interventions.filter(
          (e) => e.id === state.interventionId
        );
        return orderBy(inters, state.sortType, state.sortOrder);
      }

      return [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isReplicationActive$ = from(
    this.interventionProvider.replicateState?.awaitInSync() ?? of(true)
  ).pipe(
    startWith(false),
    map((e) => !e),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  filterValue$ = this.store.state$.pipe(
    map((e) => e.listFilterValue),
    startWith(''),
    map((e) => e ?? ''),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  usersForCurrentIntervention$ = combineLatest([
    this.currentIntervention$,
    this.tagsState.tags$,
    this.groupeState.groupes$,
  ]).pipe(
    map(([intervention, tags, groupes]) => {
      if (!intervention) return undefined;
      return getUsersForIntervention(intervention, tags, groupes);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private store: InterventionsStateStore,
    private userState: UserStateService,
    private groupeState: GroupesStateService,
    private tagsState: TagsStateService,
    private emplacementsState: EmplacementsStateService,
    private commonState: CommonStateService,
    private emplacementService: EmplacementService
  ) {}

  getState() {
    return this.store.getState();
  }

  setView = (view: InterventionsViewTypes) => {
    this.store.setState({
      view,
    });
  };
  setTabFormView(index: number) {
    this.store.setState({
      currentTabFormView: index,
    });
  }
  getNode() {
    return this.getState().node;
  }

  getEmplacementsFromTagId(tagId: string) {
    return this.emplacementsState.getEmplacementsByTagId(tagId);
  }

  forceCollectionSync() {
    this.interventionProvider.replicateState?.reSync();
  }

  getInterventions() {
    return this.interventionProvider.getAllValue();
  }
  getInterventionsByTagId(idTag: string) {
    return this.interventionProvider
      .getAllValue()
      .filter((e) => e.id_tag === idTag);
  }
  getIntervention(id: string) {
    return this.getInterventions().find((e) => e.id === id);
  }
  setTabDetailView(index: number) {
    this.store.setState({
      currentTabDetailView: index,
    });
  }
  toggleEmpacementDetailOpened() {
    this.store.setState({
      isDetailEmplacementOpened: !this.getState().isDetailEmplacementOpened,
    });
  }
  toggleIntervenantDetailOpened() {
    this.store.setState({
      isDetailIntervenantOpened: !this.getState().isDetailIntervenantOpened,
    });
  }

  setFilterValue(value: string) {
    this.store.setState({
      listFilterValue: value,
    });
  }

  setSelectedDossier(
    node: filtreParentNode | undefined,
    tagId: string | undefined,
    interventionId: string | undefined,
    emplacementIds: string[] | undefined,
    view: InterventionsViewTypes = 'list'
  ) {
    this.store.setState({
      node,
      tagId,
      interventionId,
      view,
      ...(emplacementIds ? { emplacementIds } : {}),
    });
  }

  resetSelectedDossier() {
    this.store.setState({
      view: PREVIEW_LIST,
      node: undefined,
      tagId: undefined,
      interventionId: undefined,
      emplacementIds: [],
      sortOrder: 'asc',
      sortType: 'created_at',
    });
  }

  changeSort(sortType: string) {
    const value = this.getState();
    // Si le type est deja le meme, on change l'ordre
    const sortOrder = value.sortType === sortType ? 'desc' : 'asc';

    this.store.setState({
      sortOrder,
      sortType,
    });
  }

  initNewIntervention(
    emplacements: { id: string }[] = [],
    tagId?: string,
    name: string = ''
  ) {
    const empls = [];
    const userId = auth.getUser()?.id;

    if (emplacements.length) {
      empls.push(...transformEmplacementsForIntervention(emplacements));
    }

    this.setCurrentIntervention(
      createIntervention(name, tagId, userId, empls),
      'new'
    );
  }

  getCurrentIntervention() {
    return this.getState().intervention;
  }

  setCurrentInterventionId(id: string, view?: InterventionsViewTypes) {
    const intervention = this.getIntervention(id);
    if (!intervention) return;
    this.store.setState({
      intervention,
      ...(view ? { view } : {}),
    });
  }

  setCurrentIntervention(
    intervention: interventionDocType,
    view?: InterventionsViewTypes
  ) {
    this.store.setState({
      intervention,
      ...(view ? { view } : {}),
    });
  }

  saveIntervention(intervention: interventionDocType) {
    return this.interventionProvider.collection.upsert({
      ...intervention,
      updated_at: new Date().toISOString(),
      updated_by: auth.getUser()?.id,
    });
  }

  updateInterventionToNextStatus(intervention: interventionDocType) {
    return this.doChangetoNextStatus(intervention);
  }

  deleteIntervention(intervention: interventionDocType) {
    this.saveIntervention({
      ...intervention,
      deleted_at: new Date().toISOString(),
      deleted_by: auth.getUser()?.id,
    });
  }

  clearIntervention() {
    this.store.setState({
      intervention: null,
      view: null,
    });
  }

  showIntervention(intervention: interventionDocType) {
    this.setCurrentIntervention(intervention, 'show');
  }

  removeEmplacementFromIntervention(
    intervention: interventionDocType,
    emplacementId: string
  ) {
    const emplacements = intervention.emplacements.filter(
      (e) => e.id_emplacement !== emplacementId
    );
    this.saveIntervention({
      ...intervention,
      emplacements,
    });
  }

  addDocumentToIntervention(
    intervention: interventionDocType,
    document: {
      filename: string;
      chemin: string;
      type: string | null;
    }
  ) {
    const alreadyAdded = intervention.documents.find(
      (d) => d.chemin === document.chemin
    );

    if (alreadyAdded) {
      return;
    }

    this.saveIntervention({
      ...intervention,
      documents: [
        ...intervention.documents,
        createInterventionDocument(document, getUserID()),
      ],
    });
  }

  changeToNextStatus() {
    const intervention = this.getCurrentIntervention();
    if (!intervention) return;
    return this.doChangetoNextStatus(intervention);
  }

  async doChangetoNextStatus(intervention: interventionDocType) {
    const nextStatus = returnInterventionNextStatus(intervention.status);

    if (nextStatus) {
      if (nextStatus === INTERVENTION_STATUS.VALIDE) {
        // L'intervention est validé et devient non modifiable
        await this.saveIntervention({
          ...intervention,
          status: nextStatus,
          date_valide: new Date().toISOString(),
        });
        return;
      }

      if (nextStatus === INTERVENTION_STATUS.EN_COURS) {
        // L'intervention est démarré, on stocke l'état des emplacements avant
        combineLatest([
          this.emplacementsState.getEmplacementsByIds$(
            intervention.emplacements.map((e) => e.id_emplacement)
          ),
          this.commonState.common$,
        ])
          .pipe(take(1))
          // eslint-disable-next-line rxjs/no-ignored-subscription -- Take 1 is used
          .subscribe(
            ([emplacements, { reglementations, properties, fields }]) => {
              if (!emplacements) return;

              this.saveIntervention({
                ...intervention,
                status: nextStatus,
                date_en_cours: new Date().toISOString(),
                etat_depart: {
                  emplacements: emplacements.map((e) => {
                    return {
                      id: e.id,
                      etat: e.status,
                      conforme: isConformeEmplacement(
                        e,
                        properties,
                        fields,
                        reglementations
                      ),
                    };
                  }),
                },
                // On met à jour les dates de début d'intervention
                emplacements: intervention.emplacements.map((e) => {
                  return {
                    ...e,
                    date_start_intervention: new Date().toISOString(),
                  };
                }),
              });

              this.emplacementService.bulkUpdateStatus(
                'NEED_INTERVENTION',
                emplacements.map((e) => e.id)
              );
            }
          );
        return;
      }

      if (nextStatus === INTERVENTION_STATUS.TERMINE) {
        // L'intervention est terminé, on stocke l'état des emplacements après
        combineLatest([
          this.emplacementsState.getEmplacementsByIds$(
            intervention.emplacements.map((e) => e.id_emplacement)
          ),
          this.commonState.common$,
        ])
          .pipe(take(1))
          // eslint-disable-next-line rxjs/no-ignored-subscription -- Take 1 is used
          .subscribe(
            ([emplacements, { reglementations, properties, fields }]) => {
              if (!emplacements) return;

              // Sauvegarde de l'intervention
              this.saveIntervention({
                ...intervention,
                status: nextStatus,
                date_termine: new Date().toISOString(),
                etat_fin: {
                  emplacements: emplacements.map((e) => {
                    return {
                      id: e.id,
                      etat: e.status,
                      conforme: isConformeEmplacement(
                        e,
                        properties,
                        fields,
                        reglementations
                      ),
                    };
                  }),
                },
              });

              // Pour chaque emplacement non traité, on remet à jour le status vers POSE
              this.emplacementService.bulkUpdateStatus(
                'POSE',
                emplacements
                  .filter((e) => e.status === 'NEED_INTERVENTION')
                  .map((e) => e.id)
              );
            }
          );
        return;
      }

      if (nextStatus.toString() === INTERVENTION_STATUS.ARCHIVE.toString()) {
        this.saveIntervention({
          ...intervention,
          status: nextStatus,
        });
      }
    }
  }

  goBack() {
    const state = this.getState();
    const currentView = state.view;

    if (currentView === 'list') {
      this.returnToInterventionFiltres();
    }

    if (currentView === 'show') {
      // Si c'est le détail + id_tag présent, on retourne sur la liste
      if (state.tagId) this.setView('list');
      // Sinon, on retourne sur les filtres
      else this.setView(PREVIEW_LIST);
    }

    if (currentView === 'edit') {
      // Si c'est le formulaire de modification, on retourne sur le détail
      this.setView('show');
    }

    if (currentView === 'new') {
      this.returnToInterventionFiltres();
    }
  }

  returnToInterventionFiltres() {
    this.store.setState({
      node: undefined, // On reset le noeud selectionné
      intervention: undefined, // On efface l'intervention courante
    });
    this.setView(PREVIEW_LIST);
  }
}
