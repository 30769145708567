import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { UgauInputNumberComponent } from '../../ugau-input-number/ugau-input-number.component';
import { UgauInputComponent } from '../../ugau-input/ugau-input.component';
import { UgauUploadInputDirectComponent } from '../../ugau-upload-input-direct.component';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { ToNumberPipe } from '../../../pipes/to-number.pipe';
import { TagMetasStateService } from '../../../state/tags-meta-state.service';
import { storage } from '../../../services/nhost';
import { forceDownloadFile } from '../../emplacement/emplacement-photo-full/extractAndForceDownloadFile';
import { tagMetaDocType } from '../../../db/schemas/tag.schema';
import { PanelStateService } from '../panel-state';
import { MetaField } from './MetaField';
import { UgauSelectUserContainer } from '../ugau-select-user.container';
import { UserPipe } from '../../../pipes/users/user.pipe';
import { retrieveUploadFilename } from '../../../utils/nhost-utils';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { UgauDatetimeComponent } from '../../ugau-datetime/ugau-datetime.component';

@Component({
  selector: 'app-meta-field-accordion',
  template: `
    <mat-accordion *ngIf="expandedStates$ | async as expandedStates">
      <mat-expansion-panel
        *ngFor="let meta of metas; let i = index; trackBy: trackById"
        [expanded]="expandedStates[i]"
        [disabled]="true"
      >
        <mat-expansion-panel-header (click)="togglePanel(i, $event)">
          <mat-panel-title class="c-black">
            <span>{{ meta.key === '' ? '-' : meta.key }}</span>
          </mat-panel-title>
          <mat-panel-description class="c-black">
            <span
              *ngIf="
                meta.type === 'text' ||
                meta.type === 'autocomplete' ||
                meta.type === 'textarea' ||
                meta.type === 'number'
              "
              >{{ meta.value === '' ? '-' : meta.value }}</span
            >
            <span
              *ngIf="
                meta.type === 'date' ||
                meta.type === 'date_debut' ||
                meta.type === 'date_fin'
              "
              >{{ meta.value | date: 'yyyy-MM-dd HH:mm' }}</span
            >
            <span *ngIf="meta.type === 'user'">{{
              meta.value | user: 'displayName'
            }}</span>
            <div *ngIf="meta.type === 'upload'" class="d-flex flex-ac">
              <mat-icon
                (click)="$event.stopPropagation(); downloadFile(meta.value)"
                class="mr1em pointer icon-download"
                >download</mat-icon
              >
              <span>{{ meta.value }}</span>
            </div>
          </mat-panel-description>

          <span *ngIf="!readonly">
            <mat-icon>{{
              expandedStates[i] === true
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'
            }}</mat-icon>
          </span>
        </mat-expansion-panel-header>

        <div class="meta-fields pt1em" (click)="$event.stopPropagation()">
          <!-- Key Input -->
          <app-ugau-input-autocomplete
            class="key-field"
            [showClearButton]="false"
            [value]="meta.key || ''"
            (inputChange)="updateMetaField(meta, 'key', $event)"
            [options]="(metaKeyOptions$ | async) || []"
            label="Clé"
          ></app-ugau-input-autocomplete>

          <!-- Conditional Value Fields -->
          <div
            class="value-field"
            *ngIf="meta.type === 'autocomplete' && !meta.key"
          >
            <div class="m1em" i18n>(En attente de la saisie d'une clé)</div>
          </div>

          <app-ugau-input-autocomplete
            class="value-field"
            *ngIf="
              (meta.type === 'autocomplete' || meta.type === 'text') && meta.key
            "
            [value]="meta.value || ''"
            [options]="(retrieveTagMetaValues$(meta.key) | async) || []"
            (inputChange)="updateMetaField(meta, 'value', $event)"
            label="Valeur"
          ></app-ugau-input-autocomplete>

          <app-ugau-input
            class="value-field"
            *ngIf="meta.type === 'textarea'"
            [value]="meta.value || ''"
            type="textarea"
            (inputChange)="updateMetaField(meta, 'value', $event)"
            label="Valeur"
          ></app-ugau-input>

          <app-ugau-input-number
            class="value-field"
            *ngIf="meta.type === 'number'"
            [value]="meta.value | toNumber"
            (inputChange)="updateMetaField(meta, 'value', $event.toString())"
            label="Valeur"
          ></app-ugau-input-number>

          <app-ugau-datetime
            class="value-field"
            *ngIf="
              meta.type === 'date' ||
              meta.type === 'date_debut' ||
              meta.type === 'date_fin'
            "
            [dateTime]="meta.value"
            (dateTimeChange)="updateMetaField(meta, 'value', $event.date)"
            label="Valeur"
          ></app-ugau-datetime>

          <app-ugau-upload-input-direct
            class="value-field"
            *ngIf="meta.type === 'upload'"
            [value]="meta.value || ''"
            (valueChange)="updateMetaField(meta, 'value', $event)"
            label="Valeur"
          ></app-ugau-upload-input-direct>

          <app-ugau-select-user
            *ngIf="meta.type === 'user'"
            class="value-field"
            i18n-label
            label="Valeur"
            [currentUser]="meta.value"
            (userChange)="updateMetaField(meta, 'value', $event)"
          ></app-ugau-select-user>
        </div>

        <mat-action-row>
          <app-ugau-button
            class="mr1em action-field"
            label=""
            iconName="delete"
            (click)="removeMetaField(meta)"
            [hasBorder]="false"
            classColor="danger"
          ></app-ugau-button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styles: [
    `
      :host {
        display: block;
        color: black;
        margin-bottom: 1em;
        ::ng-deep .mat-expansion-panel-header[aria-disabled='true'] {
          color: rgba(0, 0, 0, 0.87);
        }

        .meta-fields {
          display: flex;
          margin-top: 1em;
          margin-bottom: 1em;

          .key-field {
            width: 30%;
          }
          .value-field {
            flex-grow: 1;
          }
        }

        mat-expansion-panel {
          mat-expansion-panel-header {
            min-height: 48px;
            height: auto !important;
          }
        }

        @media (max-width: 992px) {
          .meta-fields {
            flex-direction: column;
            justify-content: flex-end;

            .key-field {
              width: 100%;
            }
            .value-field {
              width: 100%;
            }
          }
          .icon-download {
            overflow: visible;
          }
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UgauInputAutocompleteComponent,
    UgauInputComponent,
    UgauInputNumberComponent,
    UgauDatetimeComponent,
    UgauUploadInputDirectComponent,
    UgauButtonComponent,
    MatExpansionModule,
    MatIconModule,
    DatePipe,
    ToNumberPipe,
    AsyncPipe,
    NgIf,
    NgFor,
    UgauSelectUserContainer,
    UserPipe,
    NgxMatTimepickerModule,
  ],
})
export class MetaFieldAccordionComponent {
  private tagMetasStateService = inject(TagMetasStateService);
  private panelStateService = inject(PanelStateService);

  @Input() metas: MetaField[] = []; // Input for meta fields
  @Input() readonly = false; // Input to set component to readonly mode
  @Input() key!: string;

  @Output() removeMeta = new EventEmitter<MetaField>(); // Output to emit meta field removal
  @Output() updateMeta = new EventEmitter<{
    meta: MetaField;
    key: keyof tagMetaDocType;
    value: any;
  }>(); // Output to emit meta field changes
  expandedStates$ = this.panelStateService.getExpandedStates$(
    'meta_' + this.key
  ); // Observable for expanded states

  metaKeyOptions$ = this.tagMetasStateService.retrieveTagMetaKeys$;

  retrieveTagMetaValues$(key: string) {
    return this.tagMetasStateService.retrieveTagMetaValues$(key);
  }

  togglePanel(index: number, event: Event): void {
    if (this.readonly) return;
    this.panelStateService.togglePanel('meta_' + this.key, index);
    event.stopPropagation();
  }

  trackById(index: number, item: MetaField): string {
    return item.id;
  }

  updateMetaField(
    meta: MetaField,
    key: keyof tagMetaDocType,
    value: any
  ): void {
    this.updateMeta.emit({ meta, key, value });
  }

  removeMetaField(meta: MetaField): void {
    this.removeMeta.emit(meta);
  }

  async downloadFile(fileId: string | undefined) {
    if (!fileId) return;

    const filename = await retrieveUploadFilename(fileId);
    const completeUrl = fileId.includes('http')
      ? fileId
      : storage.getPublicUrl({ fileId });

    forceDownloadFile(completeUrl, filename);
  }
}
