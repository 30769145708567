import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { TagProvider } from '../../db/providers/tag-provider';
import { tagDocType, sharedTagDocType } from '../../db/schemas/tag.schema';
import { SentryService } from './../sentry.service';
import { auth, graphql } from './../nhost';
import { EventsService } from './../events.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  tags$: Observable<tagDocType[]> = this.tagProvider.getAll$.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private tagProvider: TagProvider,
    private events: EventsService
  ) {}

  resync() {
    this.tagProvider.resetSync();
  }

  getTag$(tagId: string): Observable<tagDocType | undefined> {
    return this.tags$.pipe(map((tags) => tags.find((el) => el.id === tagId)));
  }

  getTags() {
    return this.tagProvider.getAllValue();
  }
  getTagById(id: string) {
    return this.tagProvider.getAllValue().find((e) => e.id === id);
  }
  public getTagsObs() {
    return this.tagProvider.collection.find().$;
  }

  public getTagsByNom(nom: string) {
    if (nom) {
      return this.tagProvider.collection
        .find()
        .where('libelle')
        .regex({
          $regex: '.*' + nom + '.*',
          $options: 'i',
        })
        .exec();
    }
    return this.getTagsObs();
  }

  public getTagsByNomObs(nom: string) {
    if (nom) {
      return this.tagProvider.collection
        .find()
        .where('libelle')
        .regex({
          $regex: '.*' + nom + '.*',
          $options: 'i',
        }).$;
    }
    return this.getTagsObs();
  }

  public upsert(tag: tagDocType) {
    try {
      if (tag.id_user || tag.id_groupe) {
        return this.tagProvider.collection.incrementalUpsert({
          ...tag,
          updated_at: new Date().toISOString(),
        });
      }
      SentryService.captureMessage('Error Upsert Tag : User and group empty', {
        extra: tag,
      });
      return false;
    } catch (err) {
      SentryService.captureMessage('Error Upsert Tag : catch', {
        extra: tag,
      });
      console.log('UPSERT ERROR');
      console.log(err);
      return false;
    }
  }

  // Lorsque l'on supprime un tag, on doit reset la base emplacement
  public remove(tag: tagDocType) {
    try {
      return this.tagProvider.collection
        .incrementalUpsert({
          ...tag,
          deleted_at: new Date().toISOString(),
          deleted_by: auth.getUser()?.id,
        })
        .then(() => {
          this.events.publish('emplacements:reset');
          return true;
        })
        .catch((err) => {
          this.handleRemoveTagError(tag, err);
          return false;
        });
    } catch (err) {
      this.handleRemoveTagError(tag, err);
      return false;
    }
  }

  private handleRemoveTagError(tag: tagDocType, error: any) {
    SentryService.captureMessage('Error Remove Tag : catch', {
      extra: tag,
      error,
    });
    console.log('Issue Remove Tag', error);
  }

  async removeSharedTag(tag: tagDocType, sharedTag: sharedTagDocType) {
    // Call en direct
    const { error } = await graphql.request(
      `
      mutation deleteSharedTag($id: uuid!, $idTag: uuid!) {
        delete_shared_tag(where: {id: {_eq: $id}}) {
          affected_rows
        }
        update_tag(where: {id: {_eq: $idTag}}, _set: {updated_at: "now()"}) {
          affected_rows
        }
      }
    `,
      {
        id: sharedTag.id,
        idTag: tag.id,
      }
    );

    if (error) {
      SentryService.captureMessage('Error delete shared tag', {
        extra: sharedTag,
      });
      return false;
    }

    return true;
  }

  getArchivedTags() {
    return this.tagProvider.getAllValue().filter((el) => el.is_archived);
  }

  getArchivedTagsIds() {
    return this.getArchivedTags().map((el) => el.id);
  }

  getArchivedTagsIds$() {
    return this.tags$.pipe(
      map((tags) => tags.filter((el) => el.is_archived).map((el) => el.id))
    );
  }
}
