import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { map } from 'rxjs';
import { userAltType } from './../../db/schemas/groupe.schema';
import { UsersStateService } from './../../state/users-state.service';
import { userAutocompleteItem } from './userSelectItem';
import {
  IAutocompleteItem,
  UgauInputAutocompleteObjectsComponent,
} from '../ugau-input-autocomplete/ugau-input-autocomplete-objects.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-ugau-select-user',
  template: `
    <ng-container *ngIf="userChoices$ | async">
      <app-ugau-input-autocomplete-objects
        [value]="currentStatusSelectItem || undefined"
        [label]="label"
        [options]="(userChoices$ | async) || []"
        (inputChange)="valueChange($event)"
        [readOnly]="readOnly"
      ></app-ugau-input-autocomplete-objects>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UgauInputAutocompleteObjectsComponent, AsyncPipe],
})
export class UgauSelectUserContainer implements OnChanges {
  @Input() label: string = 'User';
  @Input() readOnly = false;
  @Input() currentUser!: string | null | undefined;

  currentStatusSelectItem!: userAutocompleteItem;

  userChoices$ = this.usersState.usersFromGroupes$.pipe(
    map((values: userAltType[]): userAutocompleteItem[] =>
      values.map((element) => new userAutocompleteItem(element))
    )
  );

  @Output() userChange = new EventEmitter<string | null>();

  constructor(private usersState: UsersStateService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.currentUser) {
      const choice = this.usersState.getUsersFromGroupes().find((e) => {
        return e.id === this.currentUser;
      });

      if (!choice) {
        // Il est possible que l'utilisateur courant ne puisse pas voir l'utilisateur sélectionné
        // Car il n'est pas dans le groupe de l'utilisateur sélectionné (ou autre)
        // Dans ce cas, on ne fait rien ?
        console.log(
          'UGAUSelectUser: User not found in groupes',
          this.currentUser
        );
        return;
      }
      this.currentStatusSelectItem = new userAutocompleteItem(choice);
    }
  }

  valueChange($event: IAutocompleteItem | null) {
    if ($event?.item.id) return this.userChange.emit($event.item.id);
    this.userChange.emit(null);
  }
}
