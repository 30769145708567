import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerStateService {
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  spinner$ = this.spinnerSubject.asObservable();

  private spinnerEmplacementSubject = new BehaviorSubject<boolean>(false);
  spinnerEmplacement$ = this.spinnerEmplacementSubject
    .asObservable()
    .pipe(throttleTime(200, undefined, { leading: true, trailing: true }));

  showSpinnerUntilFirstNext(obs$: Observable<any>) {
    this.show();
    return obs$.subscribe({
      next: () => this.hide(),
      error: () => this.hide(),
      complete: () => this.hide(),
    });
  }

  show() {
    this.spinnerSubject.next(true);
  }

  hide() {
    this.spinnerSubject.next(false);
  }

  showEmplacement() {
    this.spinnerEmplacementSubject.next(true);
  }

  hideEmplacement() {
    this.spinnerEmplacementSubject.next(false);
  }
}
