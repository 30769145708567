/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Map, RasterSource } from 'mapbox-gl';
import { proxyCallMap } from '../proxyCallMap';
import { removeLayer } from '../removeLayer';
import { removeSource } from '../removeSource';
import { addMapAdministrativeLines } from './addMapAdministrativeLines';
import { addMapBuildings } from './addMapBuildings';
import { addMapCadastre } from './addMapCadastre';
import { addMapInfraNetworkLayer } from './addMapInfraNetworkLayer';
import { addMapLayerDUPSMV } from './addMapLayerDUPSMV';
import { addMapRepereRoutier } from './addMapRepereRoutier';
import { addMapSecuRouteOA } from './addMapSecuRouteOA';
import { addMapWaterLines } from './addMapWaterLines';
import {
  URI_lines_administratives,
  URI_points_repere_routiers,
  URI_securouteOA,
  URI_cadastral,
  URI_buildings,
  URI_urbanisme,
  URI_infraNetwork,
  layersChoices,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
  URI_hydrographie,
} from './layersChoices';

export const toggleLayerIGN = (
  map: Map,
  uri: string,
  enabled: boolean,
  additionnalLayers: any[] = []
) => {
  if (uri === URI_lines_administratives) {
    addMapAdministrativeLines(map, enabled);
  } else if (uri === URI_points_repere_routiers) {
    addMapRepereRoutier(map, enabled);
  } else if (uri === URI_securouteOA) {
    addMapSecuRouteOA(map, enabled);
  } else if (uri === URI_cadastral) {
    addMapCadastre(map, enabled);
  } else if (uri === URI_hydrographie) {
    addMapWaterLines(map, enabled);
  } else if (uri === URI_buildings) {
    addMapBuildings(map, enabled);
  } else if (uri === URI_urbanisme) {
    addMapLayerDUPSMV(map, enabled);
  } else if (uri === URI_infraNetwork) {
    addMapInfraNetworkLayer(map, enabled);
  } else {
    const choice = [...layersChoices, ...additionnalLayers].find(
      (layer) => layer.uri === uri
    );
    if (choice?.style) {
      addMapIgnDirectLayer(map, uri, choice, enabled);
    }
  }
};
/**
 * Toggles the visibility of various map layers on a Mapbox map based on the provided URI.
 *
 * @param {Map} map - The Mapbox map instance on which to toggle the layers.
 * @param {string} uri - The URI identifier for the specific map layer to toggle.
 * @param {boolean} enabled - A boolean indicating whether to enable (true) or disable (false) the specified layer.
 *
 * Supported URIs:
 * - `URI_lines_administratives`: Toggles administrative lines.
 * - `URI_points_repere_routiers`: Toggles repere routier points.
 * - `lines-cadastre`: Toggles cadastre lines.
 * - `lines-hydrographie`: Toggles hydrography lines.
 * - `buildings`: Toggles buildings.
 */

function addMapIgnDirectLayer(
  map: Map,
  uri: string,
  choice: any,
  remove = false
) {
  const sourceLib = uri.replace(/[^a-zA-Z0-9]/g, '');
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    `&layer=${uri}` +
    IGN_TILE.replace('&style=normal', `&style=${choice.style}`);
  loadIgnRasterSourceAndLayer(map, sourceLib, url, {
    minzoom: choice.minZoom,
    maxzoom: choice.maxZoom,
    bounds: choice.bounds,
  });
}
/**
 * Loads an IGN raster source and layer into the provided Mapbox map instance.
 * If the map's style is already loaded, the source and layer are added immediately.
 * Otherwise, the source and layer are added once the map's 'load' event is fired.
 *
 * @param {Map} map - The Mapbox map instance to which the source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster source.
 */

export function loadIgnRasterSourceAndLayer(
  map: Map,
  sourceLib: string,
  url: string,
  opts: Partial<RasterSource> | undefined = undefined
) {
  const addSourceAndLayer: () => void = () => {
    // Remove existing layer if it exists
    if (map.getLayer(`${sourceLib}-layer`))
      map.removeLayer(`${sourceLib}-layer`);
    // Remove existing source if it exists
    if (map.getSource(sourceLib)) map.removeSource(sourceLib);
    // Add the source and layer
    mapAddSandLIgnRaster(map, sourceLib, url, opts);
  };
  proxyCallMap({ mapInstance: map, performMapAction: addSourceAndLayer });
}
/**
 * Adds a raster source and layer to the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the raster source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster tiles.
 *
 * @example
 * ```typescript
 * mapAddSandLIgnRaster(map, 'mySourceLib', 'https://example.com/tiles/{z}/{x}/{y}.png');
 * ```
 */

function mapAddSandLIgnRaster(
  map: Map,
  sourceLib: string,
  url: string,
  opts: Partial<RasterSource> = {
    tileSize: 256,
    minzoom: 6,
    maxzoom: 18,
  }
) {
  map.addSource(sourceLib, {
    type: 'raster',
    tiles: [url],
    ...opts,
  });
  map.addLayer(
    {
      id: sourceLib + '-layer',
      type: 'raster',
      source: sourceLib,
      paint: {
        'raster-opacity': 0.8,
      },
    },
    'z-index-1'
  );
}
/**
 * Removes a specified raster layer and its source from the Mapbox map.
 *
 * @param map - The Mapbox map instance from which the layer and source will be removed.
 * @param libelle - The label used to identify the layer and source to be removed.
 */
export function mapRemoveSandLIgnRaster(map: Map, libelle: string) {
  removeLayer(map, `${libelle}-layer`);
  removeSource(map, `${libelle}`);
}
