import { Feature } from 'geojson';
import { ProductFamiliesType } from '../../../../@types_custom/ProductType';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import { reglementationDocType } from '../../db/schemas/reglementation.schema';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import {
  getIconMarkerForEmplacement,
  getFillColor,
} from '../../utils/emplacement/getImgEmplacement';
import { keyValueProductOrder } from '../../utils/product/getProductsOrder';

export function getFeatureForComposantWithGeolocProductfields(
  emplacement: emplacementDocType,
  familyTree: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[],
  productsOrder: keyValueProductOrder
): GeoJSON.Feature[] {
  const features: GeoJSON.Feature[] = [];

  emplacement.composants.forEach((composant) => {
    const productFieldsWithGeo = composant.composant_productfields.filter(
      (productField) =>
        productField.productfield.type === PRODUCTFIELD_TYPE.GEOLOCATION ||
        productField.productfield.type ===
          PRODUCTFIELD_TYPE.GEOLOCATION_BATIMENT
    );

    productFieldsWithGeo.forEach((productField) => {
      if (!productField.value) return;
      try {
        const geometry = JSON.parse(productField.value) as GeoJSON.Geometry;
        const image = getIconMarkerForEmplacement(
          { ...emplacement, composants: [composant] },
          familyTree,
          properties,
          fields,
          reglementations,
          productsOrder
        );
        const fillColor = getFillColor(emplacement);

        const feature: Feature = {
          geometry,
          type: 'Feature',
          properties: {
            id: emplacement.id,
            image,
            ...('index' in emplacement && { index: emplacement.index }),
            numeroensemble: emplacement.numero_ensemble ?? '',
            fillColor: fillColor || undefined,
            idComposant: composant.id,
          },
        };
        features.push(feature);
      } catch (error) {
        console.log('Error parsing geojson', error);
      }
    });
  });

  return features;
}
