import { Injectable } from '@angular/core';
import { ReglementationsStateService } from './reglementations-state.service';
import { ProductpropertiesStateService } from './productproperties-state.service';
import { ProductfieldsStateService } from './productfields-state.service';
import { combineLatest, map, shareReplay } from 'rxjs';
import { FabricantsStateService } from './fabricants-state.service';

@Injectable({
  providedIn: 'root',
})
export class CommonStateService {
  reglementations$ = this.reglementationsState.reglementations$;
  properties$ = this.productpropertiesState.productproperties$;
  fields$ = this.productfieldsState.productfields$;
  fabricants$ = this.fabricantsState.fabricants$;

  // One for all
  common$ = combineLatest([
    this.reglementations$,
    this.properties$,
    this.fields$,
    this.fabricants$,
  ]).pipe(
    map(([reglementations, properties, fields, fabricants]) => {
      return {
        reglementations,
        properties,
        fields,
        fabricants,
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    public reglementationsState: ReglementationsStateService,
    public productpropertiesState: ProductpropertiesStateService,
    public productfieldsState: ProductfieldsStateService,
    public fabricantsState: FabricantsStateService
  ) {}

  getCommon() {
    return {
      reglementations: this.reglementationsState.getReglementations(),
      properties: this.productpropertiesState.getProductproperties(),
      fields: this.productfieldsState.getProductfields(),
      fabricants: this.fabricantsState.getFabricants(),
    };
  }
}
