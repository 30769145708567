import { gql } from 'graphql-request';

export const QUERY_GET_USERS = gql`
  query MyQuery(
    $where: users_bool_exp!
    $order_by: [users_order_by!]!
    $limit: Int!
  ) {
    users(where: $where, order_by: $order_by, limit: $limit) {
      id
      displayName
      phoneNumber
      email
      defaultRole
      user_groupes {
        id
        role
        groupe {
          id
          libelle
          abonnement
        }
      }
      createdAt
      updatedAt
      deleted_bool: disabled
    }
  }
`;
