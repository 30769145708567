import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { Injectable } from '@angular/core';
import { QUERY_GET_USERS } from '../queries/users.queries';
import {
  usersDocType,
  usersSchema,
  usersSchemaLiteral,
} from '../schemas/users.schema';
import { graphql } from './../../services/nhost';
import { Checkpoint } from '../interfaces/Checkpoint.type';

@Injectable({
  providedIn: 'root',
})
export class UsersProvider extends AbstractProvider<usersDocType> {
  schema = usersSchema;
  schemaLiteral = usersSchemaLiteral;
  enablePush = false;

  protected migrationStrategies = {
    1: function (oldDoc: usersDocType) {
      return null;
    },
  };

  async getGetPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<usersDocType, Checkpoint>> {
    // Un peu capilotracté mais c'est pour pouvoir récupérer les utilisateurs liés
    // aux mêmes villes que celles présentes dans le groupe de l'utilisateur actuel
    const variables = {
      where: {
        _or: this.getOrForQueryCamelCase(lastCheckpoint),
      },
      order_by: this.getOrderByForQueryCamelCasse(),
      limit: this.BATCH_SIZE,
    };
    const { data, error } = await graphql.request(QUERY_GET_USERS, variables);

    if (error || !data.users) {
      console.log(
        `Erreur lors de la récupération des utilisateurs sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.users;
    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updatedAt,
            },
    };
  }
}
