<div class="database-status c-black">
  <div matSubheader i18n>État de la base de données locale</div>
  <div class="m1em">
    <div
      *ngFor="let status of collectionsStatus$ | async; trackBy: trackByName"
      class="p05em"
    >
      <div matLine class="d-flex flex-wrap flex-ac flex-sb">
        <div class="flex-item-small">
          {{ status.name }}
        </div>
        <div class="flex-item-small2">
          <ng-container *ngIf="status.count | async as count; else tiret">
            {{ count }} élément{{ count > 1 ? 's' : '' }}
          </ng-container>
          <ng-template #tiret> - </ng-template>
        </div>
        <div class="flex-item-big m1em">
          Sync. : {{ status.replicationStatus | async }}
          {{
            (status.lastCheckpoint | async)
              ? '(' + (status.lastCheckpoint | async) + ')'
              : ''
          }}, Erreurs :
          {{ (status.replicationErrors | async)?.length || 0 }}
        </div>
      </div>
    </div>
  </div>
</div>
