import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, take } from 'rxjs';
import { tagDocType, sharedTagDocType } from '../../../db/schemas/tag.schema';
import { GroupePipe } from '../../../pipes/groupes/groupe.pipe';
import { SharedTagPipe } from '../../../pipes/tags/shared-tag.pipe';
import { UserPipe } from '../../../pipes/users/user.pipe';
import { EventsService } from '../../../services/events.service';
import { trackByIdFn } from '../../../services/trackByFunctions.utils';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauDateComponent } from '../../ugau-date/ugau-date.component';
import { PanelStateService } from '../panel-state';
import { UgauSelectGroupeContainer } from '../ugau-select-tag-groupe.container';
import { TagService } from '../../../services/tag/tag.service';
import { DialogPromptBoolComponent } from '../../dialogs/dialog-prompt-bool/dialog-prompt-bool.component';
import { UgauSelectUserContainer } from '../ugau-select-user.container';

@Component({
  selector: 'app-tag-form-shared-tags',
  templateUrl: './tag-form-shared-tag.component.html',
  styleUrls: ['./tag-form-shared-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    UgauSelectGroupeContainer,
    UgauSelectUserContainer,
    MatCheckboxModule,
    UgauDateComponent,
    NgIf,
    UgauButtonComponent,
    AsyncPipe,
    UserPipe,
    GroupePipe,
    MatExpansionModule,
    SharedTagPipe,
    MatIconModule,
  ],
})
export class TagSharedTagsComponent implements OnDestroy {
  trackById = trackByIdFn;

  private sharedTagsSubject$ = new BehaviorSubject<any[]>([]);
  sharedTags$ = this.sharedTagsSubject$.asObservable();
  expandedStates$ = this.panelStateService.getExpandedStates$('shared_tags');

  @Input() tag: tagDocType | null = null;
  @Input() readonly = false;
  @Input()
  public set sharedTags(value: any[] | undefined) {
    this.sharedTagsSubject$.next(value ?? []);
  }

  @Output() sharedTagsChange = new EventEmitter<any[]>();

  constructor(
    private tagService: TagService,
    private events: EventsService,
    private dialog: MatDialog,
    private panelStateService: PanelStateService
  ) {}

  ngOnDestroy(): void {
    this.sharedTagsSubject$.next([]);
  }

  updateSharedTagByIndex(property: string, value: any, index: number) {
    const sharedTags = this.sharedTagsSubject$.getValue().map((tag, i) => {
      if (i === index) {
        return {
          ...tag,
          [property]: value,
        };
      }
      return tag;
    });

    this.sharedTagsSubject$.next(sharedTags);
    this.sharedTagsChange.emit(sharedTags);
  }

  removeSharedTag(sharedTag: sharedTagDocType) {
    const dialog = this.dialog.open(DialogPromptBoolComponent, {
      width: '350px',
      data: {
        data: $localize`Êtes vous certain de vouloir supprimer ce partage ?`,
      },
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((bool: boolean) => {
        if (bool === true) {
          this.doRemoveSharedTag(sharedTag);
        }
      });
  }
  async doRemoveSharedTag(sharedTag: sharedTagDocType) {
    if (!this.tag) return;

    const bool = await this.tagService.removeSharedTag(this.tag, sharedTag);
    if (bool) {
      const sharedTags = this.sharedTagsSubject$
        .getValue()
        .filter((st) => st.id !== sharedTag.id);
      this.sharedTagsSubject$.next(sharedTags);
      this.sharedTagsChange.emit(sharedTags);
      this.events.publish('resync');
    }
  }

  togglePanel(index: number) {
    if (!this.readonly)
      this.panelStateService.togglePanel('shared_tags', index);
  }
}
