import { Injectable } from '@angular/core';
import { auth } from './nhost';
import { AuthenticationServiceInterface } from './authentication.interface';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject, takeUntil } from 'rxjs';
import { EventsService } from './events.service';
import { ToolsService } from './tools.service';
import { SentryService } from './sentry.service';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseAuthenticationService
  implements AuthenticationServiceInterface
{
  isOnline: boolean | unknown = true;
  public getIsOnline() {
    return this.isOnline;
  }

  idUser!: string;
  defaultRole: string = '';
  displayName: string = '';
  email: string = '';

  protected destroy$ = new Subject<void>();

  refreshEnCours = false;

  public token$ = new Observable((observer: any) => {
    observer.next(auth.getAccessToken());
    auth.onTokenChanged(() => {
      observer.next(auth.getAccessToken());
    });
  });

  nhostSessionSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public tools: ToolsService,
    public event: EventsService,
    public router: Router
  ) {}

  abstract init(): Promise<void>;

  public getUserId() {
    const authUser = auth.getUser();
    if (authUser) return authUser.id;
    throw new Error("Impossible de récupérer l'id de l'utilisateur");
  }

  public getToken() {
    return auth.getAccessToken();
  }

  public isLoggedIn() {
    return auth.isAuthenticated();
  }

  public isLoggedInAsync() {
    return auth.isAuthenticatedAsync();
  }

  public async refreshToken() {
    if (!this.refreshEnCours) {
      this.refreshEnCours = true;
      await auth.refreshSession();
      this.refreshEnCours = false;
    }
  }

  public async logout() {
    try {
      await auth.signOut();
    } catch (e) {
      SentryService.captureException(e);
    }
    location.href = '/';
  }

  public async logoutAll() {
    try {
      await auth.signOut({ all: true });
    } catch (e) {
      SentryService.captureException(e);
    }
    location.href = '/';
  }

  public changeEmail(email: string) {
    return auth.changeEmail({ newEmail: email });
  }

  public changePassword(oldPass: string, newPass: string) {
    return auth.changePassword({ newPassword: newPass });
  }

  public getUser() {
    return auth.getUser();
  }

  public register(email: string) {
    return auth.signIn({
      email: email,
    });
  }

  public watchToken() {
    // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
    this.token$.pipe(takeUntil(this.destroy$)).subscribe((token) => {
      this.event.publish('auth:tokenChanged', token);
    });
  }

  public async login(user: any) {
    this.watchToken();

    const { session, error } = await auth.signIn({
      email: user.username,
      password: user.password,
    });

    if (error) {
      return { session: null, error };
    }

    if (session) {
      this.nhostSessionSubject$.next(session);
      if (environment.ENABLE_SENTRY) {
        Sentry.setUser({
          id: session.user.id,
          username: session.user.email,
          email: session.user.email,
        });
      }
      return { session, error: null };
    }

    return {
      session: null,
      error: { message: $localize`Erreur lors de l'authentification` },
    };
  }
}
