<mat-expansion-panel [expanded]="open" *ngIf="_confkey && _value">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <ng-container *ngIf="_confkey === 'conforme'">
        <span class="carre bg-green"></span> <span i18n>Conforme</span>
      </ng-container>
      <ng-container *ngIf="_confkey === 'nonConforme'">
        <span class="carre bg-red"></span> <span i18n>Non conforme</span>
      </ng-container>
    </mat-panel-title>
    <mat-panel-description>
      {{ _value[_confkey].count }}
      {{ elementsStr }}
      <span *ngIf="showPrices && _value[_confkey].total"
        >, {{ _value[_confkey].total | formatnumber }}€</span
      >
    </mat-panel-description>
  </mat-expansion-panel-header>

  <table mat-table [dataSource]="dataSource" class="w100" matSort>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef i18n>Image</th>
      <td mat-cell *matCellDef="let element">
        <div class="td-image">
          <img [src]="element.image" alt="" />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Produit</th>
      <td mat-cell *matCellDef="let element" class="td-product">
        {{ element.product }}
      </td>
    </ng-container>
    <ng-container matColumnDef="nb_emplacement">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n
        mat-sort-header
        class="th-emplacement"
      >
        Nombre
      </th>
      <td mat-cell *matCellDef="let element" class="td-emplacement">
        {{ element.nb_emplacement }}
      </td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n
        mat-sort-header
        class="th-count"
      >
        Quantité
      </th>
      <td mat-cell *matCellDef="let element" class="td-count">
        {{ element.count | formatnumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" class="td-comment">
        {{ element.comment }}
      </td>
    </ng-container>
    <ng-container matColumnDef="unit_price" *ngIf="showPrices">
      <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>
        Prix unitaire
      </th>
      <td mat-cell *matCellDef="let element" class="td-price">
        {{ element.unit_price | formatnumber }} € /
        {{ element.unit_type | transformUnit }}
      </td>
    </ng-container>
    <ng-container matColumnDef="total_price" *ngIf="showPrices">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="td-count-header"
        i18n
        mat-sort-header
      >
        Total (€)
      </th>
      <td mat-cell *matCellDef="let element" class="td-count">
        {{ element.total_price | formatnumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="id_emplacement">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="td-link-emplacement">
        <app-ugau-button
          matTooltip="Voir les emplacements concernés"
          iconName="visibility"
          label=""
          [hasBorder]="false"
          iconColor="primary"
          (click)="doClick(element)"
        ></app-ugau-button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="PAGE_SIZE_OPTIONS"
    [pageSize]="PAGE_SIZE"
    showFirstLastButtons
    (page)="PAGE_CHANGED($event)"
  />
</mat-expansion-panel>
