import { userAltType } from './../../db/schemas/groupe.schema';

export const retrieveUserDisplayName = (
  idUser: string,
  users: userAltType[]
) => {
  if (!idUser) return '';
  const user = users.find((el) => el.id === idUser);
  if (!user) return '';
  return user.displayName || '';
};
