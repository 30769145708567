import { PHOTO_DEFAULT } from '../../../enum/photo-default';

export function generatePopupElement() {
  const popupContent = document.createElement('div');
  popupContent.style.display = 'flex';
  popupContent.style.flexDirection = 'column';
  popupContent.style.justifyContent = 'center';
  popupContent.style.alignItems = 'center';
  return popupContent;
}

export function createImageElement(src: string) {
  const imageElement = document.createElement('img');
  imageElement.classList.add('w100');
  imageElement.src = src;
  imageElement.onerror = () => {
    imageElement.src = PHOTO_DEFAULT.DEFAULT;
  };
  return imageElement;
}

export function createBannerContainer(images: string[]) {
  const bannerContainer = document.createElement('div');
  bannerContainer.classList.add('image-banner-container');
  images.forEach((image) => {
    const imageElement = document.createElement('div');
    imageElement.classList.add(`img-${image}`);
    bannerContainer.appendChild(imageElement);
  });
  return bannerContainer;
}

export function createLinkElement(
  iconText: string,
  linkText: string,
  onClick: () => void
) {
  const linkElement = document.createElement('a');
  linkElement.classList.add('w100', 'ugau-button-popup');

  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.textContent = iconText;
  linkElement.appendChild(icon);

  const text = document.createElement('span');
  text.textContent = linkText;
  linkElement.appendChild(text);

  linkElement.onclick = onClick;

  return linkElement;
}

export function createHeaderElement(text: string) {
  const headerElement = document.createElement('div');
  headerElement.classList.add('mat-mdc-subheader', 'mdc-list-group__subheader');
  headerElement.innerHTML = text;
  return headerElement;
}

export function createBottomContainer(elements: HTMLElement[]) {
  const bottomContainer = document.createElement('div');
  bottomContainer.classList.add('bottom-container');
  elements.forEach((element) => bottomContainer.appendChild(element));
  return bottomContainer;
}
