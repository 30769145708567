import { NgIf, DatePipe, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { formatFULL } from '../../../utils/date-formats';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { TableComponentBase } from '../../table-base.component';
import { MatMenuModule } from '@angular/material/menu';
import {
  downloadArrayAsCSV,
  generateTimeName,
} from '../../../services/utils.service';
import { StockState } from '../../stock/stock.state';
import { ProductFamiliesTreeState } from '../../../state/product-families-tree-state.service';
import {
  composantDocType,
  emplacementDocType,
} from '../../../db/schemas/emplacement.schema';
import {
  EmplacementPipe,
  EmplacementIdPipe,
} from '../../../pipes/emplacements/emplacement.pipe';
import { v4 as uuidv4 } from 'uuid';
import { CommonStateService } from '../../../state/commun-state.service';
import { trackByIdFn } from '../../../services/trackByFunctions.utils';
import { EmplacementDetailStateService } from '../../../state/emplacement-detail-state.service';
import { generateComposantFromFamily } from '../../../utils/composant/generateComposantFromFamily';
import { getUserID } from '../../../services/nhost';
import { ProductSearchStateService } from '../../../state/product-search-state.service';
import { isConformeComposant } from '../../../utils/composant/isConformeComposant';
import { ProductNamePipe } from '../../../pipes/products/product-name-from-composant';
import { GetComposantListitemPipe } from '../../../pipes/composants/get-composant-listitem.pipe';
import { EmplacementsStateService } from '../../../state/emplacements-state.service';
import { getEmplacementLibelle } from '../../../utils/emplacement/getEmplacementLibelle';
import { getProductsOrder } from '../../../utils/product/getProductsOrder';
import { ComponentItemsOpenedState } from '../../../state/emplacement-composant-list-state.service';
import { getComposantName } from '../../../utils/composant/getComposantName';

@Component({
  selector: 'app-materiel-list',
  templateUrl: './materiel-list.component.html',
  styleUrls: ['./materiel-list.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    NgFor,
    NgIf,
    UgauButtonComponent,
    UgauInputAutocompleteComponent,
    MatMenuModule,
    EmplacementPipe,
    AsyncPipe,
    ProductNamePipe,
    EmplacementIdPipe,
    GetComposantListitemPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMaterielListComponent extends TableComponentBase<composantDocType> {
  key = 'materiel';
  private stockState = inject(StockState);
  private productTreeState = inject(ProductFamiliesTreeState);
  private commonState = inject(CommonStateService);
  private emplacementDetailState = inject(EmplacementDetailStateService);
  private emplacementsState = inject(EmplacementsStateService);
  private productSearchState = inject(ProductSearchStateService);
  private treeState = inject(ProductFamiliesTreeState);
  private productsOrder = getProductsOrder(this.treeState.getOriginalTree());
  private componentOpenedItems = inject(ComponentItemsOpenedState);
  trackById = trackByIdFn;

  showFilters = !this.isMobile;

  formatFULL = formatFULL;

  data$ = this.stockState.materiel$;
  batiments$ = this.stockState.depots$;

  displayedColumns: string[] = this.isMobile
    ? ['name', 'conforme', 'emplacement']
    : ['name', 'conforme', 'emplacement', 'created_at', 'updated_at'];
  exceptSearchForColumns: string[] = ['created_at', 'updated_at'];

  columnDefs: Record<string, { title: string }> = {
    name: {
      title: $localize`Libelle`,
    },
    conforme: {
      title: $localize`Conforme`,
    },
    emplacement: {
      title: $localize`Bâtiment`,
    },
    created_at: {
      title: $localize`Créé le`,
    },
    updated_at: {
      title: $localize`Modifié le`,
    },
  };

  customColumnOrder: Record<string, number> = {
    emplacement: 1,
    name: 2,
    conforme: 3,
  };

  navigateEmplacement(id: string) {
    this.stockState.routingState.navigateEmplacement(id);
  }

  async addNewMateriel(batiment: emplacementDocType | undefined = undefined) {
    const materialFam = await this.productTreeState.getMaterielFirstFamily();
    const batimentId = await this.productTreeState.getBatimentFirstFamilyId();
    if (!materialFam || !batimentId) {
      this.tools.launchErrorAlert(
        $localize`Erreur lors de la récupération du module matériel`
      );
      return;
    }

    // Si on a un batiment, on ajoute le materiel dans le batiment
    if (batiment) {
      const product = await this.productSearchState.chooseProduct(materialFam);
      if (!product) {
        return;
      }

      this.stockState.routingState.navigateEmplacement(batiment.id);
      setTimeout(() => {
        // Il faut ajouter un composant dans l'emplacement pour ajouter un materiel
        const materielComposant = generateComposantFromFamily(
          product,
          undefined,
          this.commonState.getCommon().reglementations,
          this.commonState.getCommon().fields,
          getUserID()
        );

        this.emplacementDetailState.updateEmplacement({
          ...batiment,
          composants: [...batiment.composants, materielComposant],
        });
      }, 800);
      return;
    }

    // Sinon on ajoute un nouveau batiment et on ajoute le materiel dedans
    this.stockState.routingState.navigateEmplacementCreate(
      undefined,
      uuidv4(),
      [batimentId, materialFam.id],
      true
    );
  }

  editMateriel(stock: composantDocType) {
    if (!stock.id_emplacement) return;
    // On envoie l'utilisateur sur l'emplacement correspondant au composant
    this.stockState.routingState.navigateEmplacement(
      stock.id_emplacement,
      true
    );
    // On ouvre le composant après un petit délai pour laisser le temps à la page de se charger

    setTimeout(
      () => {
        this.componentOpenedItems.openComposants([stock.id]);
      },
      this.isMobile ? 1200 : 800
    );
  }

  customSortingDataAccessor(data: composantDocType, sortHeaderId: string) {
    switch (sortHeaderId) {
      case 'emplacement':
        const emplacement = this.emplacementsState.getEmplacementById(
          data.id_emplacement
        );
        return getEmplacementLibelle(emplacement, this.productsOrder);
      case 'name':
        return data.product?.name;
      case 'conforme':
        return isConformeComposant(
          data,
          null,
          this.commonState.getCommon().properties,
          this.commonState.getCommon().fields,
          this.commonState.getCommon().reglementations
        )
          ? $localize`Oui`
          : $localize`Non`;
      default:
        return (data as any)[sortHeaderId];
    }
  }

  customCreateFilter(
    data: composantDocType,
    key: string,
    value: string
  ): boolean | undefined {
    if (key === 'emplacement' && value) {
      const emplacement = this.emplacementsState.getEmplacementById(value);
      const libelle = getEmplacementLibelle(emplacement, this.productsOrder);
      return libelle.toLowerCase().includes(value.toLowerCase());
    }
    if (key === 'name' && value) {
      return data.product?.name
        ? data.product.name.toLowerCase().includes(value.toLowerCase())
        : false;
    }
    if (key === 'conforme' && value) {
      const { reglementations, properties, fields } =
        this.commonState.getCommon();
      const conforme = isConformeComposant(
        data,
        null,
        properties,
        fields,
        reglementations
      )
        ? $localize`Oui`
        : $localize`Non`;
      return conforme.toLowerCase().includes(value.toLowerCase());
    }
    return undefined;
  }

  customGetFilterObject(
    data: composantDocType,
    key: string
  ): string | undefined {
    if (key === 'emplacement') {
      const emplacement = this.emplacementsState.getEmplacementById(
        data.id_emplacement
      );
      return getEmplacementLibelle(emplacement, this.productsOrder);
    }
    if (key === 'name') {
      return data.product?.name;
    }
    if (key === 'conforme') {
      return isConformeComposant(
        data,
        null,
        this.commonState.getCommon().properties,
        this.commonState.getCommon().fields,
        this.commonState.getCommon().reglementations
      )
        ? $localize`Oui`
        : $localize`Non`;
    }
    return undefined;
  }

  export() {
    const i18nKeys = {
      name: $localize`Libelle`,
      conforme: $localize`Conforme`,
      created_at: $localize`Créé le`,
      updated_at: $localize`Modifié le`,
    };
    const data = this.dataSource.filteredData;
    const csvArray = data.map((el) => {
      return {
        [i18nKeys.name]: getComposantName(el),
        [i18nKeys.conforme]: isConformeComposant(
          el,
          null,
          this.commonState.getCommon().properties,
          this.commonState.getCommon().fields,
          this.commonState.getCommon().reglementations
        )
          ? $localize`Oui`
          : $localize`Non`,
        [i18nKeys.created_at]: el.created_at,
        [i18nKeys.updated_at]: el.updated_at,
      };
    });

    const filename = generateTimeName('export_stock');
    downloadArrayAsCSV(csvArray, filename);
  }
}
