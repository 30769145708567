import { BehaviorSubject, Observable } from 'rxjs';

export abstract class BooleanStateService {
  private stateSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // Observable pour accéder à l'état
  get state$(): Observable<boolean> {
    return this.stateSubject$.asObservable();
  }

  // Méthode pour obtenir la valeur actuelle de l'état
  getValue(): boolean {
    return this.stateSubject$.getValue();
  }

  // Méthode pour modifier l'état
  setValue(value: boolean): void {
    if (typeof value !== 'boolean') {
      throw new Error('La valeur doit être un boolean');
    }
    this.stateSubject$.next(value);
  }

  // Méthodes pour définir l'état à true ou false
  setTrue(): void {
    this.setValue(true);
  }

  setFalse(): void {
    this.setValue(false);
  }

  // Méthode pour inverser l'état actuel
  toggle(): void {
    this.setValue(!this.getValue());
  }
}
