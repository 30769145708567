import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const interventionSchemaLiteral = jsonSchema({
  name: 'intervention',
  nameCleanForUser: 'Interventions',
  autoMigrate: true,
  version: 0,
  type: 'object',
  required: [
    'id',
    'numero',
    'name',
    'description',
    'id_tag',
    'status',
    'created_at',
    'updated_at',
    'emplacements',
    'documents',
  ],
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    numero: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    id_tag: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    date_preparation: {
      type: 'string',
    },
    date_valide: {
      type: ['string', 'null'],
    },
    date_en_cours: {
      type: ['string', 'null'],
    },
    date_termine: {
      type: ['string', 'null'],
    },
    etat_depart: {
      type: ['object', 'null'],
    },
    etat_fin: {
      type: ['object', 'null'],
    },
    status: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    created_by: {
      type: ['string', 'null'],
    },
    updated_at: {
      type: 'string',
    },
    updated_by: {
      type: ['string', 'null'],
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_by: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
    emplacements: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'id_emplacement', 'created_at'],
        properties: {
          id: {
            type: 'string',
          },
          id_emplacement: {
            type: 'string',
          },
          created_at: {
            type: 'string',
          },
          created_by: {
            type: 'string',
          },
          updated_at: {
            type: 'string',
          },
          updated_by: {
            type: 'string',
          },
          date_start_intervention: {
            type: ['string', 'null'],
          },
          date_end_intervention: {
            type: ['string', 'null'],
          },
        },
      },
    },
    documents: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'document_name', 'document_type', 'chemin'],
        properties: {
          id: {
            type: 'string',
          },
          document_name: {
            type: 'string',
          },
          document_type: {
            type: 'string',
          },
          chemin: {
            type: 'string',
          },
          created_at: {
            type: 'string',
          },
          created_by: {
            type: ['string', 'null'],
          },
          updated_at: {
            type: 'string',
          },
          updated_by: {
            type: ['string', 'null'],
          },
          deleted_at: {
            type: ['string', 'null'],
          },
          deleted_by: {
            type: ['string', 'null'],
          },
        },
      },
    },
  },
  primaryKey: 'id',
} as const);

const interventionSchemaTyped = toTypedRxJsonSchema(interventionSchemaLiteral);

// aggregate the document type from the schema
export type interventionDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof interventionSchemaTyped
>;
export type emplacementInterventionDocType =
  interventionDocType['emplacements'][number];
export type documentInterventionDocType =
  interventionDocType['documents'][number];

// create the typed RxJsonSchema from the literal typed object.
export const interventionSchema: RxJsonSchema<interventionDocType> =
  interventionSchemaLiteral;
