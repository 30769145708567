import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { environment } from '../../environments/environment';
import { UGAU_USER_ROLE } from '../enum/ugau-user-role';
import { AuthenticationService } from './authentication.service';
import { EventsService } from './events.service';
import { SentryService } from './sentry.service';
import { ToolsService } from './tools.service';
import { EventString } from './analytics.EventString';
import { EVENT_TYPE } from './analytics.EventType';

@Injectable({
  providedIn: 'root',
})
export class AnalyticService {
  isReady = true;

  settings = {
    apiKey: environment.SEGMENT_API,
    retryQueue: true,
    addBundledMetadata: true,
  };

  constructor(
    public events: EventsService,
    public platform: Platform,
    public auth: AuthenticationService,
    public tools: ToolsService
  ) {}

  private mustNotBeTracked() {
    // Si Segment est pas prêt OU si c'est un utilisateur ADMIN, alors on ne doit PAS tracker
    const isSuperAdmin =
      this.auth.defaultRole === UGAU_USER_ROLE.SUPER_ADMIN.toString();
    return !this.isReady || isSuperAdmin;
  }

  public identify() {
    if (this.mustNotBeTracked()) {
      return;
    }

    if (!this.auth.getUserId()) {
      SentryService.captureMessage(
        'Segment Identify : this.auth.idUser est VIDE'
      );
      return;
    }

    const params = {
      displayName:
        this.auth.displayName === '' ? this.auth.email : this.auth.displayName,
      email: this.auth.email,
      isMobile: this.tools.isMobile(),
      ...this.getScreenInfo(),
    };
    const eventString = this.auth.getUserId();

    this.doSegmentCall(EVENT_TYPE.IDENTIFY, eventString, params);
  }

  public trackEvent(eventString: EventString, _params: any = {}) {
    // Est ce que l'utilisateur ne doit pas être tracké ?
    if (this.mustNotBeTracked()) {
      return;
    }

    this.doSegmentCall(EVENT_TYPE.TRACK, eventString, _params);
  }

  private doSegmentCall(typeEvent: string, eventString: string, _params: any) {
    try {
      if (typeEvent === EVENT_TYPE.IDENTIFY.toString()) {
        (window as any).analytics.identify(eventString, _params);
      }
      if (typeEvent === EVENT_TYPE.TRACK.toString()) {
        (window as any).analytics.track(eventString, {
          ...this.getScreenInfo(),
          ..._params,
        });
      }
    } catch (e) {
      SentryService.captureException(e, {
        step: 'Error AnalyticService::doSegmentCall',
        typeEvent: typeEvent,
        eventString: eventString,
        _params: _params,
      });
    }
  }

  private getScreenInfo() {
    return {
      origin: this.platform.is('mobile') ? 'app' : 'web',
      screenWidth: document.body.offsetWidth,
      screenHeight: document.body.offsetHeight,
    };
  }
}
