import { ProductFamiliesType, ProductType } from '@types_custom/ProductType';
import {
  CartStore,
  CartStoreItem,
  CartSubformeStoreType,
  StoreProductLine,
} from '@types_custom/cart-store';
import { getProductImageFromFamilies } from '../utils/productfamilies-utils.service';
import { getDesignationComposant } from '../utils/composant/getDesignationComposant';
import { multiply } from '../utils/multiply';
import { composantDocType } from '../db/schemas/emplacement.schema';

export const generateCartStoreItem = (
  emplacementId: string,
  composant: composantDocType,
  comment: string,
  product: ProductType,
  families: ProductFamiliesType[],
  unit: string,
  quantity: number,
  productPrice: number
): StoreProductLine => {
  const designation = getDesignationComposant(composant);

  return {
    shortcut: product.shortcut,
    image: getProductImageFromFamilies(product.id, families),
    product: designation ?? product.name,
    unit_type: unit,
    unit_price: productPrice,
    count: quantity,
    comment: comment,
    nb_emplacement: 1,
    id_emplacement: [emplacementId],
    total_price: multiply(productPrice, quantity),
    ids_composant: [composant.id],
  };
};

export const increaseCountAndTotal = (
  cartStore: CartStoreItem | CartSubformeStoreType,
  price: number
) => {
  cartStore.count++;
  cartStore.total += price;
};

export const initStatStoreItem = (
  familyName: string
): {
  nonConforme: CartSubformeStoreType;
  conforme: CartSubformeStoreType;
  total: number;
  count: number;
  name: string;
  childrens: CartStore;
} => {
  return {
    nonConforme: {
      count: 0,
      total: 0,
      list: [],
    },
    conforme: {
      count: 0,
      total: 0,
      list: [],
    },
    count: 0,
    total: 0,
    name: familyName,
    childrens: {},
  };
};
