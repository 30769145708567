// mapbox-custom-control.ts
import { IControl, Map } from 'mapbox-gl';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
} from '@angular/core';
import { MapboxControlComponent } from './mapbox-control.component';
import { getFromLocalStorage } from '../../../utils/localstorage-utils.service';
import { layersChoices } from './layersChoices';
import { toggleLayerIGN } from './toggleLayerIGN';
import { uniqBy } from 'lodash';
import { Subscription } from 'rxjs';

export class MapboxCustomControl implements IControl {
  private controlContainer!: HTMLElement;
  private componentRef!: ComponentRef<MapboxControlComponent>;
  private map: Map | undefined;
  private sub: Subscription | undefined;

  constructor(
    private injector: EnvironmentInjector,
    private appRef: ApplicationRef,
    private forcedLayers: string[]
  ) {}

  onAdd(map: Map): HTMLElement {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    const id = map.getContainer().id;
    const savedLayers = getFromLocalStorage('ENABLED_IGN-LAYERS_' + id, true);
    const layersEnabled: any[] = savedLayers
      ? savedLayers.map((e: any) => e.uri)
      : [];
    if (layersEnabled.length > 0) {
      layersEnabled.forEach((layer) => {
        toggleLayerIGN(map, layer, false, savedLayers);
      });
    }
    if (this.forcedLayers.length > 0) {
      this.forcedLayers.forEach((layer) => {
        toggleLayerIGN(map, layer, false, savedLayers);
      });
    }
    // Créer le composant Angular
    this.componentRef = createComponent(MapboxControlComponent, {
      environmentInjector: this.injector,
    });
    this.componentRef.instance.map = map;
    this.componentRef.instance.styles = uniqBy(
      [...layersChoices, ...savedLayers],
      'uri'
    );
    this.componentRef.instance.options = {
      activeLayers: layersEnabled,
    };

    this.appRef.attachView(this.componentRef.hostView);

    // Obtenir l'élément DOM du composant
    const componentElement = (this.componentRef.hostView as any)
      .rootNodes[0] as HTMLElement;

    // Écouter les événements du composant
    this.sub = this.componentRef.instance.styleChanged.subscribe(
      (newStyle: string) => {
        const layerExists = this.map?.getLayer(newStyle + '-layer');
        const layerAltExists = this.map?.getLayer(
          newStyle.replace(/[^a-zA-Z0-9]/g, '') + '-layer'
        );
        const exists = layerExists ?? layerAltExists;

        toggleLayerIGN(
          map,
          newStyle,
          !!exists,
          this.componentRef.instance.styles
        );
      }
    );

    // Ajouter le composant au conteneur
    this.controlContainer.appendChild(componentElement);

    return this.controlContainer;
  }

  onRemove(): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }
    if (this.controlContainer.parentNode) {
      this.controlContainer.parentNode.removeChild(this.controlContainer);
    }
    this.map = undefined;
    this.sub?.unsubscribe();
  }

  getDefaultPosition(): string {
    return 'top-right';
  }
}
