import { Pipe, PipeTransform } from '@angular/core';
import { EMPLACEMENT_PHOTO_TYPE } from '../../enum/emplacement-photo-type';
import { PHOTO_DEFAULT } from '../../enum/photo-default';
import { AuthenticationService } from '../../services/authentication.service';
import {
  emplacementDocType,
  emplacementPhotoDocType,
} from '../../db/schemas/emplacement.schema';
import { getSrcPhoto } from './../../utils/emplacement-photos-utils.service';
import { Observable, catchError, map, of } from 'rxjs';

@Pipe({
    name: 'emplacementFirstPhoto',
    standalone: true,
})
export class EmplacementFirstPhotoPipe implements PipeTransform {
  constructor(private auth: AuthenticationService) {}

  transform(
    emplacement:
      | Observable<emplacementDocType | null>
      | emplacementDocType
      | null
  ): Observable<string> {
    if (emplacement instanceof Observable) {
      return emplacement.pipe(
        map((empl) => this.findFirstPhotoSrc(empl)),
        catchError(() => of(PHOTO_DEFAULT.DEFAULT))
      );
    }
    return of(this.findFirstPhotoSrc(emplacement));
  }

  private findFirstPhotoSrc(emplacement: emplacementDocType | null): string {
    if (!emplacement?.emplacement_photos.length) {
      return PHOTO_DEFAULT.DEFAULT;
    }
    const photo = this.findFirstPhoto(emplacement);
    return photo
      ? getSrcPhoto(photo, this.auth.getIsOnline())
      : PHOTO_DEFAULT.DEFAULT;
  }

  private findFirstPhoto(
    emplacement: emplacementDocType
  ): emplacementPhotoDocType | undefined {
    const validPhotos = emplacement.emplacement_photos
      .filter(
        (photo) =>
          !photo.deleted_at && photo.type_photo === EMPLACEMENT_PHOTO_TYPE.FACE
      )
      .sort(
        (a, b) =>
          new Date(b.created_at ?? 0).getTime() -
          new Date(a.created_at ?? 0).getTime()
      );

    return validPhotos[0];
  }
}

// export const isDistantAndNotNhost = (src: string) => {
//   // Vialitics => Pas de ?
//   // Vaisala => ? présent, donc check si vionice est présent
//   // Mapillary => ? présent, donc check si mapillary est présent
//   return (
//     src.includes('http') && // distant
//     src.includes('vionice') && // vaisala
//     !src.includes('nhost') // not nhost
//   );
// };

export const findFirstPhotoSrc = (
  emplacement: emplacementDocType,
  isOnline: boolean | unknown
) => {
  const photoFace = findFirstPhoto(emplacement);
  if (photoFace) {
    return getSrcPhoto(photoFace, isOnline);
  }
  return PHOTO_DEFAULT.DEFAULT;
};

export const findFirstPhoto = (
  emplacement: emplacementDocType
): emplacementPhotoDocType | undefined => {
  const photos = emplacement.emplacement_photos
    .filter((el: emplacementPhotoDocType) => !el.deleted_at)
    .filter(
      (el: emplacementPhotoDocType) =>
        el.type_photo === EMPLACEMENT_PHOTO_TYPE.FACE
    )
    .sort((e1: emplacementPhotoDocType, e2: emplacementPhotoDocType) => {
      if (!e1.created_at || !e2.created_at) return 0;
      return e1.created_at < e2.created_at ? 1 : -1;
    });

  if (!photos.length) return undefined;
  return photos[0];
};
