import { Platform } from '@ionic/angular/standalone';
import {
  Component,
  OnInit,
  NgZone,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';
import { AuthenticationService } from './services/authentication.service';
import { fromEvent } from 'rxjs';
import { App } from '@capacitor/app';
import { ToolsService } from './services/tools.service';
import { clearCacheAndIDBForLiveUpdate } from './services/clearDatabasesAndCache';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpriteManager } from './components/objet-osmap/sprite.service';
import { reloadApp } from './services/reloadApp.util';
import { RoutingStateAppRestoredService } from './state/routing-state-app-restored.service';
import { Router, RouterOutlet } from '@angular/router';
import 'zone.js/plugins/zone-patch-rxjs';
import { AppModaleComponent } from './components/dialogs/app-modale.component';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet><app-modale #modale></app-modale>`,
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, AppModaleComponent],
})
export class AppComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private appRestoredListener: any;
  @ViewChild(AppModaleComponent) modale!: AppModaleComponent;

  constructor(
    private authService: AuthenticationService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private tools: ToolsService,
    private ngZone: NgZone,
    private spriteService: SpriteManager,
    private appRestoredService: RoutingStateAppRestoredService,
    private modaleService: ModalService,
    private router: Router
  ) {
    this.setupUrlOpenListener();
    this.setupAppRestoredListener();

    // Fix pour l'affichage chrome sous iphone (et potentiellement d'autres)
    this.fixDisplayVhToPx();
    this.spriteService.init();
  }

  /**
   * Permet de gérer l'ouverture de l'application sur mobile via un lien externe
   * L'url est de la forme ugau://..
   * L'url est utilisée pour gérer les redirections pour la connexion via "email magic link"
   */
  setupUrlOpenListener() {
    if (this.tools.isMobile()) {
      App.addListener('appUrlOpen', (data) => {
        const url = data.url.replace('ugau://', '');

        // Ex : ugau://login?error=invalid-ticket&errorDescription=Invalid+or+expired+verification+ticket
        const pageStr = url.split('?')[0];
        const page = pageStr === 'login' ? '' : pageStr;
        const queryParams = url.split('?')[1];
        const queryParamsObj = queryParams
          ? queryParams
              .split('&')
              .reduce((acc: { [key: string]: string }, param) => {
                const [key, value] = param.split('=');
                acc[key] = value;
                return acc;
              }, {})
          : {};

        this.router.navigate(['/' + page], {
          queryParams: {
            returnUrl: queryParams,
            ...queryParamsObj,
          },
          replaceUrl: true,
        });
      });
    }
  }

  async setupAppRestoredListener() {
    if (!this.platform.is('android')) {
      // Pas de listener sur le web
      return;
    }

    let isCalled = false;
    this.appRestoredListener = await App.addListener(
      'appRestoredResult',
      (data) => {
        isCalled = true;
        console.log('Restored data found : ', data);
        this.appRestoredService.setAppStoredResult(data);
        this.removeAppRestoredListener();
      }
    );

    setTimeout(() => {
      if (!isCalled) {
        this.removeAppRestoredListener();
      }
    }, 10000);
  }

  removeAppRestoredListener() {
    if (this.appRestoredListener) {
      this.appRestoredListener.remove();
      this.appRestoredListener = null;
    }
  }

  public ngOnInit(): void {
    this.authService.init().then(() => {
      this.initializeApp().then(() => {
        this.modaleService.setModaleComponent(this.modale);
      });
    });
  }

  public async initializeApp() {
    await this.platform.ready();

    // Désactivation du bouton retour sur Android
    this.platform.backButton.subscribeWithPriority(9999, () => {});

    this.checkUpdateServiceWorker();
  }

  private checkUpdateServiceWorker() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(takeUntilDestroyed(this.destroyRef))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe((event: VersionEvent) => {
        if (event.type === 'VERSION_READY') {
          const readyEvent = event as VersionReadyEvent;
          const currentVersion = readyEvent.currentVersion;
          const latestVersion = readyEvent.latestVersion;

          console.log(
            `Current version: ${currentVersion.hash}, New version: ${latestVersion.hash}`
          );

          if (confirm($localize`Nouvelle version disponible, installer ?`)) {
            this.tools.toastError($localize`Traitement en cours ...`);
            clearCacheAndIDBForLiveUpdate().then(() => {
              reloadApp('App::checkUpdateServiceWorker');
            });
          }
        }
      });
  }

  private fixDisplayVhToPx() {
    this.updateViewportUnit();
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(takeUntilDestroyed(this.destroyRef))
        // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
        .subscribe(() => {
          this.updateViewportUnit();
        });
    });
  }

  public updateViewportUnit() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
