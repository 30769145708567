import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LngLat } from 'mapbox-gl';

/**
 * Interface representing the selection state for Mapbox.
 *
 * @interface
 * @property {string[]} emplacementIds - An array of emplacement IDs.
 * @property {LngLat | undefined | null} startPos - The starting position as a LngLat object, which can be undefined or null.
 * @property {LngLat | undefined | null} endPos - The ending position as a LngLat object, which can be undefined or null.
 */
export interface MapboxSelectionType {
  emplacementIds: string[];
  startPos: LngLat | undefined | null;
  endPos: LngLat | undefined | null;
}

/**
 * @class MapboxSelectionsStateService
 * @description Service to manage the state of Mapbox selections.
 */
@Injectable({
  providedIn: 'root',
})
export class MapboxSelectionsStateService {
  /**
   * @private
   * @property {BehaviorSubject<MapboxSelectionType>} mapboxSelectionsSubject$ - Subject to hold the current state of Mapbox selections.
   */
  private mapboxSelectionsSubject$: BehaviorSubject<MapboxSelectionType> =
    new BehaviorSubject<MapboxSelectionType>({
      emplacementIds: [],
      startPos: undefined,
      endPos: undefined,
    });

  /**
   * @public
   * @property {Observable<MapboxSelectionType>} mapboxSelections$ - Observable to provide the current state of Mapbox selections.
   */
  mapboxSelections$: Observable<MapboxSelectionType> =
    this.mapboxSelectionsSubject$
      .asObservable()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /**
   * @public
   * @method setMapboxSelections
   * @description Sets the current state of Mapbox selections.
   * @param {MapboxSelectionType} selections - The new state of Mapbox selections.
   */
  setMapboxSelections(selections: MapboxSelectionType) {
    this.mapboxSelectionsSubject$.next(selections);
  }

  /**
   * @public
   * @method getMapboxSelections
   * @description Gets the current state of Mapbox selections.
   * @returns {MapboxSelectionType} The current state of Mapbox selections.
   */
  getMapboxSelections(): MapboxSelectionType {
    return this.mapboxSelectionsSubject$.getValue();
  }

  /**
   * @public
   * @method reset
   * @description Resets the state of Mapbox selections to the initial state.
   */
  reset() {
    this.mapboxSelectionsSubject$.next({
      emplacementIds: [],
      startPos: undefined,
      endPos: undefined,
    });
  }
}
