import { composantDocType } from '../../db/schemas/emplacement.schema';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';

/**
 * Retrieve the designation of a component
 * Check if the component has a designation field and return it
 *
 * @param composant
 * @returns
 */
export function getDesignationComposant(
  composant: composantDocType
): string | null {
  const name = composant.composant_productfields.reduce((acc, curr) => {
    if (
      curr.productfield.type === PRODUCTFIELD_TYPE.DESIGNATION.toString() &&
      curr.value
    ) {
      return curr.value;
    }
    return acc;
  }, '');

  if (name) {
    return name;
  }
  return null;
}
