import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UserStateService } from '../state/user-state.service';
import { DisabledService } from './disabled-service';

@Directive({
  selector: '[appDisableIfHasNoGroup]',
  standalone: true,
})
export class DisableIfHasNoGroupDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private userState: UserStateService,
    private disabledService: DisabledService
  ) {}

  ngOnInit() {
    this.updateElementState();
  }

  private updateElementState() {
    const tagName = this.el.nativeElement.tagName.toLowerCase();
    const hasNoGroup = this.userState.checkIfUserHasNoGroup();
    if (hasNoGroup) {
      switch (tagName) {
        case 'app-ugau-history-list':
          this.doHistoryListAction();
          break;
        case 'mat-tab':
          this.doMatTabAction();
          break;
        default:
          this.doDefaultAction();
          break;
      }
    }
  }

  doMatTabAction() {
    const matTabGroup = this.el.nativeElement.closest('mat-tab-group');
    if (matTabGroup) {
      const tabs = matTabGroup.querySelectorAll('.mat-tab');
      tabs.forEach((tab: any) => {
        if (tab === this.el.nativeElement) {
          this.renderer.setStyle(tab, 'pointer-events', 'none');
          this.renderer.setStyle(tab, 'opacity', '0.6');
          this.renderer.addClass(tab, 'mat-mdc-tab-disabled');
        }
      });
    }
  }

  doHistoryListAction() {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.6');
    this.renderer.setStyle(this.el.nativeElement, 'height', '100px');
    this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    this.disabledService.addLockIconCenter48px(this.renderer, this.el);

    this.disabledService.interceptClick(this.el);
  }

  doDefaultAction() {
    const classes = this.el.nativeElement.classList;
    if (classes.contains('sidebar-item-fixed')) {
      this.disabledService.handleSidebarItemFixed(this.renderer, this.el);
      return;
    }
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.6');
    if (classes.contains('mat-mdc-icon-button')) {
      const icon = this.el.nativeElement.querySelector('span:first-child');
      this.renderer.setStyle(icon, 'position', 'absolute');
      this.renderer.setStyle(icon, 'width', '100%');
      this.renderer.setStyle(icon, 'height', '2px');
      this.renderer.setStyle(icon, 'background-color', 'red');
      this.renderer.setStyle(icon, 'top', '50%');
      this.renderer.setStyle(icon, 'left', '0');
      this.renderer.setStyle(
        icon,
        'transform',
        'translateY(-50%) rotate(-45deg)'
      );
      return;
    }
    this.disabledService.addLockIcon24px(this.renderer, this.el);
    this.disabledService.interceptClick(this.el);
  }
}
