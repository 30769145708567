import { DatePipe } from '@angular/common';
import { Injector, Pipe, PipeTransform } from '@angular/core';
import { groupeDocType } from './../../db/schemas/groupe.schema';
import { interventionDocType } from './../../db/schemas/intervention.schema';
import { tagDocType } from './../../db/schemas/tag.schema';
import { GroupesStateService } from './../../state/groupes-state.service';
import { TagsStateService } from './../../state/tags-state.service';
import { UserPipe } from '../users/user.pipe';
import { UsersStateService } from './../../state/users-state.service';
import { getInterventionStatusString } from './getInterventionStatusString';

@Pipe({
  name: 'intervention',
  standalone: true,
})
export class InterventionPipe implements PipeTransform {
  private datePipe: DatePipe;
  constructor(
    private tagsState: TagsStateService,
    private groupesStates: GroupesStateService,
    private userState: UsersStateService,
    private injector: Injector
  ) {
    this.datePipe = this.injector.get(DatePipe);
  }

  transform(
    intervention: interventionDocType | null,
    type:
      | 'name'
      | 'firstline'
      | 'secondline'
      | 'badge'
      | 'badge-color'
      | 'progress'
      | 'progress-tooltip'
      | 'intervention_emplacement_status'
      | undefined,
    ...args: any[]
  ): string {
    if (!intervention) return '';

    if (type === 'progress') {
      const nbDone = intervention.emplacements.filter(
        (el) => el.date_end_intervention
      ).length;
      return ((nbDone / intervention.emplacements.length) * 100).toFixed(2);
    }

    if (type === 'progress-tooltip') {
      const nbDone = intervention.emplacements.filter(
        (el) => el.date_end_intervention
      ).length;
      return `${nbDone} / ${intervention.emplacements.length}`;
    }

    if (type === 'firstline')
      return getFirstLineIntervention(
        intervention,
        this.tagsState.getTags(),
        this.groupesStates.getGroupes()
      );
    if (type === 'secondline')
      return getSecondLineIntervention(
        intervention,
        this.datePipe,
        this.userState
      );
    if (type === 'name') return getNameIntervention(intervention);
    if (type === 'badge') return getInterventionStatusString(intervention);
    if (type === 'badge-color') return getBadgeColorIntervention(intervention);
    if (type === 'intervention_emplacement_status')
      return getEmplacementStatus(intervention, args[0]);
    return '';
  }
}

const getEmplacementStatus = (
  intervention: interventionDocType,
  emplacementId: string
): string => {
  const emplacement = intervention.emplacements.find(
    (el) => el.id_emplacement === emplacementId
  );

  if (!emplacement) return '';

  if (emplacement.date_end_intervention) {
    return $localize`Terminé`;
  }

  if (emplacement.date_start_intervention) {
    return $localize`En cours`;
  }

  return $localize`A faire`;
};

const getFirstLineIntervention = (
  intervention: interventionDocType,
  tags: tagDocType[],
  groupes: groupeDocType[]
): string => {
  const emplacementCount: number = intervention.emplacements.length;

  const users = getUsersForIntervention(intervention, tags, groupes);
  const userCount = users.user.length + users.user_created.length;
  const documentCount = intervention.documents.length;

  const retourEmpacementString = `${emplacementCount} emplacements`;
  const retourUserString = userCount ? ` - ${userCount} intervenants` : '';
  const retourDocumentString = documentCount
    ? ` - ${documentCount} documents`
    : '';

  return `${retourEmpacementString}${retourUserString}${retourDocumentString}`;
};

const getSecondLineIntervention = (
  intervention: interventionDocType,
  datePipe: DatePipe,
  userState: UsersStateService
) => {
  const date = datePipe.transform(intervention.created_at, 'dd/MM/yyyy');
  const userLibelle = new UserPipe(userState).transform(
    intervention.created_by,
    'displayName'
  );
  return `${date} - ${userLibelle}`;
};

export const getUsersForIntervention = (
  intervention: interventionDocType,
  tags: tagDocType[],
  groupes: groupeDocType[]
) => {
  const tag = tags.find((el) => el.id === intervention.id_tag);
  let users: string[] = [];
  let users_created: string[] = [];
  if (tag) {
    // On récupère le propriétaire du dossier
    if (tag.id_user) {
      users.push(tag.id_user);
    }

    // On récupère les utilisateurs du groupe propriétaire
    if (tag.id_groupe) {
      const groupe = groupes.find((el) => el.id === tag.id_groupe);
      if (groupe) {
        groupe.user_groupes.forEach((el) => {
          if (el.role?.startsWith('user_created') && el.id_user) {
            users_created.push(el.id_user);
          }
          if (el.role === 'user' && el.id_user) {
            users.push(el.id_user);
          }
        });
      }
    }

    // On récupère les utilisateurs des groupes partagés
    if (tag.shared_tags) {
      tag.shared_tags.forEach((el) => {
        if (el.target_user_id) {
          users.push(el.target_user_id);
        }

        if (el.target_group_id) {
          const groupe = groupes.find((el) => el.id === tag.id_groupe);
          if (groupe) {
            groupe.user_groupes.forEach((el) => {
              if (el.role?.startsWith('user_created') && el.id_user) {
                users_created.push(el.id_user);
              }
              if (el.role === 'user' && el.id_user) {
                users.push(el.id_user);
              }
            });
          }
        }
      });
    }
  }

  users = users.filter((el, index) => users.indexOf(el) === index);
  users_created = users_created.filter(
    (el, index) => users_created.indexOf(el) === index
  );

  return {
    user: users,
    user_created: users_created,
    total: users.length + users_created.length,
  };
};

const getNameIntervention = (
  intervention: interventionDocType | null
): string => {
  if (!intervention) return '';

  return `${intervention.name} (${intervention.numero})`;
};

const getBadgeColorIntervention = (
  intervention: interventionDocType | null
): string => {
  if (!intervention) return '';

  let color = '';
  if (intervention.status === 'PREPARATION') {
    color = 'accent';
  }
  if (intervention.status === 'VALIDE') {
    color = 'primary';
  }
  if (intervention.status === 'EN_COURS') {
    color = 'warn';
  }
  return color;
};
