import { inject, Injectable } from '@angular/core';
import { DatabaseService } from '../db/database.service';
import { Observable, map } from 'rxjs';
import { usersDocType } from '../db/schemas/users.schema';
import { RxDocument } from 'rxdb';
import { UserManagementService } from './user-management.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userProvider = inject(DatabaseService).getUserProvider();

  constructor(private userManagement: UserManagementService) {}

  public changeEmail(idUser: string, email: string) {
    return this.userManagement.changeEmail(idUser, email);
  }

  public getUserById(id: string) {
    return this.userProvider.collection.findOne().where('id').eq(id).exec();
  }

  public getUserByIdObj(id: string) {
    return this.userProvider.collection
      .findOne()
      .where('id')
      .eq(id)
      .exec()
      .then((user: RxDocument<usersDocType> | null) => {
        return user?.toJSON(true);
      });
  }

  public getUserByIdObs(
    id: string | undefined
  ): Observable<usersDocType | undefined> {
    return this.userProvider.collection
      .findOne()
      .where('id')
      .eq(id)
      .$.pipe(
        map((user: any) => {
          if (!user || !id) return undefined;
          return user.toJSON(true);
        })
      );
  }

  public getUsersByNomObs(name: string) {
    const strReg = '.*' + name + '.*';
    return this.userProvider.collection.find().where('displayName').regex({
      $regex: strReg,
      $options: 'i',
    }).$;
  }

  public upsert(user: any) {
    return this.userManagement.upsert(user);
  }

  public changeUserType(idUser: string, idGroupe: string, type: string) {
    return this.userManagement.changeUserType(idUser, idGroupe, type);
  }

  public deleteUserGroupe(idUser: string, idGroupe: string) {
    return this.userManagement.deleteUserGroupe(idUser, idGroupe);
  }

  resyncUserAndGroupe() {
    this.userManagement.resyncUserAndGroupe();
  }
}
