import { Injectable } from '@angular/core';
import { BooleanStateService } from './abstract-boolean-state';
import { UserMetaState } from './usermeta.state';

@Injectable({
  providedIn: 'root',
})
export class EmplacementPhotoWidthStateService extends BooleanStateService {
  constructor(private userMetaState: UserMetaState) {
    super();

    const value = this.userMetaState.getEmplacementPhotoWidth();
    this.setValue(value);
  }

  isFullwidth$ = this.state$;

  toggle(): void {
    const value = !this.getValue();
    this.setValue(value);
    this.userMetaState.setEmplacementPhotoWidth(value);
  }

  setFullwidthWithoutRegisterToMeta(value: boolean): void {
    this.setValue(value);
  }
}
