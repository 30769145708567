import {
  URI_lines_administratives,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
} from './layersChoices';
import { mapRemoveSandLIgnRaster, loadIgnRasterSourceAndLayer } from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

/**
 * Adds or removes administrative lines on the given Mapbox map.
 *
 * @param {Map} map - The Mapbox map instance to which the administrative lines will be added or from which they will be removed.
 * @param {boolean} [remove=false] - If true, the administrative lines will be removed from the map. If false, the administrative lines will be added to the map.
 * @returns {void}
 */

export function addMapAdministrativeLines(map: Map, remove = false) {
  if (remove) return mapRemoveSandLIgnRaster(map, URI_lines_administratives);

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=LIMITES_ADMINISTRATIVES_EXPRESS.LATEST' +
    IGN_TILE;

  loadIgnRasterSourceAndLayer(map, URI_lines_administratives, url);
}
