export function openDatabase(
  dbName: string,
  tableName: string
): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () =>
      handleUpgradeNeeded(request.result, tableName);

    request.onsuccess = () => {
      const db = request.result;
      if (db.objectStoreNames.contains(tableName)) {
        resolve(db);
      } else {
        const version = db.version + 1;
        db.close();
        const upgradeRequest = indexedDB.open(dbName, version);
        upgradeRequest.onupgradeneeded = () =>
          handleUpgradeNeeded(upgradeRequest.result, tableName);
        upgradeRequest.onsuccess = () => resolve(upgradeRequest.result);
        upgradeRequest.onerror = () => {
          console.error(
            `Error upgrading database ${dbName} to version ${version}`,
            upgradeRequest.error
          );
          reject(upgradeRequest.error);
        };
      }
    };
    request.onerror = () => {
      console.error(`Error opening database ${dbName}`, request.error);
      reject(request.error);
    };
  });
}
function handleUpgradeNeeded(db: IDBDatabase, tableName: string) {
  if (!db.objectStoreNames.contains(tableName)) {
    db.createObjectStore(tableName);
  }
}
export function getTransaction(
  db: IDBDatabase,
  tableName: string,
  mode: IDBTransactionMode
): IDBTransaction {
  return db.transaction(tableName, mode);
}
export function handleTransactionError(
  transaction: IDBTransaction,
  reject: (reason?: any) => void
) {
  console.error('Transaction error', transaction.error);
  reject(transaction.error);
}
