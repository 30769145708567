import { Injectable } from '@angular/core';
import { CartStore, CartSubformeStoreType } from '@types_custom/cart-store';
import { ProductFamiliesType, ProductType } from '@types_custom/ProductType';
import { composantDocType } from '../db/schemas/emplacement.schema';
import { getProductPathFromCache } from '../utils/product/getProductPathFromCache';
import {
  getProductFromFamilies,
  getDirectParentFamilyForProductId,
  getImageFromFamiliesRecursive,
  recursiveFindFamilyFromFamiliesId,
} from '../utils/productfamilies-utils.service';
import { isNotConformeComposantV2 } from '../services/data-accessor.service';
import { ProductpropertiesStateService } from '../state/productproperties-state.service';
import { ProductfieldsStateService } from '../state/productfields-state.service';
import { ReglementationsStateService } from '../state/reglementations-state.service';
import { getComposantBadConformiteString } from '../utils/composant/getComposantBadConformiteString';
import {
  generateCartStoreItem,
  increaseCountAndTotal,
  initStatStoreItem,
} from '../utils/cart-utils';
import { get } from 'lodash-es';
import { ComposantType } from '../../../@types_custom/composant';
import { multiply } from '../utils/multiply';

@Injectable({
  providedIn: 'root',
})
export class CartCalculationService {
  constructor(
    private productpropertiesState: ProductpropertiesStateService,
    private productfieldsState: ProductfieldsStateService,
    private reglementationsState: ReglementationsStateService
  ) {}

  calculate(
    store: CartStore,
    emplacements: any,
    families: ProductFamiliesType[]
  ) {
    const properties = this.productpropertiesState.getProductproperties();
    const productfields = this.productfieldsState.getProductfields();
    const reglementations = this.reglementationsState.getReglementations();

    if (emplacements && emplacements.length > 0) {
      for (const emplacement of emplacements) {
        emplacement.composants.forEach((composant: any) => {
          if (composant.product) {
            const isNotConforme = isNotConformeComposantV2(
              composant,
              null,
              families,
              properties,
              productfields,
              reglementations
            );

            this.doTheComposantCount(
              store,
              emplacement.id,
              composant,
              families,
              isNotConforme
            );
            return;
          }

          if (composant.type) {
            const altFamily = recursiveFindFamilyFromFamiliesId(
              composant.type,
              families
            );
            if (!altFamily) return;
            this.doMapillaryImportedEmplacement(
              store,
              altFamily,
              emplacement.id,
              composant,
              families
            );
          }
        });
      }

      Object.keys(store).forEach((key) => {
        store[key].conforme.list.sort((a: any, b: any) => {
          return a.product.localeCompare(b.product);
        });
        store[key].nonConforme.list.sort((a: any, b: any) => {
          return a.product.localeCompare(b.product);
        });
      });
    }
  }

  private doMapillaryImportedEmplacement(
    store: CartStore,
    family: ProductFamiliesType,
    emplacementsId: string,
    composant: ComposantType,
    families: ProductFamiliesType[]
  ) {
    const familyName = '‏À renseigner‏';
    if (!store.hasOwnProperty(familyName)) {
      store[familyName] = initStatStoreItem(familyName);
    }

    const alreadyListIndex = store[familyName].nonConforme.list.findIndex(
      (item) => item.product === family.name
    );
    const familyPrice = family.price?.price || 0;
    if (alreadyListIndex === -1) {
      store[familyName].nonConforme.list.push({
        shortcut: family.name,
        image: getImageFromFamiliesRecursive(family.id, families),
        product: family.name,
        unit_type: family.unit,
        unit_price: familyPrice,
        count: composant.quantity,
        nb_emplacement: 1,
        id_emplacement: [emplacementsId],
        ids_composant: [composant.id],
        total_price: multiply(composant.quantity, familyPrice),
        comment: '',
      });
    } else {
      store[familyName].nonConforme.list[alreadyListIndex].nb_emplacement++;
      store[familyName].nonConforme.list[alreadyListIndex].count +=
        composant.quantity;
      store[familyName].nonConforme.list[alreadyListIndex].total_price +=
        multiply(composant.quantity, familyPrice);
      store[familyName].nonConforme.list[alreadyListIndex].id_emplacement.push(
        emplacementsId
      );
      store[familyName].nonConforme.list[alreadyListIndex].ids_composant.push(
        composant.id
      );
    }
    store[familyName].nonConforme.count++;
    store[familyName].nonConforme.total += familyPrice;
  }

  private doTheComposantCount(
    store: CartStore,
    emplacementId: string,
    composant: composantDocType,
    families: ProductFamiliesType[],
    isNotConforme: boolean
  ) {
    if (!composant.product) return;
    if (composant.deleted_at) return;

    const productPath = getProductPathFromCache(composant.product.id, families);
    const product = getProductFromFamilies(composant.product.id, families);
    const unit =
      getDirectParentFamilyForProductId(composant.product.id, families)?.unit ??
      '';

    if (!product) return;
    const priceProduct = product.price.price;

    this.doRecursiveFromFamily(
      productPath,
      0,
      store,
      priceProduct,
      isNotConforme,
      product,
      unit,
      emplacementId,
      composant,
      families
    );
  }

  private doRecursiveFromFamily(
    productPath: any,
    i: number,
    storePart: any,
    priceProduct: number,
    isNotConforme: boolean,
    product: ProductType,
    unit: string,
    emplacementId: string,
    composant: composantDocType,
    families: ProductFamiliesType[]
  ) {
    const customPrice = composant.composant_productfields.reduce(
      (acc: number | null, curr: any) => {
        const price = parseFloat(curr.value);
        if (
          curr.productfield.type === 'custom_price' &&
          !isNaN(price) &&
          price > 0
        ) {
          return price;
        }
        return acc;
      },
      null
    );

    const firstFamilyFromTree = families[parseInt(productPath[0])];
    const fam =
      i === 0
        ? firstFamilyFromTree
        : get(firstFamilyFromTree, productPath.slice(1, i * 2 + 1).join('.'));
    if (!fam) return;

    ['Tous', fam].forEach((family) => {
      const familyName = typeof family === 'string' ? 'Tous' : family.name;

      if (!storePart) {
        storePart = {};
      }
      if (!storePart[familyName]) {
        storePart[familyName] = initStatStoreItem(familyName);
      }

      increaseCountAndTotal(
        storePart[familyName],
        multiply(priceProduct, composant.quantity)
      );

      if (isNotConforme) {
        this.doRecursiveFromProduct(
          storePart[familyName].nonConforme,
          product,
          emplacementId,
          composant,
          unit,
          families,
          customPrice || priceProduct
        );
      } else {
        this.doRecursiveFromProduct(
          storePart[familyName].conforme,
          product,
          emplacementId,
          composant,
          unit,
          families,
          customPrice || priceProduct
        );
      }

      if (
        typeof family !== 'string' &&
        family.children_productfamilies?.length > 0 &&
        i < productPath.length
      ) {
        this.doRecursiveFromFamily(
          productPath,
          i + 1,
          storePart[familyName].childrens,
          customPrice || priceProduct,
          isNotConforme,
          product,
          unit,
          emplacementId,
          composant,
          families
        );
      }
    });
  }

  private doRecursiveFromProduct(
    subStore: CartSubformeStoreType,
    product: ProductType,
    emplacementId: string,
    composant: composantDocType,
    unit: string,
    families: ProductFamiliesType[],
    productPrice: number
  ) {
    const comment = getComposantBadConformiteString(
      composant,
      this.productpropertiesState.getProductproperties(),
      this.productfieldsState.getProductfields(),
      this.reglementationsState.getReglementations()
    );

    const index = subStore.list.findIndex(
      (item) => item.product === product.name && item.comment === comment
    );

    if (index === -1) {
      subStore.list.push(
        generateCartStoreItem(
          emplacementId,
          composant,
          comment,
          product,
          families,
          unit,
          composant.quantity,
          productPrice
        )
      );
    } else {
      subStore.list[index].nb_emplacement++;
      subStore.list[index].total_price += multiply(
        composant.quantity,
        productPrice
      );
      subStore.list[index].count += composant.quantity;
      subStore.list[index].id_emplacement.push(emplacementId);
    }
    increaseCountAndTotal(subStore, multiply(productPrice, composant.quantity));
  }
}
