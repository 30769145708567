import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const tagSchemaLiteral = jsonSchema({
  name: 'tag',
  nameCleanForUser: 'Dossiers',
  autoMigrate: true,
  version: 4,
  type: 'object',
  primaryKey: 'id',
  required: ['id', 'libelle', 'updated_at', 'type'],
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    id_user: {
      type: ['string', 'null'],
    },
    id_groupe: {
      type: ['string', 'null'],
    },
    libelle: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
    id_parent: {
      type: ['string', 'null'],
    },
    created_at: {
      type: 'string',
    },
    created_by: {
      type: ['string', 'null'],
    },
    updated_at: {
      type: ['string'],
    },
    updated_by: {
      type: ['string', 'null'],
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_by: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
    is_archived: {
      type: 'boolean',
    },
    user_libelle: {
      type: ['string', 'null'],
    },
    groupe_libelle: {
      type: ['string', 'null'],
    },
    tag_meta: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'key', 'created_at', 'updated_at', 'type'],
        properties: {
          id: {
            type: 'string',
          },
          key: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
          created_at: {
            type: 'string',
          },
          updated_at: {
            type: 'string',
          },
        },
      },
    },
    shared_tags: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          active: {
            type: 'boolean',
          },
          readonly: {
            type: 'boolean',
          },
          expires_at: {
            type: ['string', 'null'],
          },
          shared_at: {
            type: ['string', 'null'],
          },
          source_user_id: {
            type: ['string', 'null'],
          },
          target_user_id: {
            type: ['string', 'null'],
          },
          source_group_id: {
            type: ['string', 'null'],
          },
          target_group_id: {
            type: ['string', 'null'],
          },
        },
      },
    },
  },
} as const);

const tagSchemaTyped = toTypedRxJsonSchema(tagSchemaLiteral);

// Type basé sur la vue des tags accessibles par l'utilisateur
export type tagDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof tagSchemaTyped
>;

// extract type sharedTagDocType = tagDocType['shared_tags'][number];
// Attentinon, shared_tag peut être null
export type sharedTagDocType = Exclude<
  tagDocType['shared_tags'],
  undefined
>[number];

export type tagMetaDocType = Exclude<tagDocType['tag_meta'], undefined>[number];

// create the typed RxJsonSchema from the literal typed object.
export const tagSchema: RxJsonSchema<tagDocType> = tagSchemaLiteral;
