import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Host,
  Optional,
} from '@angular/core';
import { GroupesStateService } from '../state/groupes-state.service';
import { ToolsService } from '../services/tools.service';
import { DisabledService } from './disabled-service';
import { MatTab } from '@angular/material/tabs';

@Directive({
  selector: '[appDisableIfNotPremium]',
  standalone: true,
})
export class DisableIfNotPremiumDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private groupeState: GroupesStateService,
    private tools: ToolsService,
    private disabledService: DisabledService,
    @Optional() @Host() private matTab: MatTab
  ) {}

  ngOnInit() {
    this.updateElementState();
  }

  private updateElementState() {
    const tagName = this.el.nativeElement.tagName.toLowerCase();
    const isPremium = this.groupeState.hasGroupePremium();
    if (!isPremium) {
      switch (tagName) {
        case 'app-ugau-history-list':
          this.doHistoryListAction();
          break;
        case 'mat-tab':
          this.doMatTabAction();
          break;
        default:
          this.doDefaultAction();
          break;
      }
    }
  }

  doMatTabAction() {
    if (this.matTab) {
      this.matTab.disabled = true;
    }
  }

  doHistoryListAction() {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.6');
    this.renderer.setStyle(this.el.nativeElement, 'height', '100px');
    this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    this.addLockIconCenter();

    this.disabledService.interceptClick(this.el);
  }

  doDefaultAction() {
    const classes = this.el.nativeElement.classList;
    if (classes.contains('sidebar-item-fixed')) {
      this.disabledService.handleSidebarItemFixed(this.renderer, this.el);
      return;
    }
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.6');
    this.disabledService.addLockIcon24px(this.renderer, this.el);
    this.disabledService.interceptClick(this.el, () => {
      this.tools.launchInfoAlert(
        `Vous devez être abonné à l'offre premium pour accéder à cette fonctionnalité.<br> <a href="https://www.ugau.fr/solutions/offres-et-tarifs" target="_blank">En savoir plus</a>`,
        'Offre premium',
        true
      );
    });
  }

  private addLockIconCenter() {
    const lockIcon = this.disabledService.generateLockIcon(this.renderer);
    this.renderer.setStyle(lockIcon, 'font-size', '48px');
    this.renderer.setStyle(lockIcon, 'width', '48px');
    this.renderer.setStyle(lockIcon, 'height', '48px');
    this.renderer.setStyle(lockIcon, 'position', 'absolute');
    this.renderer.setStyle(lockIcon, 'color', 'var(--error-regular)');
    this.renderer.setStyle(lockIcon, 'top', '50%');
    this.renderer.setStyle(lockIcon, 'left', '50%');
    this.renderer.setStyle(lockIcon, 'transform', 'translate(-50%, -50%)');

    const parent = this.el.nativeElement;
    this.renderer.setStyle(parent, 'position', 'relative');

    this.renderer.appendChild(parent, lockIcon);
  }
}
