import { RxReplicationWriteToMasterRow, WithDeleted } from 'rxdb';
import { Injectable } from '@angular/core';
import { graphql } from './../../../services/nhost';
import { gql } from 'graphql-request';
import { EmplacementProvider } from './emplacement-provider';
import { Observable, map, shareReplay } from 'rxjs';
import { getEmplacementReplicationPush } from './../../../utils/emplacement/getEmplacementReplicationPush';
import { emplacementDocType } from '../../schemas/emplacement.schema';
import { SentryService } from './../../../services/sentry.service';
import { MUTATION_UPSERT_EMPLACEMENT_PHOTO } from '../../queries/emplacement.queries';
import { EmplacementPhotoType } from '../../../../../@types_custom/emplacement-photo';

@Injectable({
  providedIn: 'root',
})
export class EmplacementMobileProvider extends EmplacementProvider {
  public getAllMod$: Observable<emplacementDocType[]> = this.getAll$.pipe(
    map((e) => e.filter((el) => !el.deleted_at)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  hasDoFirstPush: boolean = false;

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<emplacementDocType>[]
  ): Promise<WithDeleted<emplacementDocType>[]> {
    // Sur mobile, avant d'envoyer la requête, on vérifie que updated_at est bien plus récent que celui sur le serveur
    this.hasDoFirstPush = true;

    const queryCheckNewer = gql`
      query MyQuery($where: emplacement_bool_exp!) {
        emplacement(where: $where) {
          id
          updated_at
        }
      }
    `;

    const docsFiltered = async (
      docs: RxReplicationWriteToMasterRow<emplacementDocType>[]
    ): Promise<RxReplicationWriteToMasterRow<emplacementDocType>[]> => {
      // Effectuer une requête GraphQL pour tous les emplacements
      const ids = docs.map((doc) => doc.newDocumentState.id);
      const { error, data } = await graphql.request(queryCheckNewer, {
        where: {
          id: {
            _in: ids,
          },
        },
      });

      if (error) {
        console.log(
          'Erreur lors de la requête de vérification des updated_at emplacement sur le serveur avant envoi de la requête de sauvegarde',
          error
        );
        return [];
      }

      // Créer un objet pour stocker les dates de mise à jour des documents de GraphQL
      const updatesFromGraphQL = data.emplacement.reduce(
        (
          acc: Record<string, number>,
          current: { id: string; updated_at: string }
        ) => {
          acc[current.id] = new Date(current.updated_at).getTime();
          return acc;
        },
        {}
      );

      // Filtrer les documents originaux en une seule passe en utilisant les données de updatesFromGraphQL
      return docs.reduce(
        (
          newerDocs: RxReplicationWriteToMasterRow<emplacementDocType>[],
          currentDoc
        ) => {
          const gqlUpdate = updatesFromGraphQL[currentDoc.newDocumentState.id];
          if (
            !gqlUpdate ||
            new Date(currentDoc.newDocumentState.updated_at).getTime() >
              gqlUpdate
          ) {
            newerDocs.push(currentDoc);
          }
          return newerDocs;
        },
        []
      );
    };

    const docsFilteredNotNull = await docsFiltered(docs);
    if (docsFilteredNotNull.length === 0) return [];

    try {
      const { query, variables } = getEmplacementReplicationPush(
        docsFilteredNotNull,
        this.idUser
      );

      const { objects_emplacement_photo: dataEmplacement } = variables;

      // Première requête pour sauvegarder les emplacements sans les photos
      const { error, data } = await graphql.request(query, {
        ...variables,
        objects_emplacement_photo: [],
      });

      // Gestion de l'erreur sur le retour de la requête
      if (error) {
        console.log(error);
        SentryService.captureException(error);
        this.AT.toastError(
          $localize`Erreur lors de la sauvegarde de l'emplacement sur le serveur`
        );
      } else {
        console.log(data);
        this.AT.toastError($localize`Emplacement sauvegardé sur le serveur`);
      }

      if (dataEmplacement.length === 0) return [];
      await this.uploadPhotos(dataEmplacement);
      return [];
    } catch (error) {
      // Gestion de l'erreur sur la requête
      console.log(error);
      SentryService.captureException(error);
      this.AT.toastError(
        $localize`Erreur lors de la sauvegarde de l'emplacement sur le serveur`
      );
      return [];
    }
  }

  uploadPhotos = async (dataEmplacementPhotos: EmplacementPhotoType[]) => {
    return await dataEmplacementPhotos.reduce(
      async (previousPromise, photo) => {
        await previousPromise;

        const variablesPhoto = {
          objects_emplacement_photo: [photo],
        };

        try {
          const resultPhoto = await graphql.request(
            MUTATION_UPSERT_EMPLACEMENT_PHOTO,
            variablesPhoto
          );

          if (!photo.data) {
            // Si la photo ne contient pas de data, alors il ne peut pas s'agir d'un upload de photo, donc pas besoin de continuer
            return;
          }

          if (resultPhoto.error) {
            console.log(resultPhoto.error);
            SentryService.captureException(resultPhoto.error);
            this.AT.toastError(
              $localize`Erreur lors de la sauvegarde de la photo d'emplacement sur le serveur`
            );
          } else {
            console.log(resultPhoto.data);
            this.AT.toastError(
              $localize`Photo d'emplacement sauvegardée sur le serveur`
            );
          }
        } catch (error) {
          console.log(error);
          SentryService.captureException(error);
          this.AT.toastError(
            $localize`Erreur lors de la sauvegarde de la photo d'emplacement sur le serveur`
          );
        }
      },
      Promise.resolve()
    );
  };
}
