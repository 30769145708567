import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';
import { Geometry } from 'geojson';

export const emplacementSchemaLiteral = jsonSchema({
  name: 'emplacement_tree',
  nameCleanForUser: 'Emplacements',
  autoMigrate: true,
  version: 8,
  type: 'object',
  required: [
    'id',
    'status',
    'composants',
    'emplacement_photos',
    'emplacement_productfields',
    'emplacement_tags',
    'updated_at',
    'deleted_at',
  ],
  primaryKey: 'id',
  indexes: ['status', 'updated_at'],
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    status: {
      type: 'string',
      maxLength: 255,
    },
    commentaire: {
      type: ['string', 'null'],
    },
    numero_ensemble: {
      type: ['string', 'null'],
    },
    address: {
      type: ['object', 'null'],
    },
    pr: {
      type: ['object', 'null'],
    },
    created_at: {
      type: 'string',
    },
    created_by: {
      type: ['string', 'null'],
    },
    geometry: {
      type: 'object',
    },
    updated_at: {
      type: 'string',
      maxLength: 255,
    },
    updated_by: {
      type: ['string', 'null'],
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_by: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
    interventions: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'id_intervention'],
        properties: {
          id: {
            type: 'string',
          },
          id_intervention: {
            type: 'string',
          },
        },
      },
    },
    emplacement_photos: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id'],
        properties: {
          id: {
            type: 'string',
          },
          created_at: {
            type: ['string', 'null'],
          },
          created_by: {
            type: ['string', 'null'],
          },
          deleted_at: {
            type: ['string', 'null'],
          },
          deleted_by: {
            type: ['string', 'null'],
          },
          data: {
            type: ['string', 'null'],
          },
          chemin: {
            type: 'string',
          },
          type_photo: {
            type: 'string',
          },
        },
      },
    },
    emplacement_tags: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'id_tag'],
        properties: {
          id: {
            type: 'string',
          },
          created_at: {
            type: ['string', 'null'],
          },
          created_by: {
            type: ['string', 'null'],
          },
          deleted_at: {
            type: ['string', 'null'],
          },
          deleted_by: {
            type: ['string', 'null'],
          },
          id_tag: {
            type: 'string',
          },
          is_from_shared_tag: {
            type: 'boolean',
          },
        },
      },
    },
    composants: {
      type: 'array',
      items: {
        type: 'object',
        required: [
          'id',
          'conformites',
          'composant_productfields',
          'created_at',
          'created_by',
          'updated_at',
          'updated_by',
          'quantity',
        ],
        properties: {
          id: {
            type: 'string',
          },
          order_per_type: {
            type: 'number',
          },
          quantity: {
            type: 'number',
          },
          created_at: {
            type: ['string'],
          },
          created_by: {
            type: ['string', 'null'],
          },
          product: {
            type: ['object', 'null'],
            required: ['id'],
            properties: {
              id: {
                type: 'string',
              },
              defaultQuantity: {
                type: 'number',
              },
              canChangeQuantity: {
                type: 'boolean',
              },
              shortcut: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
              productfamilies: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    productfamily: {
                      type: 'object',
                      properties: {
                        id: {
                          type: 'string',
                        },
                        name: {
                          type: 'string',
                        },
                        images: {
                          type: ['object', 'null'],
                        },
                      },
                    },
                  },
                },
              },
              productpropertyvalues: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    productpropertyvalue: {
                      type: 'object',
                      properties: {
                        id: {
                          type: 'string',
                        },
                        productproperty_id: {
                          type: 'string',
                        },
                        value: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
              },
              price: {
                type: 'object',
                properties: {
                  price: {
                    type: 'number',
                  },
                },
              },
            },
          },
          id_emplacement: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          updated_at: {
            type: ['string', 'null'],
          },
          updated_by: {
            type: ['string', 'null'],
          },
          deleted_at: {
            type: ['string', 'null'],
          },
          deleted_by: {
            type: ['string', 'null'],
          },
          conformites: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'object',
              required: [
                'id',
                'id_reglementation',
                'valeur',
                'est_conforme',
                'created_at',
              ],
              properties: {
                id: {
                  type: 'string',
                },
                id_reglementation: {
                  type: ['number'],
                },
                valeur: {
                  type: 'string',
                },
                est_conforme: {
                  type: 'boolean',
                },
                id_reglementation_choix: {
                  type: ['number', 'null'],
                },
                commentaire: {
                  type: ['string', 'null'],
                },
                created_at: {
                  type: 'string',
                },
                created_by: {
                  type: ['string', 'null'],
                },
                deleted_at: {
                  type: ['string', 'null'],
                },
                deleted_by: {
                  type: ['string', 'null'],
                },
              },
            },
          },
          composant_productfields: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'object',
              required: [
                'id',
                'value',
                'productfield',
                'created_at',
                'updated_at',
              ],
              properties: {
                id: {
                  type: 'string',
                },
                value: {
                  type: ['string', 'null'],
                },
                created_at: {
                  type: 'string',
                },
                updated_at: {
                  type: 'string',
                },
                deleted_at: {
                  type: ['string', 'null'],
                },
                productfield: {
                  type: 'object',
                  required: ['id', 'type'],
                  properties: {
                    id: {
                      type: 'string',
                    },
                    type: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    emplacement_productfields: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        required: ['id', 'value', 'productfield', 'created_at', 'updated_at'],
        properties: {
          id: {
            type: 'string',
          },
          value: {
            type: ['string', 'null'],
          },
          created_at: {
            type: 'string',
          },
          updated_at: {
            type: 'string',
          },
          deleted_at: {
            type: ['string', 'null'],
          },
          productfield: {
            type: 'object',
            required: ['id', 'type'],
            properties: {
              id: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
} as const);

const emplacementSchemaTyped = toTypedRxJsonSchema(emplacementSchemaLiteral);

export type emplacementDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof emplacementSchemaTyped
> & { geometry: Geometry; address: any; pr: any };

export type emplacementProductfieldDocType =
  emplacementDocType['emplacement_productfields'][number] & {
    created_at?: string;
  };

export type emplacementPhotoDocType =
  emplacementDocType['emplacement_photos'][number] & {
    id_emplacement?: string;
  };
export type emplacementTagDocType =
  emplacementDocType['emplacement_tags'][number] & { id_emplacement?: string };
export type composantDocType = emplacementDocType['composants'][number] & {
  id_emplacement?: string;
};
export type productDocType = composantDocType['product'] & {
  productproperties?: any[];
  productfamilies?: any[];
};

export type composantProductfieldDocType =
  composantDocType['composant_productfields'][number] & { created_at?: string };

export type anyProductfieldDocType =
  | composantProductfieldDocType
  | emplacementProductfieldDocType;

export type conformiteDocType = composantDocType['conformites'][number];

export const emplacementSchema: RxJsonSchema<emplacementDocType> =
  emplacementSchemaLiteral;

// type withRevAndAttachments<T> = T & {
//   _rev: string;
//   _attachments: any;
//   _meta: {
//     lwt: number;
//   };
//   _deleted: boolean;
// };
