import { tagDocType } from '../../../db/schemas/tag.schema';
import {
  CalendarType,
  CalendarEvent,
  sendEventToCalendar,
} from '../tags-list/send-event-to-calendar';

/**
 * Schedule a tag event in the calendar
 * - If the tag has a date_debut and date_fin meta, use them as start and end date
 * - If the tag has no date_debut and date_fin meta, use the current date as start date and the current date + 1 hour as end date
 * - If the tag has no date_fin meta, use the current date + 1 hour as end date
 * - If the tag has no date_debut meta, use the current date as start date
 * - If shouldSplit is true, split the event in multiple days
 * - If shouldSplit is false, create a single event
 * - If the event is split, the start hour is the start hour of the first day and the end hour is the end hour of the last day
 * - If the event is not split, the start hour is the start hour and the end hour is the end hour
 *
 * @param tag
 * @param calendarType
 * @param description
 * @param shouldSplit
 * @param startHour
 * @param endHour
 */
export function scheduleTagEvent(
  tag: tagDocType,
  calendarType: CalendarType,
  description: string,
  shouldSplit: boolean,
  startHour: number,
  endHour: number
): void {
  // Start date is the current date
  let startDate = new Date();
  // Or the date_debut tag meta
  const tagMetaStartDate = tag.tag_meta?.find(
    (meta) => meta.type === 'date_debut'
  );
  if (tagMetaStartDate?.value) {
    startDate = new Date(tagMetaStartDate.value);
  }

  // End date is the start date + 1 hour
  let endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
  // Or the date_fin tag meta
  const tagMetaEndDate = tag.tag_meta?.find((meta) => meta.type === 'date_fin');
  if (tagMetaEndDate?.value) {
    endDate = new Date(tagMetaEndDate.value);
  }

  const isSameDay = startDate.toDateString() === endDate.toDateString();

  if (isSameDay || !shouldSplit) {
    const event: CalendarEvent = {
      id: tag.id,
      title: tag.libelle,
      startDate,
      endDate,
      description,
      location: '',
    };
    sendEventToCalendar(calendarType, event);
  }

  const days = Math.ceil(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  for (let i = 0; i < days; i++) {
    const event: CalendarEvent = {
      id: tag.id,
      title: tag.libelle,
      startDate: new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        i === 0 ? startDate.getHours() : startHour,
        i === 0 ? startDate.getMinutes() : 0
      ),
      endDate: new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        i === days - 1 ? endDate.getHours() : endHour,
        i === days - 1 ? endDate.getMinutes() : 0
      ),
      description,
      location: '',
    };
    sendEventToCalendar(calendarType, event);
  }
}
