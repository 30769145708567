import { Injectable } from '@angular/core';
import {
  ProductFamiliesType,
  ProductFamiliesTypeOrNull,
  ProductType,
  ProductTypeOrNull,
} from '@types_custom/ProductType';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  take,
} from 'rxjs/operators';
import { LOCALSTORAGE } from '../enum/local-storage';
import { flatProductsFromFamilyTree } from '../utils/productfamilies-utils.service';
import { ProductFamiliesTreeState } from './product-families-tree-state.service';
import { searchStateType } from '../../../@types_custom/searchStateType';
import { setToLocalStorage } from '../utils/localstorage-utils.service';
import { SpinnerStateService } from './spinner-state.service';

@Injectable({
  providedIn: 'root',
})
export class ProductSearchStateService {
  selectedFirstFamilyIndexSubject$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  selectedFirstFamilyIndex$: Observable<number | null> =
    this.selectedFirstFamilyIndexSubject$.asObservable();
  getSelectedFirstFamilyIndex() {
    return this.selectedFirstFamilyIndexSubject$.getValue();
  }
  setSelectedFirstFamilyIndex(num: number) {
    this.selectedFirstFamilyIndexSubject$.next(num);
  }

  selectedTreePathSubject$: BehaviorSubject<string[] | null> =
    new BehaviorSubject<string[] | null>(null);
  selectedTreePath$: Observable<string[] | null> =
    this.selectedTreePathSubject$.asObservable();
  getSelectedTreePath() {
    return this.selectedTreePathSubject$.getValue();
  }
  setSelectedTreePath(value: string[]) {
    this.selectedTreePathSubject$.next(value);
  }

  isOpenSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isOpen$: Observable<boolean> = this.isOpenSubject$.asObservable();

  originalTree$ = this.productTreeState.originalTree$;
  getOriginalTree() {
    return this.productTreeState.getOriginalTree();
  }

  startFamilySubject$: BehaviorSubject<ProductFamiliesType | null> =
    new BehaviorSubject<ProductFamiliesType | null>(null);
  startFamily$: Observable<ProductFamiliesType | null> =
    this.startFamilySubject$.asObservable();

  familyTree$: Observable<ProductFamiliesType[] | null> = combineLatest([
    this.productTreeState.originalTree$,
    this.startFamily$,
  ]).pipe(
    map(([originalTree, startFamily]) => {
      if (startFamily?.children_productfamilies?.length) {
        return startFamily.children_productfamilies;
      } else if (startFamily) {
        const selectedFamily = this.getSelectedFamily();
        if (startFamily.id !== selectedFamily?.id) {
          this.setSelectedFamily(startFamily);
        }
        return [];
      }

      return originalTree;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  productsFromTree$: Observable<ProductType[]> = this.familyTree$.pipe(
    map((families) => {
      if (!families) return [];
      return flatProductsFromFamilyTree(families);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  selectedProductSubject$: BehaviorSubject<ProductTypeOrNull> =
    new BehaviorSubject<ProductTypeOrNull>(null);
  selectedProduct$: Observable<ProductTypeOrNull> =
    this.selectedProductSubject$.asObservable();
  setSelectedProduct(product: ProductType) {
    this.selectedProductSubject$.next(product);
  }
  clearSelectedProduct() {
    this.selectedProductSubject$.next(null);
  }
  getSelectedProduct() {
    return this.selectedProductSubject$.getValue();
  }

  selectedFamilySubject$: BehaviorSubject<ProductFamiliesTypeOrNull> =
    new BehaviorSubject<ProductFamiliesTypeOrNull>(null);
  selectedFamily$: Observable<ProductFamiliesTypeOrNull> =
    this.selectedFamilySubject$.asObservable();

  setSelectedFamily(family: ProductFamiliesTypeOrNull) {
    this.selectedFamilySubject$.next(family);
  }

  clearSelectedFamily() {
    this.selectedFamilySubject$.next(null);
  }
  getSelectedFamily() {
    return this.selectedFamilySubject$.getValue();
  }

  searchStringSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  searchString$: Observable<string> = this.searchStringSubject$
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  setSearchString(value: string) {
    this.searchStringSubject$.next(value);
  }

  searchFamilyResultsSubject$: BehaviorSubject<ProductFamiliesType[] | null> =
    new BehaviorSubject<ProductFamiliesType[] | null>(null);
  searchFamilyResults$: Observable<ProductFamiliesType[] | null> =
    this.searchFamilyResultsSubject$.asObservable();
  setSearchFamilyResults(value: ProductFamiliesType[] | null) {
    this.searchFamilyResultsSubject$.next(value);
  }

  private resetStateSubject$: BehaviorSubject<null> = new BehaviorSubject<null>(
    null
  );

  searchState$: Observable<searchStateType> = combineLatest([
    this.isOpen$,
    this.startFamily$,
    this.selectedTreePath$,
    this.selectedFirstFamilyIndex$,
    this.searchString$,
  ]).pipe(
    distinctUntilChanged(),
    map(
      ([
        isOpen,
        startFamily,
        selectedTreePath,
        selectedFirstFamilyIndex,
        searchString,
      ]) => {
        return {
          isOpen,
          startFamily,
          selectedTreePath,
          selectedFirstFamilyIndex,
          searchString,
        } as searchStateType;
      }
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private productTreeState: ProductFamiliesTreeState,
    private spinnerState: SpinnerStateService
  ) {}

  doGoBack() {
    const selectedProduct = this.selectedProductSubject$.getValue();
    if (selectedProduct) {
      this.selectedProductSubject$.next(null);
      this.spinnerState.hideEmplacement();
      return;
    }

    const selectedFamily = this.selectedFamilySubject$.getValue();
    const firstFamily = this.startFamilySubject$.getValue();

    // Cas spécial pour les emplacements importés depuis mapillary
    if (selectedFamily && firstFamily && selectedFamily.id === firstFamily.id) {
      this.resetSearch();
      this.isOpenSubject$.next(false);
      this.spinnerState.hideEmplacement();
      return;
    }

    if (selectedFamily) {
      this.selectedFamilySubject$.next(null);
      this.spinnerState.hideEmplacement();
      return;
    }

    const selectedPath = this.selectedTreePathSubject$.getValue();
    if (selectedPath?.length && selectedPath.length > 0) {
      selectedPath.pop();
      if (
        selectedPath[selectedPath.length - 1] !== 'children_productfamilies'
      ) {
        selectedPath.pop();
      }

      if (selectedPath.length === 0) {
        this.selectedFirstFamilyIndexSubject$.next(null);
      }

      this.selectedTreePathSubject$.next(selectedPath);
      this.spinnerState.hideEmplacement();
      return;
    }

    this.resetSearch();
    this.isOpenSubject$.next(false);
    this.spinnerState.hideEmplacement();
  }
  goBack() {
    this.spinnerState.showEmplacement();
    this.doGoBack();
  }

  setStartFamily(family: ProductFamiliesType | null) {
    this.startFamilySubject$.next(family);
  }

  closeSearch() {
    this.isOpenSubject$.next(false);
  }
  openSearch() {
    this.isOpenSubject$.next(true);
  }

  resetSearch() {
    this.selectedFamilySubject$.next(null);
    this.startFamilySubject$.next(null);
    this.selectedProductSubject$.next(null);
    this.selectedFirstFamilyIndexSubject$.next(null);
    this.selectedTreePathSubject$.next(null);
    this.resetStateSubject$.next(null);
  }

  chooseProduct(
    startFamily: ProductFamiliesType | null
  ): Promise<ProductType | null> {
    this.spinnerState.hideEmplacement();
    return new Promise((resolve, reject) => {
      this.resetSearch();
      if (startFamily) {
        this.setStartFamily(startFamily);
      }
      this.openSearch();

      const subscription = combineLatest([
        this.isOpen$,
        this.selectedProduct$,
      ]).subscribe(([isOpen, selectedProduct]) => {
        // Si la recherche est fermé, plus besoin d'écouter
        if (!isOpen) {
          subscription.unsubscribe();
          resolve(null);
        }

        // Tant que le produit n'est pas sélectionné, on ne fait rien
        if (!selectedProduct?.id) return;
        subscription.unsubscribe();

        if (startFamily)
          setToLocalStorage(
            LOCALSTORAGE.DEFAUT_PRODUCT_PREFIX + startFamily.id,
            selectedProduct.id
          );

        this.closeSearch();
        resolve(selectedProduct);
        subscription.unsubscribe();
      });
    });
  }

  isWaitingForChooseProduct() {
    return this.isOpenSubject$.getValue();
  }

  waitForChooseProduct() {
    return lastValueFrom(
      this.isOpen$.pipe(
        filter((isOpen) => !isOpen),
        take(1)
      )
    );
  }
}
