import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GraphQlService {
  constructor(private http: HttpClient) {}

  public doCall<T = any>(
    operationsDoc: any,
    variables = {},
    operationName = 'MyQuery'
  ): Observable<GraphQLResponse<T>> {
    const body = {
      query: operationsDoc,
      variables,
      operationName,
    };

    return this.http.post<GraphQLResponse<T>>(environment.HASURA_URL, body);
  }
}

interface GraphQLResponse<T = any> {
  data: T;
  errors?: { message: string }[];
}
