import { Pipe, PipeTransform } from '@angular/core';
import { TagService } from '../../services/tag/tag.service';
import { InterventionsStateService } from './../../state/interventions-state.service';
import { getInterventionStatusString } from '../interventions/getInterventionStatusString';

@Pipe({
    name: 'tag',
    standalone: true,
})
export class TagPipe implements PipeTransform {
  constructor(
    private tagService: TagService,
    private interventionState: InterventionsStateService
  ) {}

  transform(
    idTag: string | undefined | null,
    property: 'libelle' | 'intervention_status' | undefined = undefined
  ): string {
    if (!idTag) return '';

    // Récupération du tag
    const tag = this.tagService.getTags().find((el) => el.id === idTag);
    if (!tag) return '';

    // Case libelle
    if (property === 'libelle') return tag.libelle || '';

    // Case intervention_status
    if (property === 'intervention_status') {
      const intervention = this.interventionState
        .getInterventions()
        .find((el) => el.id_tag === idTag);
      if (!intervention) return '';
      return getInterventionStatusString(intervention);
    }

    // Case default
    return '';
  }
}
