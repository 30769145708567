import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { NgIf, AsyncPipe, NgFor } from '@angular/common';
import { graphql } from '../../services/nhost';
import { gql } from 'graphql-request';
import { ToolsService } from '../../services/tools.service';
import { HistoryDialogComponent } from './stock-history.dialog';
import { ModalService } from '../../services/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupeIdPipe } from '../../pipes/groupes/groupe-id.pipe';

@Component({
  selector: 'app-history-button',
  template: `
    <app-ugau-button
      i18n-label
      label=""
      [hasBorder]="false"
      iconName="history"
      (click)="showHistory()"
    >
    </app-ugau-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    GroupeIdPipe,
    UgauButtonComponent,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    AsyncPipe,
  ],
})
export class StockHistoryComponent {
  private tools = inject(ToolsService);
  private dialog = inject(MatDialog);
  private modalService = inject(ModalService);

  @Input() type!: 'stock' | 'materiel';

  async showHistory() {
    try {
      const history = await getHistory(this.type);
      this.showDialog(history);
    } catch (e: any) {
      this.tools.toastError(e.message);
    }
  }

  showDialog(data: any[]) {
    const params = this.modalService.getParams(false);
    this.dialog.open(HistoryDialogComponent, {
      ...params,
      height: 'auto',
      width: 'auto',
      data: data,
    });
  }
}

async function getHistory(type: 'stock' | 'materiel') {
  const { data, error } = await graphql.request(gql`
    query MyQuery {
      stock_history(
      where: { stock: { type: { _eq: ${type} } } }
      order_by: { created_at: desc }
      ) {
        change_quantity
        change_type
        created_at
        created_by
        description
        groupe_id
        id
        previous_quantity
        stock {
          id
          product_id
          meta
        }
      }
    }
  `);

  if (error) {
    throw new Error("Erreur lors de la récupération de l'historique");
  }

  return data.stock_history;
}
