import {
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  Renderer2,
} from '@angular/core';
import { FocusService } from './focus.service'; // Assurez-vous du bon chemin
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appFocusState]',
  standalone: true,
})
export class FocusStateDirective {
  private destroyRef = inject(DestroyRef);

  @Input('appFocusState') elementId!: string; // L'ID unique de l'élément (modale ou emplacement)

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private focusService: FocusService
  ) {
    this.focusService
      .getFocusedElement()
      .pipe(takeUntilDestroyed(this.destroyRef))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe((focusedId) => {
        if (this.elementId === 'modale') {
          let zIndex = 1000;

          if (focusedId === 'emplacement') {
            zIndex = 999;
          }
          if (focusedId === 'filtres') {
            zIndex = 998;
          }

          this.renderer.setStyle(
            this.el.nativeElement.parentElement,
            'z-index',
            zIndex
          );
        }

        if (this.elementId === 'menu') {
          if (focusedId === 'menu') {
            this.renderer.setStyle(this.el.nativeElement, 'z-index', '1001');
          } else {
            this.renderer.setStyle(this.el.nativeElement, 'z-index', '3');
          }
        }

        if (this.elementId === 'filtres') {
          if (focusedId === 'filtres') {
            this.renderer.setStyle(this.el.nativeElement, 'z-index', '1001');
          } else {
            this.renderer.setStyle(this.el.nativeElement, 'z-index', '1');
          }
        }
      });
  }

  @HostListener('click')
  onClick() {
    this.focusService.setFocusedElement(this.elementId);
  }
}
