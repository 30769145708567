import { Router } from '@angular/router';
import { EventsService } from './events.service';
import { auth } from './nhost';
import { MatDialog } from '@angular/material/dialog';
import { ToolsService } from './tools.service';
import { Injectable } from '@angular/core';
import { AuthenticationServiceInterface } from './authentication.interface';
import { MobileAuthenticationService } from './authentication.mobile';
import { DesktopAuthenticationService } from './authentication.desktop';
import { DialogChangePasswordComponent } from '../components/dialogs/dialog-change-password/dialog-change-password.component';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// implements AuthenticationServiceInterface
export abstract class AuthenticationService {
  idUser: string | undefined;
  defaultRole: string = 'user';
  displayName: string = '';
  email: string = '';

  private authService: AuthenticationServiceInterface;

  constructor(
    public tools: ToolsService,
    public event: EventsService,
    public router: Router,
    private dialog: MatDialog
  ) {
    this.authService = this.tools.isMobile()
      ? new MobileAuthenticationService(this.tools, this.event, this.router)
      : new DesktopAuthenticationService(this.tools, this.event, this.router);
  }

  // PROXY METHODS
  public getIsOnline() {
    return this.authService.getIsOnline();
  }

  public init() {
    return this.authService.init();
  }

  public getToken() {
    return this.authService.getToken();
  }

  public isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  public isLoggedInAsync() {
    return this.authService.isLoggedInAsync();
  }

  public login(user: any) {
    return this.authService.login(user);
  }

  public signIn(email: string) {
    return auth.signIn({ email });
  }

  // public hasTraceAuth() { return this.authService.hasTraceAuth(); }

  public getUserId() {
    return this.authService.getUserId();
  }

  public refreshToken() {
    return this.authService.refreshToken();
  }

  public logout() {
    return this.authService.logout();
  }

  public logoutAll() {
    return this.authService.logoutAll();
  }

  // public register(email: string, password: string) { return this.authService.register(email, password); }

  // CUSTOM METHODS
  public changeEmail(email: string) {
    return auth.changeEmail({ newEmail: email });
  }

  public changePassword(oldPass: string, newPass: string) {
    return auth.changePassword({ newPassword: newPass });
  }

  public getUser() {
    return auth.getUser();
  }

  public promptNewPassword(
    title: string,
    description: string = '',
    disableClose: boolean = false
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogChangePasswordComponent, {
        panelClass: 'dialog-change-password',
        backdropClass: 'backdrop-change-password',
        data: {
          title,
          description,
          disableClose,
        },
        hasBackdrop: false,
        width: '500px',
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
        .subscribe((result) => {
          if (!result) {
            reject('Annulé');
            return;
          }

          auth
            .changePassword({
              newPassword: result,
            })
            .then(({ error }) => {
              if (error) {
                reject(error.message);
                return;
              }
              resolve(result);
            });
        });
    });
  }
}

export const isAdmin = () => {
  const email = auth.getUser()?.email;
  return email === 'admin@ugau.fr' || email === 'jamel+1@ugau.fr';
};
