import { Injectable } from '@angular/core';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted,
  lastOfArray,
} from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { ensureValidToken, graphql } from './../../services/nhost';
import {
  stockDocType,
  stockSchema,
  stockSchemaLiteral,
} from '../schemas/stock.schema';
import { SentryService } from '../../services/sentry.service';
import { Checkpoint } from '../interfaces/Checkpoint.type';
import { MUTATION_INSERT_STOCK } from '../queries/stock/MUTATION_INSERT_STOCK';
import { QUERY_GET_STOCK } from '../queries/stock/QUERY_GET_STOCK';

@Injectable({
  providedIn: 'root',
})
export class StockProvider extends AbstractProvider<stockDocType> {
  schema = stockSchema;
  schemaLiteral = stockSchemaLiteral;

  protected migrationStrategies = {
    1: function (oldDoc: stockDocType) {
      return null;
    },
  };

  async getGetPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<stockDocType, Checkpoint>> {
    const variables = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: { updated_at: 'asc' },
      limit: batchSize,
    };
    const { data, error } = await graphql.request(QUERY_GET_STOCK, variables);

    if (error || !data.stock) {
      console.error(
        'Erreur lors de la récupération des stocks sur le serveur',
        error
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.stock.map((doc: any) => {
      return {
        ...doc,
        deleted_bool: !doc.deleted_at ? false : true,
      };
    });

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<stockDocType>[]
  ): Promise<WithDeleted<stockDocType>[]> {
    await ensureValidToken();

    const datas = docs.reduce(
      (acc: any[], rxDoc: RxReplicationWriteToMasterRow<stockDocType>) => {
        const doc = rxDoc.newDocumentState;
        const stock = {
          id: doc.id,
          groupe_id: doc.groupe_id,
          product_id: doc.product_id,
          emplacement_id: doc.emplacement_id === '' ? null : doc.emplacement_id,
          type: doc.type,
          quantity: doc.quantity,
          price: doc.price,
          meta: doc.meta,
          supplier: doc.supplier,
          alert_threshold: doc.alert_threshold,
          created_at: doc.created_at,
          updated_at: doc.updated_at,
          created_by: doc.created_by,
          updated_by: doc.updated_by,
          deleted_at: doc.deleted_at,
          deleted_by: doc.deleted_by,
        };

        acc.push(stock);
        return acc;
      },
      []
    );
    try {
      const variables = {
        objects: datas,
        on_conflict: {
          constraint: 'stock_pkey',
          update_columns: [
            'groupe_id',
            'product_id',
            'emplacement_id',
            'quantity',
            'meta',
            'price',
            'type',
            'supplier',
            'alert_threshold',
            'updated_at',
            'updated_by',
            'deleted_at',
            'deleted_by',
          ],
        },
      };
      const query = MUTATION_INSERT_STOCK;
      const { error } = await graphql.request(query, variables);

      if (error) {
        SentryService.captureException(error);
        console.log('ERREUR Push stock', error);
        this.AT.toastError($localize`Erreur lors de la sauvegarde du stock`);
        return [];
      }

      this.AT.toastError($localize`Stock sauvegardé`);
      return [];
    } catch (error) {
      SentryService.captureException(error);
      console.log('ERREUR Push stock', error);
      this.AT.toastError($localize`Erreur lors de la sauvegarde du stock`);
      return [];
    }
  }
}
