import { NhostClient } from '@nhost/nhost-js';
import { environment } from './../../environments/environment';

const nhost = new NhostClient({
  subdomain: environment.NHOST_SUBDOMAIN,
  region: environment.NHOST_REGION,
  autoSignIn: true,
  autoRefreshToken: true,
});

const auth = nhost.auth;
const graphql = nhost.graphql;
const storage = nhost.storage;

export { auth, storage, graphql };

/**
 * Récupère l'ID de l'utilisateur actuellement connecté.
 * @throws {Error} Si aucun utilisateur n'est connecté.
 * @returns {string} L'ID de l'utilisateur.
 */
export function getUserID(): string {
  const user = auth.getUser();
  if (user) {
    return user.id;
  }
  throw new Error('User not found in nhost auth');
}

/**
 * Vérifie et rafraîchit le token si nécessaire.
 */
export async function ensureValidToken() {
  const token = auth.getAccessToken();
  if (!token || isTokenExpired(token)) {
    console.log('Token expired, refreshing...');
    await auth.refreshSession();
  }
}

/**
 * Vérifie si le token est expiré.
 * @param {string} token - Le token à vérifier.
 * @returns {boolean} Vrai si le token est expiré, sinon faux.
 */
function isTokenExpired(token: string): boolean {
  const decodedToken = auth.getDecodedAccessToken();
  if (!decodedToken) {
    return true;
  }
  const exp = decodedToken.exp;
  if (!exp) {
    return true;
  }
  const now = Math.floor(Date.now() / 1000);
  return exp < now;
}
