import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { DatabaseService } from '../../db/database.service';
import { Providers } from '../../db/providers-interface';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MatGridListModule } from '@angular/material/grid-list';
import { AbstractProvider } from '../../db/providers/abstract-provider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { scan } from 'rxjs/operators';
import { trackByNameFn } from '../../services/trackByFunctions.utils';

type colls = {
  name: string;
  count: Observable<number>;
  replicationErrors: Observable<any[]> | undefined;
  replicationStatus: Observable<string> | undefined;
  lastCheckpoint: Observable<string | undefined>;
  provider: AbstractProvider<any>;
};

@Component({
  selector: 'app-database-status',
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatTooltipModule,
    MatGridListModule,
  ],
  templateUrl: './database-status.component.html',
  styleUrls: ['./database-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatabaseStatusComponent implements OnInit {
  trackByName = trackByNameFn;
  collectionsStatusSubject$ = new BehaviorSubject<colls[]>([]);
  collectionsStatus$ = this.collectionsStatusSubject$.asObservable();

  constructor(private databaseService: DatabaseService) {}

  ngOnInit(): void {
    this.getCollectionsStatus();
  }

  getCollectionsStatus() {
    const providers: Providers = this.databaseService.getProviders();
    const collectionsStatus: colls[] = [];

    for (const key of Object.keys(providers)) {
      const provider = providers[key as keyof Providers];
      const count = provider.collection.count().$;

      const replication = provider.replicateState;
      const lastCheckpoint = provider.lastCheckpoint$.pipe(
        map((checkpoint) => {
          return checkpoint?.updatedAt
            ? new Date(checkpoint.updatedAt).toLocaleString()
            : undefined;
        })
      );
      const replicationStatus = replication?.active$.pipe(
        map((active) => (replication.isStopped() ? 'Stoppée' : 'Ok'))
      );

      const replicationErrors = replication?.error$?.pipe(
        scan(
          (allErrors, currentError) => [...allErrors, currentError],
          [] as any[]
        )
      );

      const name = provider.getCleanEntityName();
      if (name)
        collectionsStatus.push({
          name,
          count,
          replicationErrors,
          replicationStatus,
          lastCheckpoint,
          provider,
        });
    }

    this.collectionsStatusSubject$.next(collectionsStatus);
  }

  resyncCollection(provider: AbstractProvider<any>) {
    provider.resetSync();
  }
}
