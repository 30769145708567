import { composantDocType } from '../../db/schemas/emplacement.schema';
import { getDesignationComposant } from '../../utils/composant/getDesignationComposant';


export function getComposantName(
  composant: composantDocType,
  choosingDisplay: boolean = false
): string {
  if (composant.composant_productfields.length > 0 && !choosingDisplay) {
    const designation = getDesignationComposant(composant);
    if (designation) {
      return designation;
    }
  }

  if (composant.product?.name) {
    return composant.product.name;
  }

  return '';
}
