import { NgIf, DatePipe, NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { formatFULL } from '../../../utils/date-formats';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { TableComponentBase } from '../../table-base.component';
import { MatMenuModule } from '@angular/material/menu';
import {
  downloadArrayAsCSV,
  generateTimeName,
} from '../../../services/utils.service';
import { StockState } from '../../stock/stock.state';
import { ProductFamiliesTreeState } from '../../../state/product-families-tree-state.service';
import { emplacementDocType } from '../../../db/schemas/emplacement.schema';
import { EmplacementPipe } from '../../../pipes/emplacements/emplacement.pipe';
import { v4 as uuidv4 } from 'uuid';
import { IsEmplacementConformePipe } from '../../../pipes/emplacements/is-emplacement-conforme.pipe';
import { getProductsOrder } from '../../../utils/product/getProductsOrder';
import { getEmplacementLibelle } from '../../../utils/emplacement/getEmplacementLibelle';
import { CommonStateService } from '../../../state/commun-state.service';
import { isConformeEmplacement } from '../../../utils/emplacement/isConformeEmplacement';

@Component({
  selector: 'app-batiment-list',
  templateUrl: './batiment-list.component.html',
  styleUrls: ['./batiment-list.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    NgFor,
    NgIf,
    UgauButtonComponent,
    UgauInputAutocompleteComponent,
    MatMenuModule,
    EmplacementPipe,
    IsEmplacementConformePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBatimentListComponent extends TableComponentBase<emplacementDocType> {
  key = 'batiment';
  private stockState = inject(StockState);
  private productTreeState = inject(ProductFamiliesTreeState);
  private treeState = inject(ProductFamiliesTreeState);
  private productsOrder = getProductsOrder(this.treeState.getOriginalTree());
  private commonState = inject(CommonStateService);

  showFilters = !this.isMobile;

  formatFULL = formatFULL;

  data$: Observable<emplacementDocType[]> = this.stockState.depots$;

  displayedColumns: string[] = this.isMobile
    ? ['name', 'conforme']
    : ['name', 'conforme', 'created_at', 'updated_at'];
  exceptSearchForColumns: string[] = ['created_at', 'updated_at'];

  columnDefs: Record<string, { title: string }> = {
    name: {
      title: $localize`Libelle`,
    },
    conforme: {
      title: $localize`Conforme`,
    },
    created_at: {
      title: $localize`Créé le`,
    },
    updated_at: {
      title: $localize`Modifié le`,
    },
  };

  async addNewBatiment() {
    const batimentId = await this.productTreeState.getBatimentFirstFamilyId();
    if (!batimentId) {
      this.tools.launchErrorAlert(
        'Erreur lors de la récupération du module batiment'
      );
      return;
    }
    this.stockState.routingState.navigateEmplacementCreate(
      undefined,
      uuidv4(),
      [batimentId],
      true,
      'batiment'
    );
  }

  editBatiment(stock: emplacementDocType) {
    this.stockState.routingState.navigateEmplacement(stock.id, true);
  }

  customSortingDataAccessor(data: emplacementDocType, sortHeaderId: string) {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (sortHeaderId) {
      case 'name':
        return getEmplacementLibelle(data, this.productsOrder);
      case 'conforme':
        const { reglementations, properties, fields } =
          this.commonState.getCommon();
        return isConformeEmplacement(data, properties, fields, reglementations)
          ? $localize`Oui`
          : $localize`Non`;
      default:
        return (data as any)[sortHeaderId];
    }
  }

  customCreateFilter(
    data: emplacementDocType,
    key: string,
    value: string
  ): boolean | undefined {
    if (key === 'name' && value) {
      return getEmplacementLibelle(data, this.productsOrder)
        .toLowerCase()
        .includes(value.toLowerCase());
    }
    if (key === 'conforme' && value) {
      const { reglementations, properties, fields } =
        this.commonState.getCommon();
      const conforme = isConformeEmplacement(
        data,
        properties,
        fields,
        reglementations
      )
        ? $localize`Oui`
        : $localize`Non`;
      return conforme.toLowerCase().includes(value.toLowerCase());
    }
    return undefined;
  }

  customGetFilterObject(
    data: emplacementDocType,
    key: string
  ): string | undefined {
    if (key === 'name') {
      return getEmplacementLibelle(data, this.productsOrder);
    }
    if (key === 'conforme') {
      const { reglementations, properties, fields } =
        this.commonState.getCommon();
      return isConformeEmplacement(data, properties, fields, reglementations)
        ? $localize`Oui`
        : $localize`Non`;
    }
    return undefined;
  }

  export() {
    const i18nKeys = {
      name: $localize`Libelle`,
      conforme: $localize`Conforme`,
      created_at: $localize`Créé le`,
      updated_at: $localize`Modifié le`,
    };
    const data = this.dataSource.filteredData;
    const csvArray = data.map((el) => {
      return {
        [i18nKeys.name]: getEmplacementLibelle(el, this.productsOrder),
        [i18nKeys.conforme]: isConformeEmplacement(
          el,
          this.commonState.getCommon().properties,
          this.commonState.getCommon().fields,
          this.commonState.getCommon().reglementations
        )
          ? $localize`Oui`
          : $localize`Non`,
        [i18nKeys.created_at]: el.created_at,
        [i18nKeys.updated_at]: el.updated_at,
      };
    });

    const filename = generateTimeName('export_stock');
    downloadArrayAsCSV(csvArray, filename);
  }
}
