/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { IGN_URL } from './layersChoices';

const WMTS_CAPABILITIES_URL = `${IGN_URL}wmts?SERVICE=WMTS&REQUEST=GetCapabilities&VERSION=1.0.0`;

export async function fetchWMTSCapabilities() {
  const response = await fetch(WMTS_CAPABILITIES_URL);
  const text = await response.text();
  const parser = new DOMParser();
  const xml = parser.parseFromString(text, 'application/xml');

  const layers: any[] = [];
  const layerNodes = xml.querySelectorAll('Layer');
  const tileMatrixSets: any = {};

  // Extraire tous les TileMatrixSet avec leurs niveaux de zoom
  xml.querySelectorAll('TileMatrixSet').forEach((tileMatrixSetNode) => {
    const identifier = tileMatrixSetNode.querySelector(
      'ows\\:Identifier, Identifier'
    )?.textContent;
    const tileMatrixNodes = tileMatrixSetNode.querySelectorAll('TileMatrix');

    const zoomLevels = Array.from(tileMatrixNodes).map((node) => {
      const zoomVal = node.querySelector(
        'ows\\:Identifier, Identifier'
      )?.textContent;
      const zoom = parseInt(zoomVal || '0', 10);
      return zoom;
    });

    if (identifier) {
      tileMatrixSets[identifier] = {
        minZoom: Math.min(...zoomLevels),
        maxZoom: Math.max(...zoomLevels),
      };
    }
  });

  // Extraire les informations de chaque couche
  layerNodes.forEach((layerNode) => {
    const title = layerNode.querySelector('ows\\:Title, Title')?.textContent;
    const identifier = layerNode.querySelector(
      'ows\\:Identifier, Identifier'
    )?.textContent;
    const formatNodes = layerNode.querySelectorAll('Format');

    // Filtrer pour n'inclure que les formats 'image/png'
    const formats = Array.from(formatNodes).map((node) => node.textContent);
    const hasPngFormat = formats.includes('image/png');
    if (!hasPngFormat) return;

    // Définir le style par défaut, priorisant 'nolegend' si disponible
    let style = 'normal';
    // Style > ows:Keywords > ows:Keyword
    const styleNodes = layerNode.querySelectorAll('Style');
    styleNodes.forEach((node) => {
      const identifierNode = node.querySelector('ows\\:Identifier, Identifier');
      const styleName = identifierNode?.textContent;
      if (styleName) style = styleName;
    });

    const legendImgUrl = layerNode
      .querySelector('LegendURL')
      ?.getAttribute('xlink:href');

    // Extraire les limites géographiques (bounds) de la couche
    const bboxNode = layerNode.querySelector(
      'ows\\:WGS84BoundingBox, WGS84BoundingBox'
    );
    let bounds = null;
    if (bboxNode) {
      const lowerCorner = bboxNode
        .querySelector('ows\\:LowerCorner, LowerCorner')
        ?.textContent?.split(' ');
      const upperCorner = bboxNode
        .querySelector('ows\\:UpperCorner, UpperCorner')
        ?.textContent?.split(' ');

      if (lowerCorner && upperCorner) {
        bounds = [
          parseFloat(lowerCorner[0]), // minLon
          parseFloat(lowerCorner[1]), // minLat
          parseFloat(upperCorner[0]), // maxLon
          parseFloat(upperCorner[1]), // maxLat
        ];
      }
    }

    // Récupérer le TileMatrixSet pour obtenir les niveaux de zoom min et max
    const tileMatrixSetLink = layerNode.querySelector(
      'TileMatrixSetLink > TileMatrixSet'
    );
    const tileMatrixSetIdentifier = tileMatrixSetLink
      ? tileMatrixSetLink.textContent
      : 'PM';
    const zoomInfo =
      tileMatrixSets && tileMatrixSetIdentifier
        ? tileMatrixSets[tileMatrixSetIdentifier]
        : {
            minZoom: 0,
            maxZoom: 18,
          }; // valeurs par défaut

    if (title && identifier) {
      layers.push({
        title: title + ' (IGN)',
        uri: identifier,
        style,
        format: 'image/png',
        minZoom: zoomInfo.minZoom,
        maxZoom: zoomInfo.maxZoom,
        bounds, // Ajout des bornes géographiques
        ...(legendImgUrl !==
          'https://data.geopf.fr/annexes/ressources/legendes/LEGEND.jpg' && {
          legendImgUrl,
        }), // Ajout de l'URL de la légende
      });
    }
  });

  return layers;
}
