import {
  URI_urbanisme,
  IGN_URL,
} from './layersChoices';
import { mapRemoveSandLIgnRaster, loadIgnRasterSourceAndLayer } from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

export function addMapLayerDUPSMV(map: Map, remove = false) {
  const sourceLib = URI_urbanisme;
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);

  // URL construite spécifiquement pour les couches `du` et `psmv` sans modifier les constantes globales
  const url =
    `${IGN_URL}wms-v/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap` +
    `&FORMAT=image%2Fpng&TRANSPARENT=true` +
    `&LAYERS=du,psmv&WIDTH=256&HEIGHT=256` +
    `&CRS=EPSG%3A3857&STYLES=&BBOX={bbox-epsg-3857}`;

  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}
