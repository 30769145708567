export enum REGLEMENTATION {
  'HAUTEUR' = '1',
  'AGE' = '2',
  'ENDOMMAGE' = '3',
  'PROPRETE' = '4',
  'CERTIFICATION' = '5',
  'ACCESSIBILITE' = '6',
  'ACCIDENTE' = '7',
  'TEXTE' = '8',
  'RECURRENCE' = '10',
}
