import { fr } from 'date-fns/locale';
import { Environment } from './environment.interface';
export const environment: Environment = {
  ADMIN_SECRET: 'ADMIN_SECRET_PROD',
  ENABLE_SENTRY: true,
  FRONT_URL: 'https://app.ugau.fr/',
  HASURA_URL: 'https://vzynrsfekwhjstrmoxhb.graphql.eu-central-1.nhost.run/v1',
  IONIC_APP_ID: '45b4f44f',
  IONIC_LIVEUPDATE_CHANNEL: 'Production',
  LOGO_IMG_SRC: '/assets/imgs/LOGO UGAU ANDROID.png',
  LOGO_URL: 'https://www.ugau.fr/',
  NHOST_REGION: 'eu-central-1',
  NHOST_SUBDOMAIN: 'vzynrsfekwhjstrmoxhb',
  PASSPHRASE: 'ceci est un test',
  SEGMENT_API: 'dhRzNPKlPnxbSd92R5mbn37qVRNo0mUX',
  environment: { production: true, libelle: 'production' },
  ENABLE_CART: true,
  ENABLE_PR: true,
  LANG: 'FR',
  SPRITE_URL: 'https://map.ugau.fr/sprite/sprite',
  MAPBOX_START_LOCATION: { latitude: 46.71109, longitude: 1.7191036 },
  MAPBOX_START_ZOOM: 7,
  ENABLE_HELP_LINK: true,
  HELP_LINK: 'https://blog.ugau.fr/aide/',
  LOCALE: { locale: fr },
  SPRITE_URL_HASH: 'https://map.ugau.fr/sprite/hash.txt',
  ENABLE_REGISTER: false,
};
