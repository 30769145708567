import { Injectable } from '@angular/core';
import {
  lastOfArray,
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted,
} from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { QUERY_V2_GET_TAGS } from '../queries/tag/QUERY_V2_GET_TAGS';
import { tagDocType, tagSchema, tagSchemaLiteral } from '../schemas/tag.schema';
import { transformTagForPushQuery } from '../../utils/tag/tag-util.service';
import { graphql } from './../../services/nhost';
import { SentryService } from '../../services/sentry.service';
import { Checkpoint } from '../interfaces/Checkpoint.type';
import { DELETE_TAG_META_MUTATION } from '../queries/tag_meta/DELETE_TAG_META_MUTATION';

@Injectable({
  providedIn: 'root',
})
export class TagProvider extends AbstractProvider<tagDocType> {
  public schema = tagSchema;
  public schemaLiteral = tagSchemaLiteral;

  protected migrationStrategies = {
    1: function (oldDoc: tagDocType) {
      return null;
    },
    2: function (oldDoc: tagDocType) {
      return null;
    },
    3: function (oldDoc: tagDocType) {
      return null;
    },
    4: function (oldDoc: tagDocType) {
      return null;
    },
  };

  async getGetPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<tagDocType, Checkpoint>> {
    const variables = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    };
    const { data, error } = await graphql.request(QUERY_V2_GET_TAGS, variables);
    if (error || !data.tag) {
      console.log(`Erreur lors de la récupération des tags sur le serveur`);
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }
    const documentsFromRemote: any[] = data.tag;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  public pullQueryModifier(doc: any) {
    return doc;
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<tagDocType>[]
  ): Promise<WithDeleted<tagDocType>[]> {
    try {
      const { query, variables } = transformTagForPushQuery(docs);
      const { error } = await graphql.request(query, variables);
      // Gestion de l'erreur sur le retour de la requête
      if (error) {
        SentryService.captureException(error);
        console.log('ERREUR Push tag', error);
        this.AT.toastError(
          $localize`Erreur lors de la sauvegarde sur le serveur`
        );
      }
      return [];
    } catch (e) {
      SentryService.captureException(e);
      console.log('ERREUR Push tag', e);
      return [];
    }
  }

  async removeTagMeta(metaId: string) {
    const { error } = await graphql.request(DELETE_TAG_META_MUTATION, {
      id: metaId,
    });
    if (error) {
      SentryService.captureException(error);
      console.log('ERREUR Delete tag meta', error);
      this.AT.toastError($localize`Erreur lors de la suppression`);
    }
  }
}
