import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { environment } from '../environments/environment';

const ENABLE_REGISTER = environment.ENABLE_REGISTER;

const routes: Routes = ENABLE_REGISTER
  ? [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'updating',
        loadChildren: () =>
          import('./pages/updating-app/updating-app.module').then(
            (m) => m.UpdatingAppPageModule
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/page-login/page-login.module').then(
            (m) => m.PageLoginPageModule
          ),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./pages/page-register/page-register.module').then(
            (m) => m.PageRegisterPageModule
          ),
      },
      {
        path: 'loading',
        loadChildren: () =>
          import('./pages/loading/loading.module').then(
            (m) => m.LoadingPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuard],
      },
    ]
  : [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'updating',
        loadChildren: () =>
          import('./pages/updating-app/updating-app.module').then(
            (m) => m.UpdatingAppPageModule
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/page-login/page-login.module').then(
            (m) => m.PageLoginPageModule
          ),
      },
      {
        path: 'loading',
        loadChildren: () =>
          import('./pages/loading/loading.module').then(
            (m) => m.LoadingPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuard],
      },
    ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppRoutingModule {}
