import { v4 as uuidv4 } from 'uuid';
import {
  reglementationDocType,
  reglementationChoixDocType,
} from '../../db/schemas/reglementation.schema';
import {
  composantDocType,
  conformiteDocType,
} from '../../db/schemas/emplacement.schema';
import { ProductType } from '@types_custom/ProductType';

function createConformiteFromReglementationId(
  reglementation: reglementationDocType | undefined,
  userId: string
) {
  if (!reglementation) return; // Reglementation non trouvée

  const reglementationChoix = reglementation.reglementation_choixes.find(
    (reglementationChoix: reglementationChoixDocType) =>
      reglementationChoix.est_conforme
  );

  return {
    id: uuidv4(),
    commentaire: '',
    created_at: new Date().toISOString(),
    created_by: userId,
    deleted_at: null,
    deleted_by: null,
    est_conforme:
      reglementationChoix === undefined
        ? true
        : reglementationChoix.est_conforme,
    id_reglementation: parseInt(reglementation.id),
    id_reglementation_choix: reglementationChoix?.id.toString(),
    valeur: '',
  } as conformiteDocType;
}

export function generateConformites(
  composant: composantDocType,
  selectedProduct: ProductType,
  reglementations: reglementationDocType[],
  userId: string
) {
  return selectedProduct.reglementations
    .map((el) => {
      const existInComposant = composant.conformites.find(
        (e: conformiteDocType) =>
          e.id_reglementation &&
          typeof el === 'number' &&
          e.id_reglementation === el
      );

      const idReglementation =
        typeof el === 'number' ? el : el.reglementation_id;
      const reglementation = reglementations.find(
        (reg) => reg.id === idReglementation.toString() // == string/int comp
      );
      const result = createConformiteFromReglementationId(
        reglementation,
        userId
      );
      if (existInComposant) {
        return {
          ...result,
          id: existInComposant.id,
        };
      }
      return result;
    })
    .filter((value): value is conformiteDocType => {
      return value !== undefined;
    });
}
