<button
  type="button"
  class="mapboxgl-ctrl-icon mapboxgl-style-custom-ign"
  [class.hidden]="(isModalOpen$ | async) !== false"
  (click)="openModal()"
></button>

<div class="mapboxgl-style-container" *ngIf="isModalOpen$ | async">
  <div class="mapboxgl-style-search">
    <app-ugau-input
      *ngIf="enableSearch"
      placeholder="Rechercher un style..."
      [value]="searchQuery"
      (inputChange)="filterStyles($event)"
      [isSearchBar]="true"
      [appearance]="'fill'"
      [timeDebounce]="0"
    ></app-ugau-input>
  </div>
  <div class="mapboxgl-style-list">
    <button
      (click)="handleStyleClick(style)"
      *ngFor="let style of filteredStyles; trackBy: trackByUri"
      type="button"
      [class.active]="activeLayers.has(style.uri)"
    >
      {{ style.title }}
      <div *ngIf="activeLayers.has(style.uri) && style.legendImgUrl">
        <img [src]="style.legendImgUrl" alt="Légende" class="w100" />
      </div>
    </button>
    <button (click)="loadMoreFromIgn()" type="button">
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>
</div>
