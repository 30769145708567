import { Pipe, PipeTransform } from '@angular/core';
import { ProductFamiliesTreeState } from './../../state/product-families-tree-state.service';
import { recursiveFindFamilyFromFamiliesId } from '../../utils/productfamilies-utils.service';
import { composantDocType } from '../../db/schemas/emplacement.schema';
import { getComposantName } from '../../utils/composant/getComposantName';

@Pipe({
  name: 'productName',
  standalone: true,
})
export class ProductNamePipe implements PipeTransform {
  constructor(private productTreeState: ProductFamiliesTreeState) {}

  async transform(
    composant: composantDocType,
    choosingDisplay: boolean = false
  ): Promise<string> {
    const composantName = getComposantName(composant, choosingDisplay);
    if (composantName) {
      return composantName;
    }

    if (composant.type) {
      const originalTree = await this.productTreeState.getOriginalTreePromise();
      if (!originalTree) return '';

      const family = recursiveFindFamilyFromFamiliesId(
        composant.type,
        originalTree
      );
      if (family && !choosingDisplay) {
        return family.name;
      }
      if (family && choosingDisplay) {
        return $localize`Sélectionner un produit ...`;
      }
    }
    return '';
  }
}


