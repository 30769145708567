import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { COLORS_BTN } from './../../enum/colors';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-ugau-input',
  template: `
    <mat-form-field
      class="ugau-input"
      [appearance]="appearance"
      floatLabel="always"
    >
      <mat-label>{{ label }}</mat-label>
      <input
        *ngIf="type !== 'textarea'"
        [type]="type"
        #input
        matInput
        [placeholder]="placeholder"
        [value]="value"
        [readonly]="readOnly"
        (click)="handleClick()"
        (blur)="handleBlur()"
      />
      <textarea
        *ngIf="type === 'textarea'"
        #input
        matInput
        [placeholder]="placeholder"
        [value]="value"
        [readonly]="readOnly"
        [rows]="rows"
        (click)="handleClick()"
        (blur)="handleBlur()"
      ></textarea>
      <mat-icon
        *ngIf="iconLabel && !isSearchBar"
        matSuffix
        (click)="handleClick()"
        >{{ iconLabel }}</mat-icon
      >
      <mat-icon
        *ngIf="iconLabel && isSearchBar"
        matSuffix
        (click)="iconClick()"
        >{{ iconLabel }}</mat-icon
      >
      <span *ngIf="suffixText" matSuffix>{{ suffixText }}</span>
    </mat-form-field>
  `,
  styleUrls: ['./ugau-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, NgIf, MatInputModule, MatIconModule],
})
export class UgauInputComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value!: string | number | null;
  @Input() iconLabel!: string | null;
  @Input() suffixText!: string | null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readOnly: boolean = false;
  @Input() isSearchBar: boolean = false;
  @Input() rows: number = 1;
  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';
  @Input() iconClick = this.clearInput;

  @Input() @HostBinding('class.no-sub-wrapper') disableSubWrapper: boolean =
    false;

  @Input()
  @HostBinding('class.textarea-autoheight')
  textAreaAutoHeight: boolean = false;

  @Output() inputChange = new EventEmitter<string>();
  @Output() fieldClick = new EventEmitter<string>();

  @ViewChild('input') inputRef!: ElementRef;

  @Input() timeDebounce: number = 5000;

  @Input() type: string = 'text';

  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    fromEvent<any>(this.inputRef.nativeElement, 'input')
      .pipe(
        map((event) => event.target.value),
        distinctUntilChanged(),
        debounceTime(this.timeDebounce), // Utiliser debounceTime seulement ici
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
      .subscribe((value) => {
        this.inputChange.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Si le type change et est textarea, on reset la hauteur par rapport au contenu
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (changes.type && changes.type.currentValue === 'textarea') {
      setTimeout(() => {
        this.inputRef.nativeElement.style.height = 'auto';
        if (this.inputRef.nativeElement.scrollHeight)
          this.inputRef.nativeElement.style.height =
            this.inputRef.nativeElement.scrollHeight + 'px';
      }, 0);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearInput() {
    return this.inputChange.emit('');
  }

  handleClick() {
    this.fieldClick.emit(this.label);
  }

  handleBlur() {
    this.inputChange.emit(this.inputRef.nativeElement.value);
  }
}
