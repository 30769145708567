import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TransformUnitPipe } from '../../pipes/transform-unit.pipe';
import { FormatNumberPipe } from '../../pipes/format-number.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  PAGE_SIZE_OPTIONS,
  PAGE_CHANGED,
  PAGE_SIZE,
} from '../paginator-options';

@Component({
  selector: 'app-ugau-statistique-list',
  templateUrl: './statistique-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [
    `
      .td-image {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 40px !important;
          width: auto;
        }
      }
      .th-emplacement,
      .td-emplacement {
        text-align: center;
      }
      .th-count,
      .td-count {
        text-align: center;
      }
      :host.small {
        .mat-mdc-header-cell {
          height: auto;
          padding-left: 0px;
          padding-right: 0px;
        }
        .mat-mdc-cell {
          height: auto;
          padding-left: 0px;
          padding-right: 0px;
        }

        .mat-column-total_price,
        .mat-column-unit_price {
          display: none; /* Masquer certaines colonnes sur petits conteneurs */
        }

        table {
          table-layout: fixed; /* Distribuer l'espace équitablement entre les colonnes */
        }
      }
    `,
  ],
  imports: [
    FormatNumberPipe,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NgIf,
    ScrollingModule,
    TransformUnitPipe,
    UgauButtonComponent,
    MatPaginatorModule,
  ],
})
export class StatistiqueListComponent {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS();
  PAGE_CHANGED = PAGE_CHANGED;
  PAGE_SIZE = PAGE_SIZE();

  private destroyRef = inject(DestroyRef);
  private elRef = inject(ElementRef);

  dataSource = new MatTableDataSource<any>();
  private previousWidth!: number;

  public _confkey!: string;
  @Input() set confkey(value: string) {
    this._confkey = value;
    this.updateDataSource(this._value); // Appel de la méthode de mise à jour du dataSource
  }

  @Input() open: any;
  @Input() elementsStr = $localize`éléments`;
  @Input() showPrices: boolean = false;

  public _value!: any;
  @Input() set value(value: any) {
    this._value = value;
    this.updateDataSource(value);
  }

  displayedColumns: string[] = [];
  colonnesWithPrices = [
    'image',
    'product',
    'nb_emplacement',
    'count',
    'comment',
    'unit_price',
    'total_price',
    'id_emplacement',
  ];
  colonnesWithoutPrices = [
    'image',
    'product',
    'nb_emplacement',
    'count',
    'comment',
    'id_emplacement',
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() showConcernedEmplacement = new EventEmitter<string[]>();
  @Output() showConcernedComposants = new EventEmitter<string[]>();

  private updateDataSourceCallSubject = new BehaviorSubject<any>(null);
  updateDataSourceCall$ = this.updateDataSourceCallSubject.asObservable();

  @HostBinding('class.small') isSmall = false;

  constructor() {
    this.updateDataSourceCall$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe((value) => {
        this.updateDatas(value);
      });
  }

  private updateDataSource(value: any) {
    this.updateDataSourceCallSubject.next(value);
  }

  private updateDatas(value: any) {
    const datas = value?.[this._confkey]?.list || [];
    if (datas) {
      this.dataSource.data = datas;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource._updateChangeSubscription();
    }

    // Si la largeur a changé, ajuster le layout
    const currentWidth = this.elRef.nativeElement.getBoundingClientRect().width;
    if (currentWidth !== this.previousWidth) {
      if (currentWidth < 600) {
        this.isSmall = true;
      } else {
        this.isSmall = false;
      }
      this.previousWidth = currentWidth;
    }

    // On vérifie que les prix sont "utiles" (= ne sont pas 0)
    const isPriceUsefull = datas.some((el: any) => {
      return el.unit_price !== 0 && el.total_price !== 0;
    });

    if (this.showPrices && !isPriceUsefull) {
      this.displayedColumns = this.colonnesWithoutPrices;
      return;
    }

    this.displayedColumns = this.showPrices
      ? this.colonnesWithPrices
      : this.colonnesWithoutPrices;
  }

  doClick(el: any) {
    this.showConcernedEmplacement.emit(el.id_emplacement);
    this.showConcernedComposants.emit(el.ids_composant);
  }
}
