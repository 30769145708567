import { groupeDocType } from '../../db/schemas/groupe.schema';

export function transformGroupesToUsers(groupes: groupeDocType[]): any[] {
  return groupes.reduce((acc: any[], groupe) => {
    // Map through each groupe's user_groupes
    groupe.user_groupes.forEach((userGroupe) => {
      if (!userGroupe.user) return;
      // Extract user information
      const { id, email, displayName } = userGroupe.user;
      // Find if the user already exists in the accumulated list
      let user = acc.find((u) => u.id === id);

      if (!user) {
        user = {
          id,
          displayName,
          email,
          groupes: [],
        };
        user.groupes.push({
          groupe_id: groupe.id,
          groupe: groupe.libelle,
          role: userGroupe.role,
          user_groupe_id: userGroupe.id,
        });
        acc.push(user);
      }

      const group = user.groupes.find((g: any) => g.groupe_id === groupe.id);
      if (!group)
        user.groupes.push({
          groupe_id: groupe.id,
          groupe: groupe.libelle,
          role: userGroupe.role,
          user_groupe_id: userGroupe.id,
        });
    });

    return acc;
  }, []);
}
