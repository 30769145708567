import { v4 as uuidv4 } from 'uuid';
import { EMPLACEMENT_PHOTO_TYPE } from '../enum/emplacement-photo-type';
import { PHOTO_DEFAULT } from '../enum/photo-default';
import {
  emplacementDocType,
  emplacementPhotoDocType,
} from '../db/schemas/emplacement.schema';
import { storage } from '../services/nhost';

export function getSrcPhoto(
  value: emplacementPhotoDocType | undefined,
  isOnline: boolean | unknown = true
) {
  if (!value) {
    return PHOTO_DEFAULT.DEFAULT;
  }

  if (value.data) {
    return value.data;
  }

  if (value.chemin) {
    if (Object.values(PHOTO_DEFAULT).includes(value.chemin as PHOTO_DEFAULT)) {
      return value.chemin;
    }

    // Est ce que c'est une url ? (http ou https)
    if (value.chemin.startsWith('http')) {
      if (isOnline) return value.chemin;
      return manageCacheImage(value.chemin);
    }

    // Alors c'est un id de fichier
    const image = storage.url + '/files/' + value.chemin + '?w=340&q=80';
    if (isOnline) return image;
    return manageCacheImage(image);
  }

  return PHOTO_DEFAULT.DEFAULT;
}

function manageCacheImage(image: string) {
  if (isCached(image)) {
    return image;
  }
  return PHOTO_DEFAULT.NO_INTERNET;
}

function isCached(image: string) {
  const img = document.createElement('img');
  img.src = image;
  return img.complete;
}

export function beautifyTypePhoto(type_photo: string | undefined) {
  switch (type_photo) {
    case EMPLACEMENT_PHOTO_TYPE.DOS:
      return $localize`de dos`;
    case EMPLACEMENT_PHOTO_TYPE.ENV:
      return $localize`de l'environnement`;
    case EMPLACEMENT_PHOTO_TYPE.FACE:
    default:
      return $localize`de face`;
  }
}

function initEmplacementPhoto(
  typePhoto: string,
  chemin: string,
  data: any,
  uuid: string,
  id_emplacement: string,
  created_by: string
) {
  return {
    created_at: new Date().toISOString(),
    created_by: created_by,
    deleted_at: null,
    deleted_by: null,
    chemin,
    type_photo: typePhoto,
    data,
    id: uuid,
    id_emplacement: id_emplacement,
  } as emplacementPhotoDocType;
}

export function initEmplacementPhotos(
  emplacement: emplacementDocType,
  idUser: string
) {
  const uuid1 = uuidv4(),
    uuid2 = uuidv4(),
    uuid3 = uuidv4();

  return [
    initEmplacementPhoto(
      EMPLACEMENT_PHOTO_TYPE.FACE,
      PHOTO_DEFAULT.UPLOAD_FACE,
      null,
      uuid1,
      emplacement.id,
      idUser
    ),
    initEmplacementPhoto(
      EMPLACEMENT_PHOTO_TYPE.DOS,
      PHOTO_DEFAULT.UPLOAD_DOS,
      null,
      uuid2,
      emplacement.id,
      idUser
    ),
    initEmplacementPhoto(
      EMPLACEMENT_PHOTO_TYPE.ENV,
      PHOTO_DEFAULT.UPLOAD_ENV,
      null,
      uuid3,
      emplacement.id,
      idUser
    ),
  ];
}
