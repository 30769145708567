import { Injectable } from '@angular/core';
import { DatabaseService } from '../db/database.service';
import { AuthenticationService } from './authentication.service';
import { clearLocalStorageForLogout } from './clearLocalStorageForLogout';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private db: DatabaseService,
    private auth: AuthenticationService
  ) {}

  /**
   * Logs out the user by performing the following steps:
   * 1. Removes database data.
   * 2. Clears local storage.
   * 3. Waits for a second and reloads the app.
   * 4. Logs out the user (and reload).
   *
   * @returns {Promise<void>}
   */
  async logout(): Promise<void> {
    try {
      // Remove database data
      await this.db.remove();
      // Clear local storage
      clearLocalStorageForLogout();
    } catch (error) {
      // Log the error
      console.error('Error clearing data during logout:', error);
    } finally {
      // Log out the user from Nhost
      await this.auth.logout();
    }
  }

  async logoutAll(): Promise<void> {
    try {
      // Remove database data
      await this.db.remove();
      // Clear local storage
      clearLocalStorageForLogout();
    } catch (error) {
      // Log the error
      console.error('Error clearing data during logout:', error);
    } finally {
      // Log out the user from Nhost
      await this.auth.logoutAll();
    }
  }
}
