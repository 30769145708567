import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import { DatabaseService } from '../../db/database.service';
import { combineLatest, lastValueFrom, map, take, tap } from 'rxjs';
import packageInfo from '../../../../package.json';
import { AuthenticationService } from './../../services/authentication.service';
import { ToolsService } from './../../services/tools.service';
import { UserService } from './../../services/user.service';
import { SentryService } from './../../services/sentry.service';
import { reloadApp } from './../../services/reloadApp.util';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { sleep } from './../../../common-projects/sleep';
import { DialogPromptInputComponent } from '../../components/dialogs/dialog-prompt-input/dialog-prompt-input.component';
import { GroupesStateService } from '../../state/groupes-state.service';
import { groupeDocType } from '../../db/schemas/groupe.schema';
import { registerNewUser } from './registerNewUser';
import { DialogPromptRegisterNewUserComponent } from '../../components/dialogs/dialog-prompt-register-new-user/dialog-prompt-register-new-user.component';
import { usersDocType } from './../../db/schemas/users.schema';
import { getUserID } from './../../services/nhost';
import { isValidEmail } from './isValidEmail';
import { UserStateService } from './../../state/user-state.service';
import { trackByIdFn } from './../../services/trackByFunctions.utils';
import { UgauInputComponent } from '../../components/ugau-input/ugau-input.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MyAccountUserInfoComponent } from './my-account-user-info.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UgauButtonComponent } from '../../components/ugau-button/ugau-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MyAccountStateService } from './my-account.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogPromptBoolComponent } from '../../components/dialogs/dialog-prompt-bool/dialog-prompt-bool.component';
import { LogoutButtonComponent } from '../../components/logout-button/logout-button.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.page.html',
  styles: [
    `
      :host {
        height: 100%;
        display: block;

        .account-container-toolbar {
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          z-index: 1000;
        }

        .account-container-listing {
          height: calc(100% - 64px);

          mat-tab-group {
            height: 100%;

            ::ng-deep .mat-mdc-tab-body-wrapper {
              height: 100%;
            }
          }
        }

        .btn-list button {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatToolbarModule,
    MatIconModule,
    UgauButtonComponent,
    MatTooltipModule,
    MatButtonModule,
    NgIf,
    MatTabsModule,
    MyAccountUserInfoComponent,
    MatListModule,
    MatMenuModule,
    UgauInputComponent,
    AsyncPipe,
    LogoutButtonComponent,
  ],
})
export class MyAccountPage implements OnInit {
  trackByIdFn = trackByIdFn;
  idUser = getUserID();

  userProxy: usersDocType | undefined = undefined;
  user$ = this.UserP.getUserByIdObs(this.idUser).pipe(
    tap((user) => {
      this.userProxy = user;
    })
  );

  groupes$ = combineLatest([this.GroupeP.groupes$, this.user$]).pipe(
    map(([groupes, user]) => {
      const groupIds =
        user?.user_groupes
          .map((e) => {
            return e.groupe.id;
          })
          .filter((e) => e) || [];
      return groupes.filter((e) => groupIds.includes(e.id));
    })
  );

  isFullOperator$ = this.userState.isFullOperator$;

  display$ = this.myAccountState.myAccount$.pipe(map((state) => state.display));

  version: string = packageInfo.version;
  isMobile = this.tools.isMobile();
  selectedIndex = this.myAccountState.myAccount$.pipe(
    map((state) => state.selectedIndex)
  );

  setSelectedIndex(index: number) {
    this.displayList();
    this.myAccountState.setSelectedIndex(index);
  }

  destroyRef = inject(DestroyRef);

  constructor(
    private tools: ToolsService,
    public UserP: UserService,
    public GroupeP: GroupesStateService,
    public authService: AuthenticationService,
    public db: DatabaseService,
    public dialogRef: MatDialogRef<MyAccountPage>,
    private dialog: MatDialog,
    private userState: UserStateService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private myAccountState: MyAccountStateService
  ) {}

  ngOnInit(): void {
    // On écoutera les changements de state pour fermer la fenêtre si mustClose est à true
    this.myAccountState.myAccount$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((state) => {
          if (state.mustClose) {
            this.dialogRef.close();
            this.myAccountState.resetClose();
          }
        })
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe();

    // On affiche la liste
    this.displayList();
  }

  elementSelected() {
    this.myAccountState.displayForm();
  }

  returnToList() {
    this.displayList();
  }

  displayList = () => {
    this.myAccountState.displayListing();
  };

  public dismiss() {
    this.myAccountState.close();
  }

  public async validateUserModification() {
    await sleep(1000);
    await this.UserP.upsert(this.userProxy);
    this.displayList();
  }

  async changePassword() {
    try {
      await this.authService.promptNewPassword(
        $localize`Modification du mot de passe`,
        ''
      );
      this.tools.toastError($localize`Mot de passe modifié avec succès`);
    } catch (error) {
      this.tools.toastError(
        $localize`Mot de passe non modifié (${JSON.stringify(error)})`
      );
    }
  }

  changeEmail() {
    this.promptNewEmail();
  }

  promptNewEmail() {
    const dialogRef = this.dialog.open(DialogPromptInputComponent, {
      data: {
        title: $localize`Changez votre adresse email`,
        placeholder: $localize`Nouvelle adresse email`,
      },
      hasBackdrop: false,
      width: '350px',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((result) => {
        if (result) {
          this.doEmailChange(result);
        }
      });
  }

  private async doEmailChange(email: string) {
    if (!email) return;

    const isEmaiValid = isValidEmail(email);
    if (!isEmaiValid) {
      this.tools.launchErrorAlert(
        $localize`Le format de l'adresse email est invalide`
      );
      return;
    }

    try {
      const { data, errors } = await lastValueFrom(
        this.UserP.changeEmail(this.idUser, email)
      );

      if (data.updateUser.id) {
        this.tools.launchInfoAlert(
          $localize`Adresse email modifiée ! <br><br>Rechargement de l'application en cours ...`
        );
        await this.db.getUserProvider().stop();
        return reloadApp('MyAccountPage::doEmailChange');
      } else if (errors && errors.length > 0) {
        const msg =
          'Uniqueness violation. duplicate key value violates unique constraint "accounts_email_key"';
        if (errors[0].message === msg) {
          this.tools.launchErrorAlert(
            $localize`Cette adresse email n'est pas disponible, veuillez en choisir une autre`
          );
          return;
        }

        SentryService.captureMessage(
          'Echec mutation HASURA UserP.changeEmail',
          { data: data, error: errors }
        );
        this.tools.launchErrorAlert(
          $localize`Echec lors de la modification de l'adresse email`
        );
      }
    } catch (e) {
      SentryService.captureException(
        e,
        'Echec requête HASURA UserP.changeEmail'
      );
      this.tools.launchErrorAlert(
        $localize`Echec lors de la modification de l'adresse email`
      );
    }
  }

  addUser(groupe: groupeDocType) {
    this.promptNewUser(groupe);
  }

  promptNewUser(groupe: groupeDocType) {
    const dialogRef = this.dialog.open(DialogPromptRegisterNewUserComponent, {
      data: {
        title: $localize`Ajouter un utilisateur`,
        emailPlaceholder: $localize`Adresse email`,
        passwordPlaceholder: $localize`Mot de passe`,
      },
      width: '350px',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((data: { email: string } | null) => {
        if (!data) return;

        const { email } = data;

        if (email) {
          const isEmailValid = isValidEmail(email);
          if (!isEmailValid) {
            this.tools.launchErrorAlert(
              $localize`Le format de l'adresse email est invalide`
            );
            return;
          }

          const errorMsg = $localize`Echec lors de la création de l'utilisateur, veuillez réessayer plus tard ou contacter le support`;

          // Création de l'utilisateur
          registerNewUser(email, groupe.id)
            .then((register) => {
              if (register?.error) {
                this.tools.launchErrorAlert(errorMsg);
                return;
              }

              this.db.getGroupeProvider().replicateState?.reSync();
              this.db.getUserProvider().replicateState?.reSync();
            })
            .catch((e) => {
              SentryService.captureException(
                e,
                'FAILED_REGISTER_NEW_USER_IN_GROUPE'
              );
              this.tools.launchErrorAlert(errorMsg);
            });
        }
      });
  }

  changeUserType(
    userId: string,
    groupeId: string,
    role: 'user' | 'user_created' | 'user_created_with_prices'
  ) {
    let msg = '';
    switch (role) {
      case 'user':
        msg = confirmMsgToGestionnaire;
        break;
      case 'user_created':
        msg = confirmMsgToOperator;
        break;
      case 'user_created_with_prices':
        msg = confirmMsgToOperatorWithPrices;
        break;
    }

    const dialog = this.dialog.open(DialogPromptBoolComponent, {
      width: '350px',
      data: {
        data: msg,
        title: $localize`Modification`,
        labelBtnValider: $localize`Valider`,
      },
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((bool: boolean) => {
        if (bool === false) return;

        this.doChangeUserType(userId, groupeId, role);
      });
  }

  doChangeUserType(userId: string, groupeId: string, role: string) {
    this.UserP.changeUserType(userId, groupeId, role);
  }
}

const confirmMsgToGestionnaire = $localize`Attention, en passant cet utilisateur en "Gestionnaire" celui-ci aura accès à tous les dossiers et emplacement du groupe.
  Êtes vous certain de vouloir passer cet utilisateur en "Gestionnaire" ?`;
const confirmMsgToOperator = $localize`Êtes vous certain de vouloir passer cet utilisateur en "Opérateur" ?
  (Pas d'accès aux dossiers et aux emplacement du groupe, prix masqués)`;
const confirmMsgToOperatorWithPrices = $localize`Êtes vous certain de vouloir passer cet utilisateur en "Opérateur avec prix" ?
  (Pas d'accès aux dossiers et aux emplacement du groupe, prix affichés)`;
