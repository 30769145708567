import { interventionDocType } from '../../db/schemas/intervention.schema';

export const getInterventionStatusString = (
  intervention: interventionDocType | null
): string => {
  if (!intervention) return '';
  let status = '';
  if (intervention.status === 'PREPARATION') {
    status = $localize`En étude`;
  }
  if (intervention.status === 'VALIDE') {
    status = $localize`Validé`;
  }
  if (intervention.status === 'EN_COURS') {
    status = $localize`En cours`;
  }
  if (intervention.status === 'TERMINE') {
    status = $localize`Terminé`;
  }
  if (intervention.status === 'ARCHIVE') {
    status = $localize`Archivé`;
  }
  return status;
};
