import {
  AsyncPipe,
  CommonModule,
  NgFor,
  NgTemplateOutlet,
} from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MapboxStyleDefinition,
  MapboxStyleSwitcherOptions,
} from 'mapbox-gl-style-switcher';
import { Map } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { setToLocalStorage } from '../../../utils/localstorage-utils.service';
import { MatIconModule } from '@angular/material/icon';
import { fetchWMTSCapabilities } from './WMTS_CAPABILITIES';
import { UgauInputComponent } from '../../../components/ugau-input/ugau-input.component';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-mapbox-control',
  templateUrl: './mapbox-control.component.html',
  styleUrls: ['./mapbox-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    CommonModule,
    NgFor,
    AsyncPipe,
    MatIconModule,
    NgTemplateOutlet,
    UgauInputComponent,
  ],
})
export class MapboxControlComponent implements OnInit {
  private _cd = inject(ChangeDetectorRef);

  trackByUri(index: number, style: MapboxStyleDefinition) {
    return style.uri;
  }
  @Input() map!: Map;
  @Input() styles: any[] = [];
  @Input() options?:
    | (MapboxStyleSwitcherOptions & {
        activeLayers?: string[];
        enableSearch?: boolean;
      })
    | string;

  @Output() styleChanged = new EventEmitter<string>();

  controlContainer!: HTMLElement;
  events?: any;
  defaultStyle!: string;
  activeLayers = new Set<string>();
  enableSearch = false;
  filteredStyles: any[] = [];
  searchQuery = '';

  private isModalOpenSubject$ = new BehaviorSubject(false);
  isModalOpen$ = this.isModalOpenSubject$.asObservable();

  ngOnInit() {
    if (typeof this.options === 'string') {
      this.defaultStyle = this.options;
    } else {
      this.defaultStyle = this.options?.defaultStyle ?? '';
      this.events = this.options?.eventListeners;
      if (this.options?.activeLayers) {
        for (const layer of this.options.activeLayers) {
          this.activeLayers.add(layer);
        }
      }
      this.enableSearch = this.options?.enableSearch ?? false;
    }

    // Trié par (active, title)
    this.filteredStyles = this.getSortedStyles(this.styles, this.activeLayers);
  }

  openModal() {
    this.isModalOpenSubject$.next(true);
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  closeModal() {
    this.isModalOpenSubject$.next(false);
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
  }

  handleDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.mapboxgl-ctrl-group')) {
      this.closeModal();
    }
  }

  handleStyleClick(style: MapboxStyleDefinition) {
    const styleUri = style.uri;

    if (this.activeLayers.has(styleUri)) {
      this.activeLayers.delete(styleUri);
    } else {
      this.activeLayers.add(styleUri);
    }
    // Store layer in localStorage
    const savedLayers = Array.from(this.activeLayers).map((layer) => {
      return this.styles.find((s) => s.uri === layer);
    });
    setToLocalStorage(
      'ENABLED_IGN-LAYERS_' + this.map.getContainer().id,
      savedLayers
    );
    this.styleChanged.emit(styleUri);
    this.closeModal();
    this.filteredStyles = this.getSortedStyles(this.styles, this.activeLayers);

    if (this.events?.onOpen) {
      this.events.onOpen();
    }
  }

  filterStyles(query: string) {
    this.filteredStyles = this.getSortedStyles(
      this.styles,
      this.activeLayers
    ).filter((style) => style.title.toLowerCase().includes(query));
    this._cd.detectChanges();
  }

  async loadMoreFromIgn() {
    // Load more styles from IGN
    const ignLayers = await fetchWMTSCapabilities();
    // Add new styles to the list
    this.styles = uniqBy([...this.styles, ...ignLayers], 'uri');
    this.enableSearch = true;
    this.filteredStyles = this.getSortedStyles(this.styles, this.activeLayers);
    this._cd.detectChanges();
  }

  getSortedStyles(styles: any[], activeLayers: Set<string>) {
    return styles.sort((a, b) => {
      if (activeLayers.has(a.uri) && !activeLayers.has(b.uri)) {
        return -1;
      }
      if (!activeLayers.has(a.uri) && activeLayers.has(b.uri)) {
        return 1;
      }

      if (a.style && !b.style) {
        return 1;
      }
      if (!a.style && b.style) {
        return -1;
      }

      return a.title.localeCompare(b.title);
    });
  }
}
