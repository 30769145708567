import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { getInfoEmplacement } from '../../utils/emplacement/getImgEmplacement';
import { getDesignationComposant } from '../composant/getDesignationComposant';

export function getEmplacementLibelle(
  emplacement: emplacementDocType | undefined,
  productsOrder: any
): string {
  if (!emplacement) return '';

  // Le libelle doit être composé du nom/designation du produit du 1er composant
  const infos = getInfoEmplacement(emplacement, productsOrder);
  if (
    infos.firstProductId &&
    infos.indexComposant !== undefined &&
    infos.indexComposant !== null
  ) {
    const composant = emplacement.composants[infos.indexComposant];
    const designation = getDesignationComposant(composant);

    if (designation && designation !== '') return designation;
    return composant.product?.name ?? '';
  }

  return '';
}
