<mat-tab-group>
  <mat-tab label="Réglages" i18n-label>
    <div class="manage-datas">
      <mat-list class="two-column-listing">
        <div matSubheader class="c-black">Informations sur la carte</div>
        <mat-list-item>
          <div class="d-flex w100 pl1em">
            <label i18n>Zoom actuel</label>
            <label>{{ MapP.mapGlobal?.getZoom()?.toFixed(2) }}</label>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="d-flex w100 pl1em">
            <label i18n>Latitude</label>
            <label>{{ MapP.mapGlobal?.getCenter()?.lat }}</label>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="d-flex w100 pl1em">
            <label i18n>Longitude</label>
            <label>{{ MapP.mapGlobal?.getCenter()?.lng }}</label>
          </div>
        </mat-list-item>
      </mat-list>

      <mat-list>
        <div matSubheader class="c-black">Paramètres</div>
        <div class="pr1em pl1em">
          <app-ugau-select
            [value]="defaultTagType"
            [options]="tagTypes"
            iconLabel="edit"
            (valueChange)="changeDefaultTagType($event)"
            i18n-label
            label="Type dossier par défault"
            style="color: black"
          ></app-ugau-select>
          <app-ugau-input-boolean
            [value]="defaultComposantOpened"
            (valueChange)="setDefaultComposantOpened($event)"
            i18n-label
            label="Par défaut, les composants sont ouverts"
          ></app-ugau-input-boolean>
          <app-ugau-select
            [value]="defaultFabricant"
            [options]="(fabricants$ | async) ?? []"
            iconLabel="edit"
            (valueChange)="changeDefaultFabricant($event)"
            i18n-label
            label="Fabricant par défault"
            style="color: black"
          ></app-ugau-select>
          <div class="d-flex flex-wrap mt1em">
            <app-ugau-input-boolean
              [value]="defaultCalendarSplitDays"
              (valueChange)="changeDefaultCalendarSplitDays($event)"
              i18n-label
              label="Créer un événement par jour"
            ></app-ugau-input-boolean>
            <app-ugau-input-number
              [value]="defaultStartHour"
              (valueChange)="changeDefaultStartHour($event)"
              i18n-label
              label="Heure début de journée"
              appearance="outline"
            ></app-ugau-input-number>
            <app-ugau-input-number
              [value]="defaultEndHour"
              (valueChange)="changeDefaultEndHour($event)"
              i18n-label
              label="Heure fin de journée"
              appearance="outline"
            ></app-ugau-input-number>
          </div>
        </div>
      </mat-list>

      <mat-list>
        <div matSubheader class="c-black">Actions</div>
        <mat-list-item>
          <div class="pl1em pr1em">
            <app-ugau-button
              (click)="purgeDatas()"
              iconName="refresh"
              i18n-label
              label="Recharger les données"
            ></app-ugau-button>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="pl1em pr1em">
            <app-ugau-button
              (click)="reload()"
              iconName="refresh"
              i18n-label
              label="Recharger l'application"
            ></app-ugau-button>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="pl1em pr1em">
            <app-ugau-button
              (click)="clearAllDatas()"
              iconName="delete"
              i18n-label
              label="Vider le cache"
            ></app-ugau-button>
          </div>
        </mat-list-item>
      </mat-list>

      <br />
      <app-database-status></app-database-status>
    </div>
  </mat-tab>

  <mat-tab label="Mes paramètres" i18n-label appDisableIfNotPremium>
    <div class="manage-datas">
      <mat-list class="two-column-listing">
        <div *ngIf="display === 'listing'">
          <table mat-table [dataSource]="productfieldsDuration$">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef i18n>Nom</th>
              <td mat-cell *matCellDef="let obj">
                {{ obj.productfield.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex flex-ac flex-sb">
                  <span i18n>Valeur</span>
                  <button
                    mat-icon-button
                    (click)="resetValues()"
                    i18n-matTooltip
                    matTooltip="Réinitialiser"
                  >
                    <mat-icon>refresh</mat-icon>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let obj">
                <div *ngIf="obj.selectItem">
                  <app-ugau-select
                    [options]="durations"
                    [value]="obj.selectItem"
                    (valueChange)="changeDuration($event, obj.productfield)"
                    appearance="outline"
                  ></app-ugau-select>
                </div>
                <div *ngIf="obj.inputItem">
                  <app-ugau-input
                    [value]="obj.inputItem"
                    (inputChange)="setAgeLimit($event, obj.classe)"
                    [timeDebounce]="2000"
                    appearance="outline"
                  ></app-ugau-input>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-list>
    </div>
  </mat-tab>
</mat-tab-group>
