import {
  Component,
  Input,
  Output,
  EventEmitter,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { tagDocType, tagMetaDocType } from '../../../db/schemas/tag.schema';
import { storage } from '../../../services/nhost';
import { forceDownloadFile } from '../../emplacement/emplacement-photo-full/extractAndForceDownloadFile';
import { PanelStateService } from '../panel-state';
import { TagMetasStateService } from '../../../state/tags-meta-state.service';
import { initMeta } from './initNewTagMeta';
import { ModalService } from '../../../services/modal.service';
import { TagMetaTypeType } from './TagMetaTypeType';
import { MetaFieldMenuComponent } from './meta-header-fieldmenu.component';
import { MetaFieldAccordionComponent } from './meta-accordion-field.component';
import { retrieveUploadFilename } from '../../../utils/nhost-utils';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { CalendarType } from '../tags-list/send-event-to-calendar';
import { tagGenerateEventDescription } from '../tags-list/tag-generate-event-description';
import { RoutingState } from '../../../state/routing-state.service';
import { GroupesStateService } from '../../../state/groupes-state.service';
import { UsersStateService } from '../../../state/users-state.service';
import { UserMetaState } from '../../../state/usermeta.state';
import { scheduleTagEvent } from './scheduleTagEvent';

@Component({
  selector: 'app-tag-meta',
  templateUrl: './tag-meta.component.html',
  styleUrls: ['./tag-meta.component.scss'],
  standalone: true,
  imports: [
    MetaFieldAccordionComponent,
    MetaFieldMenuComponent,
    UgauButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagMetaComponent {
  private modalService = inject(ModalService);
  private panelStateService = inject(PanelStateService);
  private tagMetasStateService = inject(TagMetasStateService);
  private routingState = inject(RoutingState);
  private usersState = inject(UsersStateService);
  private metaState = inject(UserMetaState);
  private groupesState = inject(GroupesStateService);

  @Input() tag!: tagDocType | null;

  private _metas: tagMetaDocType[] = [];
  @Input() set metas(metas: tagMetaDocType[]) {
    const hasDateDebut = metas.some((m) => m.type === 'date_debut');
    const hasDateFin = metas.some((m) => m.type === 'date_fin');
    if (!hasDateDebut) {
      metas.push(initMeta('date_debut'));
    }
    if (!hasDateFin) {
      metas.push(initMeta('date_fin'));
    }

    // Il faut set date_debut et date_fin si vide
    this._metas = metas.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
  }
  get metas() {
    return this._metas;
  }

  @Input() readonly = false;
  @Output() metaChange = new EventEmitter<tagMetaDocType[]>();

  addMetaField(type: TagMetaTypeType) {
    const meta = initMeta(type);
    this.metas.push(meta);
    this.metaChange.emit(this.metas);

    this.metas.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
    const index = this.metas.findIndex((m) => m.id === meta.id);
    this.togglePanel(index);
  }

  updateMetaField(
    meta: tagMetaDocType,
    field: keyof tagMetaDocType,
    value: string | null
  ) {
    const newMetas = this.metas.map((m) => {
      if (m.id === meta.id)
        return {
          ...m,
          [field]: value,
        };
      return m;
    });

    this.metaChange.emit(newMetas);
  }

  removeMetaField(meta: tagMetaDocType) {
    this.modalService.presentBooleanDialog(
      {
        data: $localize`Êtes vous certain de vouloir supprimer cette information ?`,
        title: $localize`Suppression information supplémentaire`,
      },
      () => {
        this.doRemoveMetaField(meta);
      }
    );
  }

  doRemoveMetaField(meta: tagMetaDocType) {
    const newMetas = [...this.metas.filter((m) => m.id !== meta.id)];
    this.metaChange.emit(newMetas);
    this.tagMetasStateService.remoteRemoveTagMeta(meta.id);
  }

  async downloadFile(fileId: string | undefined) {
    if (!fileId) return;

    const filename = await retrieveUploadFilename(fileId);
    const completeUrl = fileId.includes('http')
      ? fileId
      : storage.getPublicUrl({ fileId });

    forceDownloadFile(completeUrl, filename);
  }

  togglePanel(index: number) {
    if (!this.readonly) this.panelStateService.togglePanel('tag_metas', index);
  }

  shareToCalendar(calendarType: CalendarType): void {
    if (!this.tag) return;

    const link = this.routingState.createUrlTreeTag(this.tag.id);

    const description = tagGenerateEventDescription(
      this.tag,
      this.usersState.getUsersFromGroupes(),
      this.groupesState.getGroupes(),
      link
    );
    const shouldSplit = this.metaState.getCalendarSplitEventInDays();
    const startHour = this.metaState.getDefaultStartHour();
    const endHour = this.metaState.getDefaultEndHour();

    scheduleTagEvent(
      this.tag,
      calendarType,
      description,
      shouldSplit,
      startHour,
      endHour
    );
  }
}
