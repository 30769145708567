import {
  HostBinding,
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { COLORS_BTN } from './../../enum/colors';
import { UGAU_BUTTON_TYPE } from './UGAU_BUTTON_TYPE';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-ugau-button',
  template: `
    <a
      *ngIf="label"
      class="ugau-button"
      mat-stroked-button
      [matTooltip]="tooltipContent"
      [disabled]="isDisabled"
    >
      <mat-icon *ngIf="iconName" [color]="iconColor">{{ iconName }}</mat-icon>
      {{ label }}
    </a>
    <a
      *ngIf="!label"
      class="ugau-button"
      mat-icon-button
      [matTooltip]="tooltipContent"
      [disabled]="isDisabled"
    >
      <mat-icon *ngIf="iconName" [color]="iconColor">{{ iconName }}</mat-icon>
    </a>
  `,
  styleUrls: ['./ugau-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatTooltipModule, MatIconModule],
})
export class UgauButtonComponent {
  UGAU_BUTTON_TYPE_PROXY = UGAU_BUTTON_TYPE;

  private _label: string = 'Ok';
  private _capitalize: boolean = false;

  @Input() set label(value: string) {
    this._label = value;
    this.updateLabel();
  }
  get label(): string {
    return this._label;
  }

  @Input() set capitalize(value: boolean) {
    this._capitalize = value;
    this.updateLabel();
  }
  get capitalize(): boolean {
    return this._capitalize;
  }

  @Input() iconName!: string;
  @Input() iconColor: string = 'primary';
  @Input() tooltipContent: string = '';
  @Input() isDisabled: boolean = false;

  @Input()
  @HostBinding('attr.color')
  classColor: COLORS_BTN = 'primary';

  @Input()
  @HostBinding('attr.color-fill')
  fill: boolean = false;

  @Input()
  @HostBinding('attr.text-align')
  textAlign: 'left' | 'center' | 'right' = 'center';

  @Input()
  @HostBinding('attr.has-border')
  hasBorder: boolean = true;

  @Input()
  @HostBinding('attr.centered')
  centered: boolean = false;

  @Input()
  @HostBinding('attr.free-height')
  freeHeight: boolean = false;

  @Input()
  @HostBinding('attr.maxWidth')
  maxWidth: boolean = false;

  private updateLabel() {
    this._label = this._capitalize ? this._label.toUpperCase() : this._label;
  }
}
