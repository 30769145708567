import { SymbolLayout } from 'mapbox-gl';
import { getFromLocalStorage } from '../../utils/localstorage-utils.service';
import { ICON_MAP_BLUE } from '../../utils/emplacement/ICON_MAP_BLUE';

export function getDefaultLayoutUnclustered(): SymbolLayout {
  const idCurrentEmplacement =
    getFromLocalStorage('selected_emplacement_osmap_layers') || 'coucou';
  return {
    'icon-image': ['coalesce', ['get', 'image'], ICON_MAP_BLUE],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'icon-size': ['case', ['in', idCurrentEmplacement, ['get', 'id']], 1, 0.65],
  };
}

export function getDefaultLayoutIconLineString(): SymbolLayout {
  const idCurrentEmplacement =
    getFromLocalStorage('selected_emplacement_osmap_layers') || 'coucou';
  return {
    'symbol-placement': 'line',
    'icon-image': ['get', 'image'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'icon-size': ['case', ['in', idCurrentEmplacement, ['get', 'id']], 1, 0.65],
  };
}
