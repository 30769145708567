import { NgIf, DatePipe, NgClass, NgFor, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { stockDocType } from '../../../db/schemas/stock.schema';
import { formatFULL } from '../../../utils/date-formats';
import { ProductIdPipe } from '../../../pipes/products/productId.pipe';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { StockState } from '../stock.state';
import { MatDialog } from '@angular/material/dialog';
import { DialogPromptInputNumberComponent } from '../../dialogs/dialog-prompt-input/dialog-prompt-input-number.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { Observable, take } from 'rxjs';
import { TableComponentBase } from '../../table-base.component';
import { MatMenuModule } from '@angular/material/menu';
import {
  downloadArrayAsCSV,
  generateTimeName,
} from '../../../services/utils.service';
import { GroupesStateService } from '../../../state/groupes-state.service';
import { EmplacementIdPipe } from '../../../pipes/emplacements/emplacement.pipe';
import { EmplacementsStateService } from '../../../state/emplacements-state.service';
import { ProductFamiliesTreeState } from '../../../state/product-families-tree-state.service';
import { getProductsOrder } from '../../../utils/product/getProductsOrder';
import { getEmplacementLibelle } from '../../../utils/emplacement/getEmplacementLibelle';
import { StockHistoryComponent } from '../stock-history.component';
import { RoutingState } from '../../../state/routing-state.service';
import { trackByIdFn } from '../../../services/trackByFunctions.utils';
import { FormatNumberPipe } from '../../../pipes/format-number.pipe';
import { GroupeIdPipe } from '../../../pipes/groupes/groupe-id.pipe';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    UgauButtonComponent,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    NgClass,
    UgauInputAutocompleteComponent,
    AsyncPipe,
    StockHistoryComponent,
    ProductIdPipe,
    GroupeIdPipe,
    EmplacementIdPipe,
    DatePipe,
    FormatNumberPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockListComponent extends TableComponentBase<stockDocType> {
  key = 'stock';
  private dialog = inject(MatDialog);
  private stockState = inject(StockState);
  private groupeState = inject(GroupesStateService);
  private treeState = inject(ProductFamiliesTreeState);
  private productsOrder = getProductsOrder(this.treeState.getOriginalTree());
  private emplacementsState = inject(EmplacementsStateService);
  private routingState = inject(RoutingState);

  trackByName = (index: number, item: any) => item.name;
  trackById = trackByIdFn;

  formatFULL = formatFULL;

  @Input() data$!: Observable<stockDocType[]>;
  _type!: 'stock' | 'materiel';
  @Input() set type(type: 'stock' | 'materiel') {
    this._type = type;
  }
  groupes$ = this.groupeState.groupes$;

  displayedColumns: string[] = this.isMobile
    ? ['meta', 'quantity', 'actions']
    : [
        'meta',
        'quantity',
        'groupe_id',
        'emplacement',
        'price',
        'total',
        'supplier',
        'created_at',
        'updated_at',
        'actions',
      ];
  exceptSearchForColumns: string[] = ['created_at', 'updated_at', 'actions'];

  columnDefs: Record<string, { title: string }> = {
    meta: {
      title: $localize`Produit`,
    },
    quantity: {
      title: $localize`Quantité`,
    },
    emplacement: {
      title: $localize`Bâtiment`,
    },
    price: {
      title: $localize`Prix`,
    },
    total: {
      title: $localize`Total`,
    },
    supplier: {
      title: $localize`Fournisseur`,
    },
    created_at: {
      title: $localize`Créé le`,
    },
    updated_at: {
      title: $localize`Modifié le`,
    },
    groupe_id: {
      title: $localize`Groupe`,
    },
  };

  @Output() addNew = new EventEmitter<string>();
  @Output() edit = new EventEmitter<string>();

  dataModifier(data: stockDocType): stockDocType {
    return {
      ...data,
      total: data.quantity * data.price,
    } as stockDocType;
  }

  addNewStock(groupeId: string) {
    this.addNew.emit(groupeId);
  }

  editStock(stock: stockDocType) {
    this.edit.emit(stock.id);
  }

  doInputStock(stock: stockDocType) {
    const diag = this.dialog.open(DialogPromptInputNumberComponent, {
      data: {
        title: $localize`Entrée de stock`,
        placeholder: $localize`Quantité`,
      },
    });

    diag
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((result) => {
        if (result) {
          const num = parseInt(result, 10);
          if (isNaN(num)) {
            this.tools.launchErrorAlert(
              $localize`La quantité doit être un nombre`
            );
            return;
          }

          this.stockState.upsertStock({
            ...stock,
            quantity: stock.quantity + num,
          });
        }
      });
  }

  doOutputStock(stock: stockDocType) {
    const diag = this.dialog.open(DialogPromptInputNumberComponent, {
      data: {
        title: $localize`Sortie de stock`,
        placeholder: $localize`Quantité`,
      },
    });

    diag
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((result) => {
        if (result) {
          const num = parseInt(result, 10);
          if (isNaN(num)) {
            this.tools.launchErrorAlert(
              $localize`La quantité doit être un nombre`
            );
            return;
          }

          this.stockState.upsertStock({
            ...stock,
            quantity: stock.quantity - num,
          });
        }
      });
  }

  customSortingDataAccessor(data: stockDocType, sortHeaderId: string) {
    switch (sortHeaderId) {
      case 'emplacement':
        return this.getEmplacementLibelle(data.emplacement_id);
      case 'meta':
        return data.meta.designation;
      case 'groupe_id':
        return this.groupeState
          .getGroupes()
          .find((g) => g.id === data.groupe_id)?.libelle;
      default:
        return (data as any)[sortHeaderId];
    }
  }

  private getEmplacementLibelle(emplacementId: string | undefined) {
    const emplacement =
      this.emplacementsState.getEmplacementById(emplacementId);
    return getEmplacementLibelle(emplacement, this.productsOrder);
  }

  customCreateFilter(
    data: stockDocType,
    key: string,
    value: string
  ): boolean | undefined {
    if (key === 'emplacement' && value) {
      return this.getEmplacementLibelle(data.emplacement_id)
        .toLowerCase()
        .includes(value.toLowerCase());
    }
    if (key === 'groupe_id' && value) {
      return this.groupeState
        .getGroupes()
        .find((g) => g.id === data.groupe_id)
        ?.libelle.toLowerCase()
        .includes(value.toLowerCase());
    }
    if (key === 'meta' && value) {
      return data.meta.designation.toLowerCase().includes(value.toLowerCase());
    }
    return undefined;
  }

  customGetFilterObject(data: stockDocType, key: string): string | undefined {
    if (key === 'groupe_id') {
      return this.groupeState.getGroupes().find((g) => g.id === data.groupe_id)
        ?.libelle;
    }
    if (key === 'emplacement') {
      return this.getEmplacementLibelle(data.emplacement_id);
    }
    if (key === 'meta') {
      return data.meta.designation;
    }
    return undefined;
  }

  export() {
    const i18nKeys = {
      designation: $localize`Produit`,
      quantity: $localize`Quantité`,
      depot: $localize`Bâtiment`,
      price: $localize`Prix`,
      total: $localize`Total`,
      supplier: $localize`Fournisseur`,
      created_at: $localize`Créé le`,
      updated_at: $localize`Modifié le`,
    };
    const data = this.dataSource.filteredData;
    const csvArray = data.map((el) => {
      return {
        [i18nKeys.designation]: el.meta.designation,
        [i18nKeys.quantity]: el.quantity,
        [i18nKeys.depot]: this.getEmplacementLibelle(el.emplacement_id),
        [i18nKeys.price]: el.price,
        [i18nKeys.total]: el.quantity * el.price,
        [i18nKeys.supplier]: el.supplier,
        [i18nKeys.created_at]: el.created_at,
        [i18nKeys.updated_at]: el.updated_at,
      };
    });

    const filename = generateTimeName('export_stock');
    downloadArrayAsCSV(csvArray, filename);
  }

  navigateBatiment(id: string) {
    this.routingState.navigateBatiment(id);
  }
}
