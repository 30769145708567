export enum PRODUCTFIELD_TYPE {
  TEXT = 'text',
  MULTILINE = 'multiline',
  NUMBER = 'number',
  DATE_FABRICATION = 'date_fabrication',
  DATE = 'date',
  DATE_YEAR = 'year',
  DURATION = 'duration',
  DURATION_START = 'duration_start',
  MAKER = 'maker',
  UPLOAD = 'upload',
  UPLOAD_MULTIPLE = 'upload_multiple',
  AUTOCOMPLETE = 'autocomplete',
  AUTOCOMPLETE_MULTILINE = 'autocomplete_multiline',
  SELECT = 'select',
  SELECT_AND_COLOR = 'select_and_color',
  DESIGNATION = 'designation',
  GEOLOCATION = 'geolocation',
  GEOLOCATION_BATIMENT = 'geolocation_batiment',
  DEPOT = 'depot', // ==== devrait être BATIMENT 'batiment' mais OSEF
  USER = 'user',
  USER_MULTIPLE = 'user_multiple',
  STOCK = 'stock',
  STOCK_MULTIPLE = 'stock_multiple',
  MATERIEL = 'materiel',
  MATERIEL_MULTIPLE = 'materiel_multiple',
  CUSTOM_PRICE = 'custom_price',
}

export type ProductFieldType = `${PRODUCTFIELD_TYPE}`;
