import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { stockDocType } from '../../../db/schemas/stock.schema';
import { ProductIdPipe } from '../../../pipes/products/productId.pipe';
import { UgauButtonAnnulerValiderContainer } from '../../ugau-button-annuler-valider/ugau-button-annuler-valider.container';
import { UgauDateComponent } from '../../ugau-date/ugau-date.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { UgauInputNumberComponent } from '../../ugau-input-number/ugau-input-number.component';
import { UgauInputComponent } from '../../ugau-input/ugau-input.component';
import { StockState } from '../stock.state';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { ModalService } from '../../../services/modal.service';
import { ToolsService } from '../../../services/tools.service';
import { UgauSelectEmplacementStockContainer } from './ugau-select-batiment.component';
import { MetaFieldMenuComponent } from '../../tags/tag-meta/meta-header-fieldmenu.component';
import { MetaFieldAccordionComponent } from '../../tags/tag-meta/meta-accordion-field.component';
import { tagMetaDocType } from '../../../db/schemas/tag.schema';
import { initMeta } from '../../tags/tag-meta/initNewTagMeta';
import { GroupeIdPipe } from '../../../pipes/groupes/groupe-id.pipe';
@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    GroupeIdPipe,
    MatCardModule,
    NgIf,
    UgauDateComponent,
    UgauInputAutocompleteComponent,
    UgauInputComponent,
    UgauInputNumberComponent,
    ProductIdPipe,
    UgauButtonAnnulerValiderContainer,
    UgauButtonComponent,
    UgauSelectEmplacementStockContainer,
    MetaFieldMenuComponent,
    MetaFieldAccordionComponent,
  ],
})
export class StockFormComponent {
  private stockState = inject(StockState);
  private modalService = inject(ModalService);
  private tools = inject(ToolsService);

  @Input() stock: stockDocType | null = null;
  @Input() type!: 'stock' | 'materiel';
  @Output() submitStock = new EventEmitter<stockDocType>();

  optionsDesignation$ = this.stockState.optionsDesignation$;
  optionsSupplier$ = this.stockState.optionsSupplier$;

  updateMetaField(
    meta: tagMetaDocType,
    field: keyof tagMetaDocType,
    value: string | null
  ) {
    if (!this.stock) return;

    const oldMeta = this.stock?.meta || { metas: [] };
    const metas: any[] = oldMeta.metas || [];
    const index = metas.findIndex((m) => m.id === meta.id);
    if (index === -1) {
      return;
    }
    const newMeta = { ...meta, [field]: value };
    metas[index] = newMeta;
    const newStock: stockDocType = {
      ...this.stock,
      meta: { ...oldMeta, metas },
    };
    this.stockState.setNewStock(newStock);
  }

  addMetaField($event: string) {
    if (!this.stock) return;

    const meta = initMeta($event);
    const oldMeta = this.stock?.meta || { metas: [] };
    const metas: any[] = oldMeta.metas || [];
    metas.push(meta);
    const newStock: stockDocType = {
      ...this.stock,
      meta: { ...oldMeta, metas },
    };
    this.stockState.setNewStock(newStock);
  }

  removeMetaField($event: tagMetaDocType) {
    if (!this.stock) return;

    const oldMeta = this.stock?.meta || { metas: [] };
    const metas: any[] = oldMeta.metas || [];
    const newMetas = metas.filter((m) => m.id !== $event.id);
    const newStock: stockDocType = {
      ...this.stock,
      meta: { ...oldMeta, metas: newMetas },
    };
    this.stockState.setNewStock(newStock);
  }

  onUpdateStock = (key: string, value: any) => {
    if (this.stock) {
      this.stockState.setNewStock({ ...this.stock, [key]: value });
    }
  };

  onUpdateDesignationStock = (designation: string) => {
    if (this.stock) {
      this.stockState.setNewStock({
        ...this.stock,
        meta: { ...this.stock.meta, designation },
      });
    }
  };

  onCancel = () => {
    this.goToList();
  };

  onValid = async () => {
    if (!this.stock) {
      return;
    }

    if (this.stock.groupe_id === '' || !this.stock.groupe_id) {
      console.log('Groupe id is empty');
      return;
    }

    await this.stockState.upsertStock(this.stock);
    this.goToList();
  };

  deleteStock(stock: stockDocType) {
    if (stock.quantity !== 0) {
      if (this.type === 'materiel') {
        this.tools.toastError(
          $localize`Impossible de supprimer un matériel qui n'est pas vide`
        );
        return;
      }
      this.tools.toastError(
        $localize`Impossible de supprimer un stock qui n'est pas vide`
      );
      return;
    }
    this.modalService.presentBooleanDialog(
      {
        data: $localize`Êtes vous certain de vouloir supprimer cet élément ?`,
        title:
          this.type === 'stock'
            ? $localize`Suppression élément du stock`
            : $localize`Suppression élément du matériel`,
      },
      () => {
        this.doDeleteStock(stock);
      }
    );
  }

  async doDeleteStock(stock: stockDocType) {
    await this.stockState.deleteStock(stock);
    this.goToList();
  }

  private goToList() {
    if (this.type === 'materiel') {
      this.stockState.navigateMateriel();
      return;
    }
    this.stockState.navigateStock();
  }
}
