/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from '@angular/core';
import { ToolsService } from './tools.service';
import { MatDialog } from '@angular/material/dialog';
import { MyAccountPage } from '../pages/my-account/my-account.page';
import { ParametresPage } from '../pages/parametres/parametres.page';
import { DialogPromptBoolComponent } from '../components/dialogs/dialog-prompt-bool/dialog-prompt-bool.component';
import { AppStockComponent } from '../components/stock/stock.component';
import { InterventionsPage, TagsPage } from '../components/tags/tags.page';
import { MyAccountGroupsPage } from '../pages/my-account-groups/my-account-groups.page';
import { AppBatimentComponent } from '../components/batiment/batiment.component';
import { AppMaterielComponent } from '../components/batiment/materiel.component';
import { AppModaleComponent } from '../components/dialogs/app-modale.component';
import { getCustomIcon } from '../pipes/custom-icon.pipe';
import { take } from 'rxjs/operators';
import { ProductFamiliesTreeState } from '../state/product-families-tree-state.service';

interface DialogData {
  data: string;
  title: string;
  labelBtnValider?: string;
  colorValid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private isMobile = inject(ToolsService).isMobile();
  private dialog = inject(MatDialog);
  private productTreeState = inject(ProductFamiliesTreeState);

  private modale!: AppModaleComponent;

  setModaleComponent(modale: AppModaleComponent) {
    this.modale = modale;
  }

  getParams(forceFullScreen: boolean) {
    let isFullScreen = this.isMobile || window.innerWidth < 1281;
    if (forceFullScreen) {
      isFullScreen = true;
    }

    const width = isFullScreen ? '100vw' : '80vw';
    const height = isFullScreen ? 'var(--100vh-wt-top)' : '80%';

    return {
      hasBackdrop: !this.isMobile,
      width,
      height,
      ...(isFullScreen && {
        maxHeight: 'var(--100vh-wt-top)',
        maxWidth: '100vw',
      }),
      autoFocus: false,
      restoreFocus: false,
    };
  }

  private createModalWithHeader({
    el,
    title,
    icon,
    iconImg,
    inputData,
  }: {
    el: any;
    title: string;
    icon?: string;
    iconImg?: string;
    inputData?: any;
  }) {
    const params = this.getParams(false);
    this.modale.openModale(el, {
      title,
      icon,
      iconImg,
      ...inputData,
      width: params.width,
      height: params.height,
      ...(params.maxHeight && { maxHeight: params.maxHeight }),
      ...(params.maxWidth && { maxWidth: params.maxWidth }),
    });
  }

  closeModalWithHeader() {
    this.modale.closeModale();
  }

  createDialog(
    el: any,
    data: any,
    successCallback: () => void = () => {}, // Optionnel
    cancelCallback: () => void = () => {} // Optionnel
  ) {
    const dialog = this.dialog.open(el, {
      width: '350px',
      data,
    });
    dialog
      .afterClosed()
      .pipe(take(1))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- take(1) is used
      .subscribe((bool: boolean) => {
        if (bool) {
          successCallback();
          return;
        }
        cancelCallback();
      });
  }

  presentBooleanDialog(
    data: DialogData,
    successCallback: () => void,
    cancelCallback: () => void = () => {}
  ) {
    this.createDialog(
      DialogPromptBoolComponent,
      data,
      successCallback,
      cancelCallback
    );
  }

  presentCurrentUserGroupsModal() {
    this.createModalWithHeader({
      el: MyAccountGroupsPage,
      title: 'Mes équipes et collaborateurs',
      icon: 'groups',
      iconImg: undefined,
      inputData: {},
    });
  }
  presentCurrentUserModal() {
    this.createModalWithHeader({
      el: MyAccountPage,
      title: 'Mon compte',
      icon: 'account_circle',
    });
  }
  presentTagModal() {
    this.createModalWithHeader({
      el: TagsPage,
      title: 'Mes dossiers',
      icon: 'folder',
      inputData: {
        type: 'tag',
      },
    });
  }
  presentInterventionModal() {
    this.createModalWithHeader({
      el: InterventionsPage,
      title: 'Mes interventions',
      icon: 'engineering',
      inputData: {
        type: 'intervention',
      },
    });
  }
  presentParametreModal() {
    this.createModalWithHeader({
      el: ParametresPage,
      title: 'Mes paramètres',
      icon: 'settings',
    });
  }
  presentStockModal() {
    this.createModalWithHeader({
      el: AppStockComponent,
      title: 'Mon stock',
      icon: 'inventory',
      inputData: {
        type: 'stock',
      },
    });
  }
  presentMaterielModal() {
    const icon = getCustomIcon('materiel', this.productTreeState);
    this.createModalWithHeader({
      el: AppMaterielComponent,
      title: 'Mon matériel',
      ...(icon === '' ? { icon: 'receipt_long' } : { iconImg: icon }),
    });
  }
  presentBatimentModal() {
    const icon = getCustomIcon('batiment', this.productTreeState);
    this.createModalWithHeader({
      el: AppBatimentComponent,
      title: 'Mes batiments',
      ...(icon === '' ? { icon: 'store' } : { iconImg: icon }),
    });
  }
}
