import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupeProvider } from '../db/providers/groupe-provider';
import { groupeDocType } from '../db/schemas/groupe.schema';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const ABO_PREMIUM = 'premium';

@Injectable({
  providedIn: 'root',
})
export class GroupesStateService {
  private groupesSubject$: BehaviorSubject<groupeDocType[]> =
    new BehaviorSubject<groupeDocType[]>([]);

  groupes$ = this.groupesSubject$.asObservable();
  groupeIds$ = this.groupes$.pipe(map((groupes) => groupes.map((el) => el.id)));

  private destroyRef = inject(DestroyRef);

  constructor(private groupesProvider: GroupeProvider) {
    this.groupesProvider.getAll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe((value) => {
        this.groupesSubject$.next(value);
      });
  }

  getGroupes() {
    return this.groupesSubject$.getValue();
  }

  hasGroupePremium() {
    return this.groupesSubject$
      .getValue()
      .some((el) => el.abonnement === ABO_PREMIUM);
  }

  getGroupeConfig(groupeId: string): Record<string, any> {
    return this.groupesSubject$.getValue().find((el) => el.id === groupeId)
      ?.config;
  }

  getGroupesConfig() {
    return this.groupesSubject$.getValue().reduce(
      (acc, el) => {
        acc = { ...acc, ...el.config };
        return acc;
      },
      {} as Record<string, any>
    );
  }

  setGroupeConfig(groupeId: string, config: any) {
    const groupes = this.groupesSubject$.getValue();
    const groupe = groupes.find((el) => el.id === groupeId);

    const updatedGroupe = {
      ...groupe,
      config: {
        ...groupe?.config,
        ...config,
      },
    };

    return this.groupesProvider.collection.upsert(updatedGroupe);
  }
}
