import {
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
  URI_hydrographie,
} from './layersChoices';
import {
  mapRemoveSandLIgnRaster,
  loadIgnRasterSourceAndLayer,
} from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

/**
 * Adds or removes water lines on the map using Mapbox.
 *
 * @param {Map} map - The Mapbox map instance.
 * @param {boolean} [remove=false] - If true, removes the water lines; otherwise, adds them.
 * @returns {void}
 */

export function addMapWaterLines(map: Map, remove = false) {
  const sourceLib = URI_hydrographie;
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);
  const url = IGN_URL + IGN_GEO_AND_TILE;
  '&layer=HYDROGRAPHY.HYDROGRAPHY' + IGN_TILE;

  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}
