import {
  OnInit,
  OnDestroy,
  Directive,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  HostBinding,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from '../utils/localstorage-utils.service'; // Assurez-vous que l'import est correct pour vos utils
import { ToolsService } from '../services/tools.service';
import { UgauInputAutocompleteComponent } from './ugau-input-autocomplete/ugau-input-autocomplete.component';
import {
  PAGE_SIZE_OPTIONS,
  PAGE_CHANGED,
  PAGE_SIZE,
} from './paginator-options';
import { trackByNameFn } from '../services/trackByFunctions.utils';

@Directive()
export abstract class TableComponentBase<T>
  implements OnInit, OnDestroy, AfterViewInit
{
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS();
  PAGE_CHANGED = PAGE_CHANGED;
  PAGE_SIZE = PAGE_SIZE();
  trackByName = trackByNameFn;

  isMobile = this.tools.isMobile();
  showFilters = !this.isMobile;
  abstract key: string;
  abstract columnDefs: Record<string, { title: string }>;

  // Le DataSource pour le tableau Angular Material
  dataSource = new MatTableDataSource<T>();

  // Observable contenant les données du tableau, à implémenter dans les classes dérivées
  abstract data$: Observable<T[]>;

  // Liste des colonnes à afficher dans le tableau, à définir dans les classes dérivées
  abstract displayedColumns: string[];
  customColumnOrder: Record<string, number> = {};
  protected exceptSearchForColumns: string[] = [];
  protected additionnalFilters: string[] = [];

  // Les colonnes pour lesquelles le filtrage est autorisé
  filterSelectObj: {
    columnProp: string;
    options: string[];
    modelValue: string;
    order: number;
  }[] = [];

  // Filtre actuel du tableau
  filterDictionary = new Map<string, string>();

  // Stream pour détruire les abonnements lors de la destruction du composant
  protected destroy$ = new Subject<void>();

  protected sort!: MatSort;
  // ViewChild pour le paginator et le tri
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
      .subscribe((sortChange) => {
        this.storeSortPreferences(sortChange.active, sortChange.direction);
      });

    this.applyStoredSortPreferences();
  }

  @ViewChildren(UgauInputAutocompleteComponent)
  filters!: UgauInputAutocompleteComponent[];

  @HostBinding('class.is-mobile') classIsMobile = this.isMobile;

  constructor(protected tools: ToolsService) {}

  dataModifier(data: T): T {
    return data;
  }

  ngOnInit(): void {
    // S'abonner aux données et mettre à jour le DataSource
    this.data$
      .pipe(takeUntil(this.destroy$))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
      .subscribe((data) => {
        this.dataSource.data = data.map((item) => this.dataModifier(item));
        // Met à jour les options de filtre
        this.updateFilterOptions(data);
      });

    // Appliquer le tri et les filtres
    this.dataSource.filterPredicate = this.createFilter();

    // Charger les filtres depuis le localStorage
    this.loadStoredFilters();
  }

  ngAfterViewInit(): void {
    // Associer le paginator et le tri au DataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.createFilter();
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) =>
      this.sortingDataAccessor(data, sortHeaderId);

    // Abonnement aux changements de tri pour stocker les préférences
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntil is used
      .subscribe((sortChange) => {
        this.storeSortPreferences(sortChange.active, sortChange.direction);
      });

    this.updateFilterOptions(this.dataSource.data);

    const storedFilters = getFromLocalStorage(`filters_${this.key}`, true);

    if (storedFilters.length > 0) {
      const filters = new Map<string, any>(storedFilters);
      filters.forEach((value, key) => {
        this.filterDictionary.set(key, value);
        const filterObj = this.filterSelectObj.find(
          (obj: { columnProp: string }) => obj.columnProp === key
        );
        if (filterObj) {
          filterObj.modelValue = value;
        }
      });
      this.dataSource.filter = JSON.stringify(storedFilters);
    }
  }

  // Mise à jour des options de filtre en appelant getFilterObject
  private updateFilterOptions(data: T[]): void {
    this.filterSelectObj = [
      ...this.displayedColumns,
      ...this.additionnalFilters,
    ]
      .filter((el) => !this.exceptSearchForColumns.includes(el))
      .map((col) => ({
        columnProp: col,
        options: this.getFilterObject(data, col),
        modelValue: '',
        order: this.getColumnOrder(col),
      }));
    this.filterSelectObj.sort((a, b) => a.order - b.order);
  }

  getColumnOrder(column: string): number {
    return this.customColumnOrder[column] || 999;
  }

  // Méthode de base pour le tri, peut être étendue par les classes filles
  protected sortingDataAccessor(data: T, sortHeaderId: string): any {
    // Appelle la méthode de tri personnalisée des classes filles
    const customSort = this.customSortingDataAccessor(data, sortHeaderId);
    // Si une valeur spécifique est retournée par la classe fille, l'utiliser
    if (customSort !== undefined) {
      return customSort;
    }

    // Logique de tri par défaut
    return this.defaultSortingDataAccessor(data, sortHeaderId);
  }

  // Méthode de tri par défaut dans la classe abstraite
  protected defaultSortingDataAccessor(data: T, sortHeaderId: string): any {
    if (
      sortHeaderId === 'created_at' ||
      sortHeaderId === 'updated_at' ||
      sortHeaderId === 'deleted_at'
    ) {
      return new Date((data as any)[sortHeaderId]).getTime();
    }
    return (data as any)[sortHeaderId];
  }

  // Méthode vide dans la classe de base, à étendre dans les classes filles
  abstract customSortingDataAccessor(data: T, sortHeaderId: string): any;

  // Méthode de filtrage de base combinée avec des règles supplémentaires
  protected createFilter() {
    return (data: T, filterString: string): boolean => {
      const searchTerms = JSON.parse(filterString);
      let isMatch = true;

      // Parcours des termes de recherche pour le filtrage
      for (const [key, value] of searchTerms) {
        // Appel de customCreateFilter pour des règles supplémentaires
        const customMatch = this.customCreateFilter(data, key, value);

        // Si customCreateFilter retourne une valeur booléenne (true/false), on l'utilise
        if (customMatch !== undefined) {
          isMatch = customMatch;
        } else {
          // Si customCreateFilter retourne undefined, on applique la logique par défaut
          const dataValue = (data as any)[key]?.toString() || '';
          isMatch = dataValue
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }

        // Arrête le filtrage si un critère ne correspond pas
        if (!isMatch) break;
      }

      return isMatch;
    };
  }

  // Méthode vide dans la classe de base, à étendre dans les classes dérivées
  abstract customCreateFilter(
    data: T,
    key: string,
    value: string
  ): boolean | undefined;

  // Méthode pour extraire des options uniques pour chaque colonne filtrable
  // Méthode pour obtenir des objets de filtre combinée avec des règles supplémentaires
  private getFilterObject(data: T[], key: string): string[] {
    const uniqChk = new Set<string>();
    data.forEach((item: any) => {
      const custom = this.customGetFilterObject(item, key);
      if (custom === undefined) {
        if (item[key] && !uniqChk.has(item[key].toString())) {
          uniqChk.add(item[key].toString());
        }
      } else if (!Array.isArray(custom)) {
        if (!uniqChk.has(custom)) {
          uniqChk.add(custom);
        }
      } else {
        custom.forEach((el) => {
          if (!uniqChk.has(el)) {
            uniqChk.add(el);
          }
        });
      }
    });

    return Array.from(uniqChk).sort();
  }

  // Méthode vide dans la classe de base, à étendre dans les classes filles pour la personnalisation
  abstract customGetFilterObject(
    data: T,
    key: string
  ): string[] | string | undefined;

  // Méthode pour gérer le changement de filtre
  filterChange(filter: { columnProp: string }, value: string) {
    if (value) {
      this.filterDictionary.set(filter.columnProp, value);
    } else {
      this.filterDictionary.delete(filter.columnProp);
    }
    const json = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.dataSource.filter = json;
    setToLocalStorage(`filters_${this.key}`, json);
  }

  // Réinitialise tous les filtres appliqués
  resetFilters() {
    this.filterDictionary.clear();
    this.filterSelectObj.forEach((filter) => (filter.modelValue = ''));
    this.dataSource.filter = '';
    removeFromLocalStorage(`filters_${this.key}`);
  }

  // Charge les filtres depuis le localStorage
  loadStoredFilters() {
    const storedFilters = getFromLocalStorage(`filters_${this.key}`, true);
    if (storedFilters.length > 0) {
      const filters = new Map<string, any>(storedFilters);
      filters.forEach((value, key) => {
        this.filterDictionary.set(key, value);
        const filterObj = this.filterSelectObj.find(
          (obj: { columnProp: string }) => obj.columnProp === key
        );
        if (filterObj) {
          filterObj.modelValue = value;
        }
      });
      this.dataSource.filter = JSON.stringify(storedFilters);
    }
  }

  // Stocke les préférences de tri dans le localStorage
  storeSortPreferences(sortBy: string, direction: 'asc' | 'desc' | ''): void {
    const sortPreferences = { sortBy, direction };
    setToLocalStorage(`sortPreferences_${this.key}`, sortPreferences);
  }

  // Applique les préférences de tri stockées
  applyStoredSortPreferences(): void {
    const storedPreferences = getFromLocalStorage(
      `sortPreferences_${this.key}`,
      true
    );
    if (storedPreferences) {
      const { sortBy, direction } = storedPreferences;
      this.sort.active = sortBy;
      this.sort.direction = direction as 'asc' | 'desc';
      this.sort.sortChange.emit({ active: sortBy, direction });
    }
  }

  ngOnDestroy(): void {
    // Compléter le stream pour nettoyer les abonnements
    this.destroy$.next();
    this.destroy$.complete();
  }
}
