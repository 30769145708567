import { environment } from './../../../environments/environment';

export async function registerNewUser(email: string, groupe_id: string) {
  return await fetch(environment.FRONT_URL + '.netlify/functions/create-user', {
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ email, groupe_id }),
    method: 'POST',
    mode: 'cors',
  }).then((e) => {
    return e.json();
  });
}

export async function deleteUserGroupeAndTags(
  user_groupe_id: string,
  user_id: string,
  groupe_id: string
): Promise<{ data: any; error: any }> {
  try {
    const response = await fetch(
      environment.FRONT_URL + '.netlify/functions/remove-user',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_groupe_id, user_id, groupe_id }),
        mode: 'cors',
      }
    );

    // Check if the response was successful
    if (!response.ok) {
      // You can throw an error or create a custom error object depending on your error handling strategy
      throw new Error('Network response was not ok');
    }

    // Await the parsing of JSON to ensure it completes before proceeding
    const { data, error } = await response.json();
    return { data, error };
  } catch (error) {
    // It's usually a good practice to log errors, but depending on your logging strategy, you might want to do it elsewhere
    console.error('Fetch error:', error);
    return { data: null, error };
  }
}

export async function checkUserAlreadyExist(email: string) {
  return await fetch(environment.FRONT_URL + '.netlify/functions/check-user', {
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ email }),
    method: 'POST',
    mode: 'cors',
  })
    .then((e) => {
      return e.json();
    })
    .catch((e) => {
      console.log('error', e);
    });
}
