import { getFromLocalStorage } from '../../utils/localstorage-utils.service';
import { LOCALSTORAGE } from '../../enum/local-storage';
import { environment } from './../../../environments/environment';

/**
 * Retrieves the initial center coordinates and zoom level for a Mapbox map.
 *
 * This function attempts to fetch the center coordinates and zoom level from
 * local storage using a key that includes the provided map ID. If no data is
 * found in local storage, it falls back to default values specified in the
 * environment configuration.
 *
 * @param idMap - The ID of the map, used to construct the local storage key.
 *                If null, a default key is used.
 * @returns An object containing the latitude, longitude, and zoom level for
 *          initializing the map.
 */
export const retrieveCenterForInit = (
  idMap: string | null = null,
  groupeConfig: any = {}
): {
  latitude: number;
  longitude: number;
  zoom: number;
} => {
  const centerFromLocalStorage = getFromLocalStorage(
    LOCALSTORAGE.MAPBOX_DEFAULT_CENTER + idMap,
    true
  );
  if (centerFromLocalStorage) {
    return centerFromLocalStorage;
  }

  if (
    groupeConfig &&
    groupeConfig['init_latitude'] &&
    groupeConfig['init_longitude']
  ) {
    return {
      latitude: groupeConfig['init_latitude'],
      longitude: groupeConfig['init_longitude'],
      zoom: groupeConfig['init_zoom'] || environment.MAPBOX_START_ZOOM,
    };
  }


  return {
    ...environment.MAPBOX_START_LOCATION,
    zoom: environment.MAPBOX_START_ZOOM,
  };
};
