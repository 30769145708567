import { AsyncPipe, NgIf } from '@angular/common';
import {
  Component,
  ViewChild,
  OnInit,
  ViewContainerRef,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Optional,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  template: `
    <mat-toolbar color="primary" class="parametre-container-toolbar">
      <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
      <img *ngIf="data.iconImg" [src]="data.iconImg" />
      <span i18n>{{ data.title }}</span>
      <span class="toolbar-spacer"></span>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>

    <div class="dialog-body">
      <ng-template #bodyContainer></ng-template>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        .parametre-container-toolbar {
          position: sticky;
          top: 0;
          z-index: 1000;
          border: #394189 1px solid;

          > mat-icon {
            min-width: 60px;
            text-align: center;
          }
          img {
            min-width: 60px;
            height: 100%;
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(233deg)
              brightness(104%) contrast(104%);
          }
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgIf,
    AsyncPipe,
  ],
})
export class DialogComponent implements OnInit {
  @ViewChild('bodyContainer', { read: ViewContainerRef, static: true })
  private bodyContainer!: ViewContainerRef;
  private currentComponentRef: any = null; // Garder une référence au composant actuel
  closeSubject$ = new Subject<void>();

  constructor(
    @Optional() dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      icon?: string;
      iconImg?: string;
      bodyComponent: any;
      inputs?: any;
    },
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadComponent(this.data.bodyComponent, this.data.inputs);
  }

  private loadComponent<T>(component: any, inputs?: Partial<T>): void {
    // Vérifie si le composant actuel est le même que celui demandé
    if (
      this.currentComponentRef &&
      this.currentComponentRef.instance instanceof component
    ) {
      this.updateComponentInputs(this.currentComponentRef.instance, inputs);
      return;
    }

    // Si c'est un nouveau composant, vider le conteneur et créer le nouveau composant
    this.bodyContainer.clear();
    const componentRef = this.bodyContainer.createComponent(component);
    this.currentComponentRef = componentRef; // Mise à jour de la référence au composant actuel

    // Mettre à jour les entrées du nouveau composant
    this.updateComponentInputs(componentRef.instance, inputs);
  }

  private updateComponentInputs<T>(instance: T, inputs?: Partial<T>): void {
    // Si des inputs sont fournis, met à jour les propriétés du composant
    if (inputs) {
      Object.keys(inputs).forEach((key) => {
        const value = inputs[key as keyof T];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (value !== undefined) {
          instance[key as keyof T] = value as T[keyof T];
        }
      });
    }
  }

  updateData(data: {
    title: string;
    icon?: string;
    iconImg?: string;
    bodyComponent: any;
    inputs?: any;
  }): void {
    this.data.title = data.title;
    this.data.icon = data.icon;
    this.data.iconImg = data.iconImg;

    if (data.bodyComponent) {
      this.loadComponent(data.bodyComponent, data.inputs);
    }
    // Trigger change detection after updating data
    this._cd.detectChanges();
  }

  close(): void {
    this.closeSubject$.next();
  }
}
