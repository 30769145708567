import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { EventsService } from './../../services/events.service';
import { MapService } from './../../services/map.service';
import { ToolsService } from './../../services/tools.service';
import { FabricantsStateService } from './../../state/fabricants-state.service';
import { getMaxAgeByClasse } from './../../utils/composant/getMaxAgeByClasse';
import { DatabaseService } from '../../db/database.service';
import { ProductfieldsStateService } from '../../state/productfields-state.service';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { DurationSelectItem } from './../../components/ugau-duration-select/duration-select-item';
import { ISelectItem } from '../../db/interfaces/select-item';
import {
  removeFromLocalStorage,
  setMaxAgeClasse,
  setToLocalStorage,
} from '../../utils/localstorage-utils.service';
import { LOCALSTORAGE } from '../../enum/local-storage';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { LogoutService } from '../../services/logout.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { deleteCacheFiles } from './../../services/clearDatabasesAndCache';
import { UgauInputComponent } from '../../components/ugau-input/ugau-input.component';
import { UgauSelectContainer } from '../../components/ugau-select/ugau-select.container';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { AsyncPipe, NgIf } from '@angular/common';
import { UgauButtonComponent } from '../../components/ugau-button/ugau-button.component';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UgauInputBooleanContainer } from '../../components/ugau-input-bool/ugau-input-boolean.container';
import {
  getDefaultFabricantAsSelectItem,
  setDefaultFabricant,
} from '../../utils/fabricant/getDefaultFabricant';
import { typeTagsChoices } from '../../enum/typeTagsChoices';
import { TagTypeSelectItem } from '../../components/ugau-select-tag-type/TagTypeSelectItem';
import { durationChoicesAsSelectItems } from '../../enum/durationsChoicesProxy';
import { durationsChoices } from '../../../common-projects/durationsChoices';
import { UserMetaState } from '../../state/usermeta.state';
import { DisableIfNotPremiumDirective } from '../../directives/disable-if-not-premium.directive';
import { DatabaseStatusComponent } from '../../components/database-status/database-status.component';
import { UgauInputNumberComponent } from '../../components/ugau-input-number/ugau-input-number.component';

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.page.html',
  styleUrls: ['./parametres.page.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatListModule,
    UgauButtonComponent,
    NgIf,
    MatTableModule,
    MatTooltipModule,
    UgauSelectContainer,
    UgauInputComponent,
    DisableIfNotPremiumDirective,
    UgauInputBooleanContainer,
    AsyncPipe,
    DatabaseStatusComponent,
    UgauInputNumberComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParametresPage {
  private usermetaState = inject(UserMetaState);

  display: string = 'listing';
  productfieldsToStorage: any = {};
  indexTuile: number = -1;
  tilesPercentDone: number = 0;

  defaultComposantOpened = this.usermetaState.getComposantOpenedByDefault();
  defaultFabricant = getDefaultFabricantAsSelectItem();
  defaultTagType = this.usermetaState.getDefaultTagTypeAsSelectItem();
  defaultStartHour = this.usermetaState.getDefaultStartHour();
  defaultEndHour = this.usermetaState.getDefaultEndHour();
  defaultCalendarSplitDays = this.usermetaState.getCalendarSplitEventInDays();

  tagTypes = typeTagsChoices.map((e) => {
    return new TagTypeSelectItem(e);
  });
  fabricants$ = this.FabP.fabricantsAsSelectItems$;
  durations = durationChoicesAsSelectItems;

  redoProductfieldsSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  productfieldsDuration$ = combineLatest([
    this.redoProductfieldsSubject$.asObservable(),
    this.productfieldsState.productfields$.pipe(
      map((e) => {
        return e
          .filter((a) => {
            return a.type === PRODUCTFIELD_TYPE.DURATION;
          })
          .map((b) => {
            const duration =
              durationsChoices.find(
                (e) => e.id.toLowerCase() === b.defaultvalue?.toLowerCase()
              ) ?? durationsChoices[0];
            return {
              productfield: b,
              selectItem: new DurationSelectItem(duration),
            };
          });
      })
    ),
  ]).pipe(
    map(([redo, productfields]) => {
      const productCustomAgeClasse = [1, 2, 3].map((e) => {
        return {
          productfield: { name: $localize`Age max classe ${e}` },
          classe: e,
          inputItem: getMaxAgeByClasse(e),
        };
      });
      return [...productCustomAgeClasse, ...productfields];
    })
  );

  displayedColumns: string[] = ['name', 'value'];

  constructor(
    private AT: ToolsService,
    private FabP: FabricantsStateService,
    private db: DatabaseService,
    private events: EventsService,
    private logoutService: LogoutService,
    private productfieldsState: ProductfieldsStateService,
    public MapP: MapService,
    public dialogRef: MatDialogRef<ParametresPage>
  ) {}

  async logout() {
    await this.logoutService.logout();
  }

  showFormParam() {
    this.displayFormParam();
  }

  displayList() {
    this.display = 'listing';
  }
  displayFormParam() {
    this.display = 'form';
  }

  dismiss() {
    this.dialogRef.close();
  }

  changeDuration(e: ISelectItem, productfield: productfieldDocType) {
    this.productfieldsToStorage[productfield.id] = e.item.id;
    if (Object.keys(this.productfieldsToStorage).length) {
      setToLocalStorage(
        LOCALSTORAGE.OVERRIDE_DURATIONS,
        this.productfieldsToStorage
      );
      this.events.publish('filtres:redo');
      this.events.publish('stat:redo');
      this.events.publish('budget:redo');
      this.redoProductfieldsSubject$.next(true);
      this.AT.toastError($localize`Modifications prises en compte`);
    }
  }

  resetValues() {
    removeFromLocalStorage([
      'age_limit_1',
      'age_limit_2',
      'age_limit_3',
      LOCALSTORAGE.OVERRIDE_DURATIONS,
    ]);
    this.redoProductfieldsSubject$.next(true);
  }

  setAgeLimit(value: number | string, classe: number) {
    if (typeof value === 'string') {
      value = parseInt(value);
    }
    switch (classe) {
      case 1:
        setMaxAgeClasse(1, value);
        break;
      case 2:
        setMaxAgeClasse(2, value);
        break;
      case 3:
        setMaxAgeClasse(3, value);
        break;
    }
    this.events.publish('filtres:redo');
    this.events.publish('stat:redo');
    this.events.publish('budget:redo');
    this.redoProductfieldsSubject$.next(true);
    this.AT.toastError($localize`Modifications prises en compte`);
  }

  setDefaultComposantOpened(value: boolean) {
    this.usermetaState.setComposantOpenedByDefault(value);
    this.defaultComposantOpened = value;
  }

  changeDefaultFabricant(e: ISelectItem) {
    setDefaultFabricant(e.item);
    this.defaultFabricant = e;
  }

  changeDefaultTagType(e: ISelectItem) {
    this.usermetaState.setDefaultTagType(e.item.id);
    this.defaultTagType = e;
  }

  changeDefaultStartHour(e: number) {
    if (e === this.defaultStartHour) return;

    this.usermetaState.setDefaultStartHour(e);
    this.defaultStartHour = e;
  }

  changeDefaultEndHour(e: number) {
    if (e === this.defaultEndHour) return;

    this.usermetaState.setDefaultEndHour(e);
    this.defaultEndHour = e;
  }

  changeDefaultCalendarSplitDays(e: boolean) {
    this.usermetaState.setCalendarSplitEventInDays(e);
    this.defaultCalendarSplitDays = e;
  }

  public async purgeDatas() {
    this.AT.toastError($localize`Traitement en cours...`);
    await this.db.purgeDatasAndReload();
  }

  public async clearAllDatas(): Promise<void> {
    this.AT.toastError($localize`Traitement en cours...`);

    try {
      // Clear local and session storage
      localStorage.clear();
      sessionStorage.clear();

      // Delete cache files
      await deleteCacheFiles();

      // Purge and reload database
      await this.purgeDatasAndReloadWithRetry(3);
    } catch (error) {
      console.error('Error clearing data:', error);
    }
  }

  private async purgeDatasAndReloadWithRetry(retries: number): Promise<void> {
    try {
      await this.db.purgeDatasAndReload();
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying database purge... (${retries} retries left)`);
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        await this.purgeDatasAndReloadWithRetry(retries - 1);
      } else {
        console.error('Failed to purge database after retries:', error);
        throw error;
      }
    }
  }

  reload() {
    this.doReload();
  }

  private doReload() {
    window.location.href = '/?skipUpdate=true';
  }
}
