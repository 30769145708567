import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { usersDocType } from '../db/schemas/users.schema';
import { GroupesStateService } from './groupes-state.service';
import { userAltType } from '../db/schemas/groupe.schema';
import { DatabaseService } from '../db/database.service';
import { userTransformedType } from '../db/schemas/UserGroupRole';
import { transformGroupesToUsers } from '../utils/user/transformGroupesToUsers';

@Injectable({
  providedIn: 'root',
})
export class UsersStateService {
  usersProvider = inject(DatabaseService).getUserProvider();
  groupesState = inject(GroupesStateService);

  users$: Observable<usersDocType[]> = this.usersProvider.getAll$;
  usersFromGroupes$ = this.groupesState.groupes$.pipe(
    map((e) => {
      return e.reduce<userAltType[]>((acc, e) => {
        if (e.user_groupes.length > 0) {
          e.user_groupes.forEach((f) => {
            const exist = acc.findIndex((g) => g.id === f.user?.id);
            if (f.user && exist === -1) acc.push(f.user);
          });
        }
        return acc;
      }, []);
    })
  );
  usersTransformed$: Observable<userTransformedType[]> =
    this.groupesState.groupes$.pipe(
      map((groupes) => transformGroupesToUsers(groupes)),
      tap((e) => console.log('usersTransformed$', e))
    );

  getUserById(userId: string) {
    return this.getUsers().find((el) => el.id === userId);
  }

  getUser$(userId: string): Observable<usersDocType | undefined> {
    return this.users$.pipe(
      map((users) => users.find((el) => el.id === userId))
    );
  }

  getUsers() {
    return this.usersProvider.getAllValue();
  }

  getUsersFromGroupes(): userAltType[] {
    return this.groupesState.getGroupes().reduce<userAltType[]>((acc, e) => {
      if (e.user_groupes.length > 0) {
        e.user_groupes.forEach((f) => {
          const exist = acc.findIndex((g) => g.id === f.user?.id);
          if (f.user && exist === -1) acc.push(f.user);
        });
      }
      return acc;
    }, []);
  }

  getUserFromGroupes$(userId: string) {
    return this.groupesState.groupes$.pipe(
      map((e) => {
        for (const groupe of e) {
          for (const userGroupe of groupe.user_groupes) {
            if (userGroupe.user && userGroupe.user.id === userId) {
              return userGroupe.user;
            }
          }
        }
        return null;
      })
    );
  }

  getUserTransformedFromGroupe(userId: string): userTransformedType | null {
    const groupes = this.groupesState.getGroupes();
    return (
      transformGroupesToUsers(groupes).find((e) => e.id === userId) ?? null
    );
  }

  getGroupes() {
    return this.groupesState.getGroupes();
  }

  getGroupesFromUser(userId: string) {
    return this.groupesState.getGroupes().filter((e) => {
      return e.user_groupes.some((f) => f.user?.id === userId);
    });
  }
}
