<app-meta-field-menu
  [readonly]="readonly"
  (addMetaField)="addMetaField($event)"
>
</app-meta-field-menu>

<app-meta-field-accordion
  [metas]="metas"
  [readonly]="readonly"
  (updateMeta)="updateMetaField($event.meta, $event.key, $event.value)"
  (removeMeta)="removeMetaField($event)"
></app-meta-field-accordion>

<div class="d-flex-center">
  <app-ugau-button
    label="Outlook"
    iconName="schedule_send"
    (click)="shareToCalendar('outlook')"
    [hasBorder]="false"
  >
  </app-ugau-button>
  <app-ugau-button
    label="Google"
    iconName="schedule_send"
    (click)="shareToCalendar('google')"
    [hasBorder]="false"
  >
  </app-ugau-button>
</div>
