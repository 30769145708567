import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { getFromLocalStorage } from '../utils/localstorage-utils.service';
import { LOCALSTORAGE } from '../enum/local-storage';
import { productfieldDocType } from '../db/schemas/productfields.schema';
import { PRODUCTFIELD_TYPE } from '../enum/product-fields-type';
import { DatabaseService } from '../db/database.service';

@Injectable({
  providedIn: 'root',
})
export class ProductfieldsStateService {
  productfieldProvider = inject(DatabaseService).getProductfieldsProvider();

  productfields$: Observable<productfieldDocType[]> =
    this.productfieldProvider.getAll$.pipe(
      map(
        (e) => {
          const overideDurations = getFromLocalStorage(
            LOCALSTORAGE.OVERRIDE_DURATIONS,
            true
          );

          if (overideDurations) {
            Object.keys(overideDurations).forEach((id: string) => {
              const index = e.findIndex((e) => e.id === id);
              if (index !== -1) {
                e[index].defaultvalue = overideDurations[id];
              }
            });
          }

          return e as productfieldDocType[];
        },
        shareReplay({ bufferSize: 1, refCount: true })
      )
    );

  getProductfield$(
    productfieldId: string
  ): Observable<productfieldDocType | undefined> {
    return this.productfields$.pipe(
      map((productfields) =>
        productfields.find((el) => el.id === productfieldId)
      )
    );
  }

  getProductfields() {
    return this.productfieldProvider.getAllValue();
  }

  getProductfieldsComposant() {
    return this.productfieldProvider
      .getAllValue()
      .filter((e) => !e.is_for_emplacement);
  }
  getProductfieldsEmplacement() {
    return this.productfieldProvider
      .getAllValue()
      .filter((e) => e.is_for_emplacement);
  }

  hasProductfieldsDesignation(checkIds: string[]) {
    const productfieldIds = this.getProductfields()
      .filter((e) => e.type === PRODUCTFIELD_TYPE.DESIGNATION)
      .map((e) => e.id);

    return productfieldIds.some((e) => checkIds.includes(e));
  }
}
