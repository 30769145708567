import { Geometry } from 'geojson';
import {
  generatePopupElement,
  createHeaderElement,
  createLinkElement,
} from './popup-utils';

export const getContentPopupCreateEmplacementFromCadastre = (
  data: any,
  callback: (feature: Geometry, numero: string, commentaire: string[]) => void,
  libelle: string = 'Créer '
) => {
  const popupContent = generatePopupElement();
  const emplacementName = createHeaderElement('Création');

  popupContent.appendChild(emplacementName);

  data.features.forEach((feature: any) => {
    const linkList = createLinkElement(
      'add',
      libelle + feature.properties.numero,
      () => {
        callback(
          feature.geometry,
          feature.properties.numero,
          Object.keys(feature.properties).map(
            (key) => `${key}: ${feature.properties[key]}`
          )
        );
      }
    );
    popupContent.appendChild(linkList);
  });

  return popupContent;
};
