import {
  URI_securouteOA,
  IGN_URL,
  IGN_GEO_AND_TILE,
  IGN_TILE,
} from './layersChoices';
import { mapRemoveSandLIgnRaster, loadIgnRasterSourceAndLayer } from './toggleLayerIGN';
import { Map } from 'mapbox-gl';

export function addMapSecuRouteOA(map: Map, remove = false) {
  const sourceLib = URI_securouteOA; // Layer pour les ouvrages d’art

  if (remove) {
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=SECUROUTE.TE.ALL' +
    IGN_TILE.replace('&style=normal', '&style=TOUS LES FRANCHISSEMENTS');
  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}
