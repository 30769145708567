import { Injectable } from '@angular/core';
import { BooleanStateService } from './abstract-boolean-state';
import { shareReplay } from 'rxjs';
import { UserMetaState } from './usermeta.state';

@Injectable({
  providedIn: 'root',
})
export class EmplacementDetailWidthStateService extends BooleanStateService {
  constructor(private userMetaState: UserMetaState) {
    super();

    const value = this.userMetaState.getEmplacementWidth();
    this.setValue(value);
  }

  isFullwidth$ = this.state$.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  setFull(): void {
    this.setTrue();
  }

  toggle(): void {
    const value = !this.getValue();
    this.setValue(value);
    this.userMetaState.setEmplacementWidth(value);
  }

  setFullwidthWithoutRegisterToMeta(value: boolean): void {
    this.setValue(value);
  }
}
