import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
  NgZone,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { COLORS_BTN } from './../../enum/colors';
import { fromEvent, merge, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteModule,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { trackByValueFn } from '../../services/trackByFunctions.utils';

@Component({
  selector: 'app-ugau-input-autocomplete',
  template: `
    <mat-form-field
      class="ugau-input-autocomplete"
      [appearance]="appearance"
      floatLabel="always"
    >
      <mat-label>{{ label }}</mat-label>
      <input
        *ngIf="type !== 'textarea'"
        [type]="type"
        #input
        matInput
        [placeholder]="placeholder"
        [value]="value"
        [readonly]="readOnly"
        [matAutocomplete]="auto"
        [formControl]="myControl"
        (blur)="onBlur()"
      />

      <textarea
        *ngIf="type === 'textarea'"
        #input
        matInput
        [placeholder]="placeholder"
        [readonly]="readOnly"
        [matAutocomplete]="auto"
        [formControl]="myControl"
        (blur)="onBlur()"
      ></textarea>

      <mat-icon
        matSuffix
        *ngIf="myControl.value && !readOnly && showClearButton"
        (click)="$event.stopPropagation(); clearInput()"
        class="clear-input"
        >close</mat-icon
      >

      <mat-autocomplete
        #auto="matAutocomplete"
        hideSingleSelectionIndicator="true"
        (optionSelected)="selected($event)"
      >
        <mat-option
          *ngFor="let option of filteredOptions$ | async; trackBy: trackByValue"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styleUrls: ['./ugau-input-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    NgFor,
    MatOptionModule,
    AsyncPipe,
  ],
})
export class UgauInputAutocompleteComponent implements OnInit, AfterViewInit {
  trackByValue = trackByValueFn;
  @Input() set value(value: string | null) {
    this.myControl.setValue(value, { emitEvent: false });
  }

  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readOnly = false;
  @Input() options: string[] = [];
  @Input() timeDebounce: number = 5000;
  @Input() shouldValueFilterOptions = true;
  @Input() showClearButton = true;

  @Input() @HostBinding('class.no-sub-wrapper') disableSubWrapper: boolean =
    false;
  @Input() @HostBinding('class.no-padding') noPadding: boolean = false;

  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';

  @ViewChild('input') inputRef!: ElementRef;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  @Output() inputChange = new EventEmitter<string>();

  myControl = new UntypedFormControl('');
  filteredOptions$!: Observable<string[]>;

  private destroyRef = inject(DestroyRef);

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    if (this.value) this.myControl.setValue(this.value);
    this.filteredOptions$ = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.shouldValueFilterOptions ? this._filter(value || '') : this.options
      )
    );
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      const input$ = fromEvent<Event>(
        this.inputRef.nativeElement,
        'input'
      ).pipe(
        map((event) => (event.target as HTMLInputElement).value),
        debounceTime(this.timeDebounce),
        distinctUntilChanged()
      );

      merge(input$)
        .pipe(takeUntilDestroyed(this.destroyRef))
        // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
        .subscribe((value) => {
          this.emit(value);
        });
    });
  }

  onBlur() {
    if (this.matAutocomplete.isOpen) {
      console.log('Blur event ignored because autocomplete is open');
      return;
    }
    this.emit(this.myControl.value);
  }

  focusout() {
    this.inputRef.nativeElement.blur();
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.myControl.setValue(event.option.value);
    this.emit(event.option.value);
    this.focusout();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (value === '') return this.options;

    return this.options.filter((option) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      option?.toLowerCase().includes(filterValue)
    );
  }

  clearInput() {
    this.myControl.setValue(null);
    this.inputChange.emit(undefined);
  }

  emit(value: string) {
    if (this.readOnly) return;
    this.inputChange.emit(value);
  }
}
